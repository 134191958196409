import React, { useEffect, useState } from "react";
import { Pie } from "@ant-design/plots";
import styled from "@emotion/styled";
import COLORS from "common/Colors";
import { Spin } from "antd";
import {
  concat,
  chain,
  groupBy,
  toPairs,
  zipObject,
  map,
  includes,
  filter,
} from "lodash";
// Redux
import { connect } from "react-redux";
import {
  getLocations,
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getCheckInLocationId,
  getUserInfo,
} from "redux/selectors";

import { CALL_API } from "common/API";
import { size } from "lodash";
import LocationBasedAttendanceList from "./LocationBasedAttendanceList";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  padding: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px !important;
  // height: 100% !important;
  padding-bottom: 25px;
  height: 490px;
  // margin-bottom: 20px;
  .name-title {
    padding: 3px 10px;
    font-size: 1rem;
    font-weight: 600;
  }
`;

function LocationBasedPresentStaff({
  locationList,
  userRoleId,
  selectedDepartmentId,
  ClientId,
  checkinLocationId,
  isHrDashboard = false,
  userInfo,
}) {
  const [displayReportLoader, toggleReportLoader] = useState(false);
  const [reportData, setReportData] = useState([]);

  const [reportList, setReportList] = useState([]);
  const [locationName, setLocationName] = useState("");
  const [displayAttendanceStaffModal, toggleDisplayAttendanceStaffModal] =
    useState(false);

  const getReportData = async () => {
    toggleReportLoader(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);
      const { locationList } = await CALL_API(
        `location-list/${ClientId}`,
        "post",
        {
          departmentId: [""],
          reportingmanager: !isHrDashboard ? userInfo?.userid : "",
        }
      );
      let result = chain(locationList)
        .groupBy((x) => x.loginlocationname)
        .map((value, key) => ({
          loginLocationName: key,
          prensentList: value,
          presentCount: size(value),
        }))
        .value();

      setReportData(result);

      toggleReportLoader(false);
    }
  };
  useEffect(() => {
    getReportData();
  }, [selectedDepartmentId, checkinLocationId]);

  const config = {
    // height:350,
    padding: "auto",
    data: reportData,
    angleField: "presentCount",
    colorField: "loginLocationName",
    radius: 0.7,
    label: {
      type: "inner",
      offset: "-30%",
      autoRotate: false,
      // content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 15,
        textAlign: "center",
      },
    },
    legend: {
      position: "right",
      layot: "vertical",
      offsetX: -30,
      marker: {
        symbol: "circle",
      },
      itemName: {
        style: {
          fontSize: 15,
        },
      },
    },
    interactions: [{ type: "element-active" }],
    state: {
      active: {
        style: {
          lineWidth: 5,
          stroke: "#ffffff",
        },
      },
    },
  };

  return (
    <>
      <Spin spinning={displayReportLoader}>
        <StyledChartContainer>
          <div className="name-title">Today's Location based Attendance</div>

          {reportData?.length > 0 ? (
            <>
              <Pie
                {...config}
                onEvent={(plot) => {
                  plot.on("element:click", (x) => {
                    setLocationName(x.data.data.loginLocationName);
                    setReportList(x.data.data.prensentList);
                    toggleDisplayAttendanceStaffModal(true);
                  });
                }}
              />
            </>
          ) : (
            <p
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No Staff Present
            </p>
          )}
        </StyledChartContainer>
      </Spin>
      <LocationBasedAttendanceList
        displayModal={displayAttendanceStaffModal}
        toggleModal={() => toggleDisplayAttendanceStaffModal(false)}
        title={locationName}
        list={reportList}
        reportType={locationName}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  userRoleId: getUserRoleId(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  checkinLocationId: getCheckInLocationId(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(LocationBasedPresentStaff);
