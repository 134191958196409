import styled from "@emotion/styled";
import { Col, Modal, Row, Select, Spin } from "antd";
import Button from "atoms/Button";
import COLORS from "common/Colors";
import { LEAVE_ICON } from "common/Constants";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import LeaveDonutChart from "./LeaveDonutChart";
// import COLORS from 'common/Colors';

// Redux
import { reduce } from "lodash";
import { connect } from "react-redux";
import {
  getAllDepartmentList,
  getClientid,
  getUserInfo,
  getUserList,
  getUserRoleId,
} from "redux/selectors";

const StyledModal = styled(Modal)`
  width: 700px !important;
  .ant-modal-body {
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-header {
    text-transform: uppercase;
    max-width: 95%;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const ButtonOutlined = styled(Button)`
  padding: 5px 20px;
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 5px;
  background: transparent;
  font-weight: normal;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;

  &:hover {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  &:focus {
    border: 1px solid ${COLORS.PRIMARY};
    background: #f1f3f4;
    color: ${COLORS.PRIMARY};
  }
  @media (max-width: 900px) {
    margin: 10px 0px;
  }
`;

const StyledSelect = styled(Select)`
  padding-bottom: 20px;
`;

function DepartmentStaffLeaveBalance({
  displayModal,
  toggleModal,
  leaveTypeId,
  leaveName,
  selectedDepartmentId,
  leaveCategoryList,
  allDepartmentList,
  userList,
  clientId,
  leaveList,
  leaveCatgeory,
  filterDepartment,
  all,
  userInfo,
}) {
  const [userLeaves, setUserLeaves] = useState([]);
  const [displayLeaveReportLoader, toggleLeaveReportLoader] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [CSVDepartmentLeave, setCSVDepartmentLeave] = useState([]);
  const csvLinkDepartment = useRef();

  const [leaveSummary, setLeaveSummary] = useState({});

  const filteredUserList = userList.filter(
    (user) =>
      user.statusid === "1" && user?.reportingmanager === userInfo?.userid
  );

  useEffect(() => {
    let leaveStatistics = {};
    if (all) {
      filteredUserList.forEach((category) => {
        leaveStatistics[category.userid] = {
          available: 0,
          consumed: 0,
        };
      });
    } else {
      const teamFilteredUserList = filteredUserList.filter((user) => {
        const userDepartmentIds = [user?.basedepartmentid];

        return Object.values(userDepartmentIds).some((deptIds) =>
          filterDepartment.some((dept) => deptIds.includes(dept.department_id))
        );
      });

      teamFilteredUserList.forEach((user) => {
        leaveStatistics[user.userid] = {
          available: 0,
          consumed: 0,
        };
      });
    }

    let approvedLeaves = [];
    if (all) {
      approvedLeaves = leaveCatgeory.filter((item) => {
        return item.status_id === "5" && item.leave_category_id === leaveTypeId;
      });
    } else {
      approvedLeaves = leaveCatgeory.filter((item) => {
        const isDepartmentFiltered = filterDepartment.some(
          (dept) => dept.department_id === item.department_id
        );

        return (
          isDepartmentFiltered &&
          item.status_id === "5" &&
          item.leave_category_id === leaveTypeId
        );
      });
    }

    approvedLeaves.forEach((leave) => {
      const leaveCategoryName = leave.userid;
      if (!leaveStatistics[leaveCategoryName]) {
        leaveStatistics[leaveCategoryName] = { available: 0, consumed: 0 };
      }
      leaveStatistics[leaveCategoryName].consumed += parseFloat(
        leave.number_of_days
      );
    });
    console.log("filteruserList-->", filteredUserList);
    const updatedLeaveData = filteredUserList.map((category) => {
      const categoryName = category.fullname;
      const categoryid = category.userid;

      const availableCount = leaveCategoryList.find(
        (data) =>
          data.leave_category_id === leaveTypeId &&
          (data?.eligible?.toLowerCase() === "both" ||
            data?.eligible?.toLowerCase() === category?.gender)
      );
      return {
        categoryName: categoryName,
        categoryid: categoryid,
        data: [
          {
            type: "available",
            value: parseInt(availableCount?.number_of_leaves || 0, 10),
          },
          {
            type: "consumed",
            value: leaveStatistics[categoryid]?.consumed || 0,
          },
        ],
      };
    });

    setLeaveSummary(updatedLeaveData);
    setUserLeaves(updatedLeaveData);
  }, [leaveTypeId, leaveCatgeory, filteredUsers]);

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Name" },
    { label: "Available Leaves" },
    { label: "Consumed Leaves" },
    { label: "Total Leaves" },
  ];
  const prepareCSV = async (event, done) => {
    if (userLeaves.length > 0) {
      setCSVDepartmentLeave(
        reduce(
          userLeaves.filter(
            (value) =>
              filteredUsers.length === 0 ||
              filteredUsers.includes(value.categoryid)
          ),
          function (result, leave) {
            result.push({
              "Employee ID": leave.categoryid,
              Name: leave.categoryName,
              "Available Leaves": leave.data[0].value - leave.data[1].value,
              "Consumed Leaves": leave.data[1].value,
              "Total Leaves": leave.data[0].value,
            });
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkDepartment.current.link.click();
      });
    } else {
      console.log("No data available to download");
      // message.error(`No data available to download.`);
    }
  };
  function findLeaveCategoryName(leaveCategoryList, leaveTypeId) {
    const leaveCategory = leaveCategoryList.find(
      (category) => category.leave_category_id === leaveTypeId
    );
    return leaveCategory
      ? leaveCategory.leave_category_name
      : "Category not found";
  }
  const leaveCategoryName = findLeaveCategoryName(
    leaveCategoryList,
    leaveTypeId
  );
  return (
    <>
      <StyledModal
        title={`${leaveCategoryName} Report`}
        visible={displayModal}
        onCancel={toggleModal}
        footer={null}
      >
        <Spin spinning={displayLeaveReportLoader}>
          <Row gutter={16}>
            <Col span={12}>
              <p>Employee</p>
              <StyledSelect
                mode="multiple"
                showSearch
                style={{
                  width: "100%",
                }}
                placeholder="All"
                name="user"
                optionFilterProp="fullname"
                filterOption={(input, option) =>
                  option.fullname.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) => {
                  optionA.fullname
                    .toLowerCase()
                    .localeCompare(optionB.fullname.toLowerCase());
                }}
                options={
                  all
                    ? filteredUserList
                    : filteredUserList.filter((user) => {
                        const userDepartmentIds = [user?.basedepartmentid];

                        return Object.values(userDepartmentIds).some(
                          (deptIds) =>
                            filterDepartment.some((dept) =>
                              deptIds.includes(dept.department_id)
                            )
                        );
                      })
                }
                fieldNames={{
                  label: "fullname",
                  value: "userid",
                }}
                onChange={(newValue) => setFilteredUsers(newValue)}
              />
            </Col>

            <Col
              span={12}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <ButtonOutlined
                onClick={prepareCSV}
                disabled={userLeaves.length === 0}
              >
                Export CSV file
              </ButtonOutlined>
              <CSVLink
                header={CSVHeaders}
                data={CSVDepartmentLeave}
                filename={"Department Leave List.csv"}
                asyncOnClick={true}
                ref={csvLinkDepartment}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            {Array.isArray(leaveSummary) &&
              leaveSummary
                .filter(
                  (value) =>
                    filteredUsers.length === 0 ||
                    filteredUsers.includes(value.categoryid)
                )
                .filter((value) => {
                  const available =
                    value.data.find((item) => item.type === "available")
                      ?.value || 0;
                  const consumed =
                    value.data.find((item) => item.type === "consumed")
                      ?.value || 0;
                  return available !== 0 || consumed !== 0;
                })
                .map((value, index) => (
                  <StyledCol md={12} sm={24} xs={24} key={index}>
                    <LeaveDonutChart
                      iconName={LEAVE_ICON[7]}
                      title={`${value.categoryName} - ${new Date()
                        .getFullYear()
                        .toString()}`}
                      data={value.data}
                    />
                  </StyledCol>
                ))}
          </Row>
        </Spin>
      </StyledModal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  userList: getUserList(state),
  clientId: getClientid(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(DepartmentStaffLeaveBalance);
