import React, { useState } from "react";
import { DownOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { ReactComponent as Edit } from "../../assets/Edit_Pencil_01.svg";
import { ReactComponent as Send } from "../../assets/send.svg";
import { ReactComponent as FaceIcon } from "../../assets/face.svg";
import { ReactComponent as Tick } from "../../assets/tick.svg";
import { Button, Input, message, Modal, Select, Upload } from "antd";
import { useHistory } from "react-router-dom";

const props = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
const Styleddiv = styled.div`
  .breadcrumb {
    display: flex;
    align-items: center;
  }
  .disciplinary-text {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    color: #636980;
  }
  .breadcrumb svg {
    width: 10px;
    height: 10px;
    color: #636980;
    margin-bottom: 10px;
  }
  .view-detail {
    font-weight: 400;
    font-size: 16px;
  }
  .emp-name {
    font-weight: 500;
    font-size: 16px;
    color: #1F243B;
  }
  .emp-id {
    font-weight: 400;
    font-size: 14px;
    color: #636980;
    margin-bottom: 13px;
  }
  .name-edit-delete {
    display: flex;
    justify-content: space-between;
  }
  .name-edit-delete div {
    display: flex;
    align-items: center;
  }
  .trash-edit-move {
    margin-right: 70px;
    gap: 10px;
  }
  .two-container {
    display: flex;
    gap: 20px;
  }
  .employee-div {
    display: flex;
    flex-wrap: wrap;
  }
  .line-emp-id {
    color: #E2E2E8;
    margin-bottom: 15px;
  }
  .emp-name-id {
    gap: 10px;
  }
  .id-upload {
    display: flex;
    justify-content: space-between;
    // gap: 50px;
  }
  .employee-title {
    width: 30%;
    font-weight: 500;
    font-size: 14px;
    color: #636980;
    text-align: left;
  }
  .employee-total {
    width: 639px;
    // height: 412px;
    border-radius: 8px;
    border: 1px solid #E2E2E8;
    padding: 10px;
  }
  .timeline-total {
    width: 639px;
    // height: 412px;
    border-radius: 8px;
    border: 1px solid #E2E2E8;
    padding: 10px;
  }
  .employee-id {
    font-weight: 500;
    font-size: 16px;
  }
  .attach-text {
    color: #2D53DA;
    border: none;
  }
  .item-value {
    font-weight: 400;
    font-size: 16px;
    color: #1F243B;
  }
  .add-comments {
    font-weight: 500;
    font-size: 16px;
    color: #1F243B;
  }
  .custom-select .ant-select-selector {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    // border: none;
    border: 1px solid #2D53DA;
  }
  .custom-select .ant-select-placeholder {
    color: #2D53DA;
  }
  .comments-input {
    border-radius: 34pc;
  }
  .actions-timeline {
    font-weight: 500;
    font-size: 16px;
  }
  .timeline-name {
    font-weight: 400;
    font-size: 16px;
  }
  .timeline-action {
    font-weight: 400;
    font-size: 16px;
    color: #636980;
  }
  
  .first-warning,
  .second-warning {
    color: #119DE5;
  }
  .under-review,
  .show-cause {
    color: #F28916;
  }
    .resolved{
  color: #08C108;
}
  .timeline-date-time {
    font-weight: 400;
    font-size: 14px;
    color: #636980;
  }
  .scrollable-container {
    max-height: 412px;
    overflow-y: auto;
  }
  // .timeline-item {
  //   word-wrap: break-word;
  // }
  .face-content {
    display: flex;
    gap: 10px;
  }
  .for-commented {
    text-decoration: underline;
    color: #2D53DA;
    cursor: pointer;
    margin-left: 120px;
  }
  .comment-para {
    ont-weight: 400;
    font-size: 14px;
    color: #636980;
  }
    .suspend-text {
    color: #f28916;
  }  

`;
const StyledModal = styled(Modal)`
  .delete-modal-text {
    font-weight: 400;
    font-size: 16px;
    color: #636980;
  }
  .yes-no-button {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .no-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #2D53DA;
    color: #2D53DA;
  }
  .yes-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    background-color: #2D53DA;
    color: #FFFFFF;
  }
  .success-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sent-successfully {
    font-size: 16px;
    font-weight: 400;
  }
`;
const EmployeeId = [
  {
    title: "Incident Type",
    value: "Vehicle Accident",
    key: 1,
  },
  {
    title: "Date",
    value: "26th Aug 2024",
    key: 2,
  },
  {
    title: "Time",
    value: "11:00 AM",
    key: 3,
  },
  {
    title: "Location",
    value: "Near Office",
    key: 4,
  },
  {
    title: "Description",
    value:
      "I met with an accident on my way to office, that’s the reason I have created this incident",
      key: 5,
  },
  {
    title: "Attachment",
    value: "",
    key: 6,
  },
];
const actionTimeline = [
  {
    key: "1",
    name: "Henry Becquerel",
    action: "created the Incident",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
  {
    key: "2",
    name: "Elsa Joseph",
    action: "Moved to Under Review",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
  {
    key: "3",
    name: "Elsa Joseph",
    action: "Commented",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
  {
    key: "4",
    name: "Elsa Joseph",
    action: "Commented",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
  {
    key: "5",
    name: "Elsa Joseph",
    action: "Commented",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
  {
    key: "6",
    name: "Elsa Joseph",
    action: "Commented",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
  {
    key: "7",
    name: "Elsa Joseph",
    action: "Moved to Resolved",
    date: "04th Mar 2024",
    time: "10.20 AM",
  },
];
function ViewResolved({ onBack }) {
  const history = useHistory(); 
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeletedModalOpen, setIsDeletedModalOpen] = useState(false);
  const [isCommentVisible, setIsCommentVisible] = useState(false);
  const handleCommentVisibility = () => {
    setIsCommentVisible(!isCommentVisible);
  };
  const handleBack = () => {
    history.push("/MyIncident"); // Navigate to the "/myincident" route
  };

  const handleDeleteOpen = () => {
    setIsDeleteOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };
  const handleDeleteConfirm = () => {
    setIsDeleteOpen(false);
    setIsDeletedModalOpen(true);
    setTimeout(() => {
      setIsDeletedModalOpen(false);
    }, 2000);
  };
  return (
    <>
      <Styleddiv>
        <div>
          <div className="breadcrumb">
            <p className="disciplinary-text" onClick={handleBack}>
              My Incident
            </p>
            &nbsp;
            <RightOutlined /> &nbsp;
            <p className="view-detail">View Detail</p>
          </div>
          {/* <div className="name-edit-delete"> */}
            <div className="emp-name-id">
              <p className="emp-name">Henry Becquerel</p>{" "}
              <span className="line-emp-id">|</span>
              <span className="emp-id">EM12345</span>
            </div>
            {/* <div className="trash-edit-move"> */}
              {/* <Trash onClick={handleDeleteOpen} style={{ cursor: "pointer" }} />
              <Edit style={{ cursor: "pointer" }} /> */}
              {/* <Select placeholder="Move to" className="custom-select">
                <Select.Option>Verbal Warning</Select.Option>
                <Select.Option>First Warning</Select.Option>
                <Select.Option>Second Warning</Select.Option>
                <Select.Option>Thirs & Final Warning</Select.Option>
                <Select.Option>Show Cause</Select.Option>
                <Select.Option>Suspend</Select.Option>
                <Select.Option>Demotion</Select.Option>
                <Select.Option>Exoneration</Select.Option>
                <Select.Option>Clearance</Select.Option>
                <Select.Option>Termination</Select.Option>
              </Select> */}
            {/* </div>
          </div> */}
          <br />
          <div className="two-container">
            <div className="employee-total">
              <div className="id-upload">
                <div className="employee-id">IN12345</div>
              </div>
              <br />
              <div>
                {EmployeeId.map((item) => (
                  <div key={item.key} className="employee-div">
                    <p className="employee-title">{item.title}</p>
                    <p
                      className="item-value"
                      style={
                        item.value === "Termination" ? { color: "#D90325" } : {}
                      }
                    >
                      {item.value}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="timeline-total">
              <div className="actions-timeline">Actions' Timeline</div>
              <br />
              <div className="scrollable-container">
                {actionTimeline.map((item) => (
                  <div className="face-content">
                    <div>
                      <FaceIcon alt="face" />
                    </div>
                    <div key={item.key} className="timeline-item">
                      <span className="timeline-name">{item.name};&nbsp;</span>
                      <span
                        className={`timeline-action
                        ${
                          item.action === "Moved to Under Review" ||
                          item.action === "" ||
                          item.action === ""
                            ? "under-review "
                            : item.action ===
                                "" ||
                              item.action === "Moved to Resolved"
                            ? " resolved"
                            : ""
                        }`}
                      >
                        {item.action}
                      </span>
                      {item.action === "Commented" && (
                        <span
                          className="for-commented"
                          onClick={handleCommentVisibility}
                        >
                          {isCommentVisible ? (
                            <>
                              Close Comment <UpOutlined />
                            </>
                          ) : (
                            <>
                              View Comment <DownOutlined />
                            </>
                          )}
                        </span>
                      )}
                      <br />
                      <span className="timeline-date-time">
                        {item.date};&nbsp;
                      </span>
                      <span className="timeline-date-time">{item.time}</span>
                      <br />
                      <br />
                      {isCommentVisible && item.action === "Commented" && (
                        <div>
                          <p className="comment-para">
                            I've noticed some inconsistencies in your recent
                            work. Let's meet to discuss how we can address these
                            and get you back on track.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <br />
          <div>
            <div className="add-comments">Add Comments</div>
            <br />
            <Input
              placeholder="Type here..."
              className="comments-input"
              // suffix={<Send />}
            />
          </div>
        </div>
        <StyledModal
          width={470}
          height={140}
          footer={false}
          closable={false}
          open={isDeleteOpen}
          onCancel={handleDeleteCancel}
          style={{ marginTop: "100px" }}
        >
            
          <p className="delete-modal-text">
            Are you sure you want to delete this incident?
          </p>
          <br />
          <div className="yes-no-button">
            <Button onClick={handleDeleteCancel} className="no-button">
              No
            </Button>
            <Button onClick={handleDeleteConfirm} className="yes-button">
              Yes
            </Button>
          </div>
        </StyledModal>
        <StyledModal
          width={190}
          height={70}
          open={isDeletedModalOpen}
          onCancel={() => setIsDeletedModalOpen(false)}
          footer={false}
          closable={false}
          style={{ marginTop: "130px" }}
        >
          <div className="success-modal">
            <Tick />
            <p className="sent-successfully">Deleted</p>
          </div>
        </StyledModal>
      </Styleddiv>
    </>
  );
}
export default ViewResolved;









