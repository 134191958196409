import React, { useEffect, useState } from "react";
import TeamCard from "molecules/TeamCard";
import { Row, Col, Input, Select, Button, Modal, Spin } from "antd";
import { DEVICE } from "common/Device";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getUnreadChatMessageList,
  getUserInfo,
  getDepartmentList,
} from "redux/selectors";
import { toggleSuccessModal } from "redux/actions";
import styled from "@emotion/styled";
import {
  map,
  concat,
  filter,
  size,
  uniqBy,
  includes,
  find,
  result,
  uniq,
  flatMap,
} from "lodash";
import COLORS from "common/Colors";
import EventBus from "utils/EventBus";
import { useRef } from "react";

const StyledCol = styled(Col)`
  padding: 10px;
`;

const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-radius: 10px;
  .selectbox {
    width: 100%;
    border-radius: 10px !important;
  }
  .ant-select-selector {
    border-radius: 10px !important;
  }
  .broadcast {
    width: 60px;
    height: 60px;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledFooterDiv = styled.div`
  text-align: right;
  padding-top: 10px;
  .ant-btn {
    font-weight: bold !important;
    color: ${COLORS.PRIMARY};
    padding-right: 0px !important;
  }
  .ant-btn:hover {
    border: none;
  }
`;

function Team({
  userList,
  allDepartmentList,
  updateSuccessModal,
  unreadChatMessageList,
  selectedDepartmentId,
  userInfo,
}) {
  const [isBroadcastModalVisible, toggleBroadcastModalVisible] =
    useState(false);
  const [sendingMessageLoader, ToggleSendingMessageLoader] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [broadcastMessage, setBroadcastMessage] = useState("");
  const { TextArea } = Input;
  const selectref = useRef(null);

  const filteruser = userList?.find(
    (item) => item?.userid === userInfo?.userid
  );

  let currentPath = window.location.pathname;
  let pathSegments = currentPath.split("/");
  let url = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    setSelectedDepartment(selectedDepartmentId);
  }, [selectedDepartmentId]);

  useEffect(() => {
    getStaffList();
  }, [selectedDepartment]);

  const sendMessage = () => {
    const staffIds = map(getStaffList(), "userid");
    if (size(staffIds) && broadcastMessage) {
      ToggleSendingMessageLoader(true);
      EventBus.$dispatch("sendMessage", {
        action: "BROADCAST",
        payload: {
          message: broadcastMessage,
          receiverUserIds: map(getStaffList(), "userid"),
          receiverName: map(getStaffList(), "fullname"),
        },
      });
      setTimeout(() => {
        toggleBroadcastModalVisible(false);
        ToggleSendingMessageLoader(false);
      }, 1000);
      setTimeout(() => {
        updateSuccessModal({
          visibility: true,
          message: `Message has been broadcasted.`,
        });
        setBroadcastMessage("");
      }, 1000);
    }
  };
  console.log("userlist--->", userList);

  const StaffList = () => {
    return concat(
      url === "myteam"
        ? userList?.filter((val) => {
            if (
              (
                val?.statusid?.toString() === "1" &&
                val?.reportingmanager === userInfo?.userid
              )?.length > 0
            ) {
              return val;
            } else if (
              val?.statusid?.toString() === "1" &&
              val?.reportingmanager === filteruser?.reportingmanager
            ) {
              return val;
            }
          })
        : userList?.filter((item) => item?.statusid === "1")
    );
  };
  const getStaffList = () => {
    const allStaffList = userList;

    var staffList;

    staffList =
      url === "myteam"
        ? allStaffList?.filter((val) => {
            if (
              (
                val?.statusid?.toString() === "1" &&
                val?.reportingmanager === userInfo?.userid
              )?.length > 0
            ) {
              return val;
            } else if (
              val?.statusid?.toString() === "1" &&
              val?.reportingmanager === filteruser?.reportingmanager
            ) {
              return val;
            }
          })
        : allStaffList?.filter((item) => item?.statusid === "1");

    return uniqBy(
      employeeNameFilter !== ""
        ? filter(staffList, (staff) => {
            return staff?.fullname === employeeNameFilter;
          })
        : staffList,
      "fullname"
    );
  };

  const STAFF_LIST = uniqBy(StaffList(), "fullname")?.map((val) => {
    return { user_id: val?.fullname, name: val?.fullname };
  });
  return (
    <>
      <StyledTitle className="page-title-head">List of Employees</StyledTitle>
      <StyledFilterRow gutter={16}>
        {/* <Col md={6} sm={24} xs={24} ref={selectref}>
          <Select
            className="selectbox mb-2"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
            }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.name ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.name ?? "").toLowerCase())
            // }
            placeholder="Select Employee"
            options={concat(
              {
                user_id: "",
                name: "All Employee",
              },
              STAFF_LIST
            )}
            fieldNames={{
              label: "name",
              value: "user_id",
            }}
            getPopupContainer={() => selectref.current}
            onChange={(value) => setEmployeeNameFilter(value)}
          />
        </Col> */}
        <Col md={18} sm={24} xs={24} className="align-right"></Col>
      </StyledFilterRow>

      <Row>
        {map(
          getStaffList(),
          (
            {
              clientid,
              userid,
              fullname,
              departmentid,
              profileurl,
              employeeid,
              jobtitle,
              nationality,
              usertype,
              employementtype,
            },
            index
          ) => (
            <StyledCol key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
              <Link
                to={
                  url === "myteam"
                    ? "/myteam"
                    : `/profile-details/${userid}?type=hr`
                }
              >
                <TeamCard
                  id={userid}
                  clientid={clientid}
                  name={fullname}
                  email_id={userid}
                  employeeid={employeeid}
                  jobtitle={jobtitle}
                  nationality={nationality}
                  usertype={usertype}
                  employementtype={employementtype}
                  hasUnreadMessage={
                    includes(unreadChatMessageList, userid) > 0 ? true : false
                  }
                  dept_name={result(
                    find(allDepartmentList, (val) => {
                      return val.department_id === departmentid;
                    }),
                    "department_name"
                  )}
                  profile={profileurl}
                />
              </Link>
            </StyledCol>
          )
        )}
      </Row>
      <Modal
        centered
        visible={isBroadcastModalVisible}
        closable={false}
        footer={false}
        keyboard={false}
        title="Broadcast Message"
        onCancel={() => toggleBroadcastModalVisible(false)}
      >
        <TextArea
          rows={4}
          placeholder="Type a message."
          maxLength={500}
          value={broadcastMessage}
          onChange={(e) => setBroadcastMessage(e.target.value)}
        />
        <StyledFooterDiv>
          <Button
            type="link"
            onClick={sendMessage}
            loading={sendingMessageLoader}
          >
            Send to all
          </Button>
        </StyledFooterDiv>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  unreadChatMessageList: getUnreadChatMessageList(state),
  userInfo: getUserInfo(state),
  departmentList: getDepartmentList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Team);
