import React, { useState } from "react";
import { Table, Select, Input, DatePicker, Button, Row, Col } from "antd";
import styled from "styled-components";
import { SearchOutlined } from "@mui/icons-material";
import { ReactComponent as Suspend } from "../../assets/suspend.svg";
import { useHistory } from "react-router-dom";

const CustomStyle = styled.div`
  .searchbar {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .searchicon {
    color: #636980;
  }
  .action-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
    font-weight: 400;
    font-size: 14px;
    color: #636980;
  }
  /* Hover effect to change color on hover */
  .dropdown-container a:hover {
    color: #1890ff;
  }
  .dropdown-container a .anticon {
    margin-left: 5px; /* Adjust spacing */
    font-size: 9px; /* Make the arrow smaller */
  }
  .totaldropdowns {
    display: flex;
    gap: 10px !important;
  }
  .heading {
    font-size: 20px;
    font-weight: 500;
  }
  .clearance-text {
    color: #08c108;
  }
  .suspend-text {
    color: #2d53da;
    text-decoration: underline;
  }
  .date-range-container {
    display: flex;
    gap: 10px;
  }
  .datepicker-style {
    width: 100px;
    height: 34px;
    border-radius: 24px;
  }
  @media (max-width: 375px) {
    .totaldropdowns {
      display: block;
    }
    .searchbar {
      margin-bottom: 10px;
    }
    .dropdown-container {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    .searchbar {
      margin-bottom: 10px;
    }
    .dropdown-container {
      margin-bottom: 10px;
    }
  }
`;

const HomeDisciplinary = () => {
  const history = useHistory();
  const dataSource = [
    {
      key: "1",
      DiscipID: "DIS123456",
      date: "25th Aug 2024",
      Category: "Criminal Offense",
      Action: "First Warning",
      Movedto: "Clearance",
      Createdon: "25th Aug 2024",
      Createdby: "Alvin David",
    },
    {
      key: "2",
      DiscipID: "DIS123456",
      date: "25th Aug 2024",
      Category: "Criminal Offense",
      Action: "First Warning",
      Movedto: "Suspend",
      Createdon: "25th Aug 2024",
      Createdby: "Alvin David",
    },
    {
      key: "3",
      DiscipID: "DIS123456",
      date: "25th Aug 2024",
      Category: "Criminal Offense",
      Action: "First Warning",
      Movedto: "Clearance",
      Createdon: "25th Aug 2024",
      Createdby: "Alvin David",
    },
    {
      key: "4",
      DiscipID: "DIS123456",
      date: "25th Aug 2024",
      Category: "Criminal Offense",
      Action: "First Warning",
      Movedto: "Suspend",
      Createdon: "25th Aug 2024",
      Createdby: "Alvin David",
    },
  ];
  const columns = [
    {
      title: "Discip. ID ",
      dataIndex: "DiscipID",
      key: "DiscipID",
    },
    {
      title: "Date of Incident",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
    },
    {
      title: "Moved to",
      dataIndex: "Movedto",
      key: "Movedto",
      render: (text, record) => {
        if (text === "Clearance") {
          return <span className="clearance-text">{text}</span>;
        } else if (text === "Suspend") {
          return (
            <span className="suspend-text">
              {text}&nbsp;
              <Suspend />
            </span>
          );
        }
      },
    },
    {
      title: "Created on",
      dataIndex: "Createdon",
      key: "Createdon",
    },
    {
      title: "Created by",
      dataIndex: "Createdby",
      key: "Createdby",
    },
  ];
  const [showDateRange, setShowDateRange] = useState(false); // State to track custom date range selection

  const handleSelectChange = (value) => {
    if (value === "custom") {
      setShowDateRange(true); // Show date pickers if "Custom Date range" is selected
    } else {
      setShowDateRange(false); // Hide date pickers for other options
    }
  };
  return (
    <CustomStyle>
      <div className="heading">
        <p>Disciplinary Management</p>
      </div>
      <div className="totaldropdowns">
        <Row gutter={16}>
          <Col>
            <div>
              <Input
                className="searchbar"
                placeholder="Search"
                prefix={<SearchOutlined className="searchicon" />}
              />
            </div>
          </Col>
          <Col>
            <div className="dropdown-container">
              <Select placeholder="Category" className="action-dropdown">
                <Select.Option value="work">
                  Absence from duty without permission
                </Select.Option>
                <Select.Option value="first">
                  Unwillingness and inability to perform duties
                </Select.Option>
                <Select.Option value="second">
                  Damage to Company property
                </Select.Option>
                <Select.Option value="third">
                  Loss of Company property
                </Select.Option>
                <Select.Option value="show">
                  Unauthorized possession of Company property
                </Select.Option>
                <Select.Option value="suspend">Insubordination</Select.Option>
                <Select.Option value="second">
                  Use of abusive language, harassment, or bullying
                </Select.Option>
                <Select.Option value="third">Gross negligence</Select.Option>
                <Select.Option value="show">
                  Wilful damage to or theft of company property
                </Select.Option>
                <Select.Option value="suspend">
                  Fraud or dishonesty in dealing with the company
                </Select.Option>
                <Select.Option value="third">
                  Theft or misappropriation of Company funds
                </Select.Option>
                <Select.Option value="show">
                  Under drugs or alcohol during working hours
                </Select.Option>
                <Select.Option value="suspend">
                  Noncompliance to standard operating procedure
                </Select.Option>
              </Select>
            </div>
          </Col>
          <Col>
            <div className="dropdown-container">
              <Select
                placeholder="Date"
                className="action-dropdown"
                onChange={handleSelectChange}
              >
                <Select.Option value="work">Last week</Select.Option>
                <Select.Option value="first">Last month</Select.Option>
                <Select.Option value="second">Last 6 months</Select.Option>
                <Select.Option value="custom">Custom Date range</Select.Option>
              </Select>
            </div>
          </Col>
          {showDateRange && ( // Conditionally render the date pickers
            <div className="date-range-container">
              <Col>
                <div>
                  <DatePicker placeholder="From" className="datepicker-style" />
                </div>
              </Col>
              <Col>
                <div>
                  <DatePicker placeholder="To" className="datepicker-style" />
                </div>
              </Col>
            </div>
          )}
          <Col>
            <div className="dropdown-container">
              <Select placeholder="Incident Type" className="action-dropdown">
                <Select.Option value="work">Workplace Injury</Select.Option>
                <Select.Option value="first">
                  Hazardous Material Exposure
                </Select.Option>
                <Select.Option value="second">Vehicle Accident</Select.Option>
                <Select.Option value="third">Fire or Explosion</Select.Option>
                <Select.Option value="show">Medical Emergency</Select.Option>
                <Select.Option value="suspend">Near Miss</Select.Option>
              </Select>
            </div>
          </Col>
        </Row>
      </div>
      <br />
      {/* Table */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(`/viewdisciplinary/${record.key}`);
            },
          };
        }}
      />
    </CustomStyle>
  );
};

export default HomeDisciplinary;
