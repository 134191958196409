import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  Tooltip,
  Modal,
  message,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { EyeFilled, BellFilled } from "@ant-design/icons";
import { CSVLink } from "react-csv";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getUserInfo,
  getClientid,
  getUserList,
  getMenuList,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { toggleProcessingModal, toggleSuccessModal } from "redux/actions";
import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  APPROVAL_FILTER_TYPES,
  STATUS_CODE,
} from "common/Constants";
import {
  addDaysinDate,
  dateInDetail,
  getMonthDateRange,
  getKenyanDateTime,
  addMonthinDate,
  getMySqlDate,
  momentDate,
  getinitialdate,
} from "utils/Date";
import COLORS from "common/Colors";
import {
  find,
  get,
  includes,
  reduce,
  concat,
  map,
  result,
  filter,
} from "lodash";
import LeaveFormModel from "organisms/LeaveFormModel";
import ViewMore from "organisms/ViewMore";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { CheckCircleFilled } from "@ant-design/icons";

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    width: 100% !important;
  }
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
    white-space: nowrap;
  }
  .non-white {
    background: #f9f9f9;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-btn {
    height: 29px;
    margin-top: 2px;
    margin-left: 0px;
    padding: 0px 10px 2px 10px;
  }
  .ant-select-selector {
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  border-radius: 5px;
`;
function Approval({
  userRoleId,
  statusListFromID,
  selectedDepartmentId,
  userInfo,
  ClientId,
  userList,
  menuList,
  updateSuccessModal,
}) {
  const [displayLeaveModal, toggleDisplayLeaveModal] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [leaveList, setLeaveList] = useState([]);
  const [CSVLeaveList, setCSVLeaveList] = useState([]);
  const [displayTaskLoader, setDisplayTaskLoader] = useState(false);
  const [leaveStatusId, setLeaveStatusId] = useState("4");
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const data = map(leaveList, ({ having_cancel_request }) => ({
    having_cancel_request,
  }));
  const [leaveID, setLeaveID] = useState();
  const [loading, setLoading] = useState(false);
  const filterList = [];
  const [havingCancel, sethavingCancel] = useState(false);
  const containerRef = useRef(null);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const data1 = leaveList?.filter((val) => {
    if (data === "") {
      return val;
    } else if (val?.status_name?.toLowerCase().includes("wait".toLowerCase())) {
      return val;
    } else if (
      val?.status_name?.toLowerCase().includes("Approved".toLowerCase())
    ) {
      if (
        JSON.stringify(val?.having_cancel_request)
          .toLowerCase()
          .includes("1".toLowerCase())
      ) {
        return val;
      }
    }
  });

  const ApproveOk = async (reason) => {
    return new Promise(async (resolve) => {
      await approveRejectCancelLeave2(5, reason, havingCancel);
      resolve();
    }).catch((err) => console.log("[ApproveOk] Error-->", err));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    ApproveOk();
  };

  const approveRejectCancelLeave2 = async (
    statusId,
    declined_reason = "",
    is_leave_cancel_request
  ) => {
    return new Promise(async (resolve) => {
      const { code } = await CALL_API(
        `approve-reject-cancel-leave/${ClientId}`,
        "post",
        {
          departmentId: selectedDepartmentId,
          user_leave_id: leaveID,
          status_id: statusId.toString(),
          declined_reason,
          is_cancel_request: is_leave_cancel_request,
          start_date: leaveDetails?.new_start_date
            ? getMySqlDate(leaveDetails?.new_start_date)
            : getMySqlDate(leaveDetails?.start_date),
          end_date: leaveDetails?.new_end_date
            ? getMySqlDate(leaveDetails?.new_end_date)
            : getMySqlDate(leaveDetails?.end_date),
        }
      );

      if (code === STATUS_CODE.SUCCESS) {
        setLoading(false);
        setIsModalOpen(false);
        updateSuccessModal({
          visibility: true,
          message: "Approved",
          Sucessicon: (
            <div className="d-flex justify-content-center align-itemw-center">
              <CheckCircleFilled style={{ color: "green" }} />
            </div>
          ),
        });
        getLeaveList();
      } else {
        message.error(`Something went wrong. Please try again.`);
      }
      resolve();
    });
  };

  const CSVHeaders = [
    { label: "Employee ID", key: "Employee ID" },
    { label: "User ID", key: "userid" },
    { label: "Name", key: "name" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Leave Type", key: "leave_category_name" },
    { label: "Days", key: "number_of_days" },
    { label: "Approved by", key: "approved_rejected_by" },
    { label: "Rejected by", key: "approved_rejected_by" },
    { label: "Amended Leave", key: "having_cancel_request" },
    { label: "Description", key: "reason" },
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeid",
      key: "employeeid",
      width: "7%",
    },
    {
      title: "User ID",
      dataIndex: "userid",
      key: "userid",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_start_date === "null" ||
          record.new_start_date === "" ||
          record.new_start_date === null
        ) {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "Start Day",
      dataIndex: "start_date",
      key: "start_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_start_date === "null" ||
          record.new_start_date === "" ||
          record.new_start_date === null
        ) {
          const getdayAlone = moment(record.start_date).format("dddd");
          return `${getdayAlone}`;
        } else {
          const getdayAlone = moment(record.new_start_date).format("dddd");
          return `${getdayAlone}`;
        }
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_end_date === "null" ||
          record.new_end_date === "" ||
          record.new_end_date === null
        ) {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    {
      title: "End Day",
      dataIndex: "end_date",
      key: "end_date",
      width: "10%",
      render: (_, record) => {
        if (
          record.new_end_date === "null" ||
          record.new_end_date === "" ||
          record.new_end_date === null
        ) {
          const getdayAlone = moment(record.end_date).format("dddd");
          return `${getdayAlone}`;
        } else {
          const getdayAlone = moment(record.new_end_date).format("dddd");
          return `${getdayAlone}`;
        }
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "10%",
    },
    {
      title: "Days",
      dataIndex: "number_of_days",
      key: "number_of_days",
      width: "7%",
      render: (_, record) => {
        if (
          record.new_number_of_days === null ||
          record.new_number_of_days === "" ||
          record.new_number_of_days === "null"
        ) {
          return record.number_of_days;
        } else {
          return record.new_number_of_days;
        }
      },
    },
  ];
  if (leaveStatusId === "5") {
    columns.push({
      title: "Approved by",
      dataIndex: "approved_rejected_byName",
      key: "approved_rejected_byName",
      width: "10%",
    });
  }
  if (leaveStatusId === "6") {
    columns.push(
      {
        title: "Rejected by",
        dataIndex: "approved_rejected_byName",
        key: "approved_rejected_byName",
        width: "5%",
      },
      {
        title: "Decline Reason",
        dataIndex: "decline_reason",
        key: "decline_reason",
        width: "15%",
        className: "normal-column",
        render: (reason) => {
          return (
            <>
              <ViewMore value={reason} />
            </>
          );
        },
      }
    );
  } else {
    columns.push({
      title: "Description",
      dataIndex: "reason",
      key: "reason",
      width: "50%",
      className: "normal-column",
      render: (reason) => {
        return (
          <>
            <ViewMore value={reason} />
          </>
        );
      },
    });
  }

  if (leaveStatusId === "4") {
    columns.push({
      title: "Amended Leave",
      dataIndex: "having_cancel_request",
      key: "having_cancel_request",
      width: "10%",

      render: (having_cancel_request) => {
        if (having_cancel_request === "1") {
          return "Yes";
        } else {
          return "No";
        }
      },
    });
  }
  if (
    leaveStatusId === "4" &&
    Menulist?.find(
      (item) =>
        item.access === true &&
        item.path === "allapprovals" &&
        item.subMenu?.some(
          (sub) => sub?.path === "approvals" && sub?.permission?.edit === true
        )
    )
  ) {
    columns.push({
      title: "Action",
      dataIndex: "address",
      key: "address",
      width: "10%",
      render: (_, record) => (
        <>
          <Tooltip title="View Leave">
            <EyeFilled
              className="action-button"
              onClick={() => viewLeave(record, true)}
            />
          </Tooltip>
          {(userRoleId === 1 || userRoleId === 6) && (
            <Tooltip title="Remind HOD & Assistant HOD" placement="left">
              <BellFilled
                className="action-button"
                onClick={() => remindSuperior(record)}
              />
            </Tooltip>
          )}
        </>
      ),
    });
  }

  const data2 = leaveList.filter((val) => {
    if (val.having_cancel_request == 0) {
      return val;
    }
  });

  useEffect(() => {
    getLeaveList();
  }, [
    // selectedDepartmentId,
    userRoleId,
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("start_date"),
    form.getFieldValue("duration_type"),
    form.getFieldValue("status_id"),
  ]);

  const getLeaveList = async () => {
    return new Promise(async (resolve, reject) => {
      setLeaveList([]);
      const { status_id, start_date, duration_type, month, end_date } =
        form.getFieldsValue();
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      if (status_id) {
        setLeaveStatusId(status_id);
        setDisplayTaskLoader(true);
        const { code, leaves = [] } = await CALL_API(
          `leave-list/${ClientId}`,
          "post",
          {
            status_id: status_id === "4" ? "" : status_id,
            reportingmanager: userInfo?.userid,
            department_id: [""],
            start_date: getMySqlDate(
              duration_type === 2
                ? startDateOfMonth
                : duration_type === 4
                ? momentDate(addMonthinDate(getinitialdate()))
                : start_date
            ),
            end_date: getMySqlDate(
              duration_type === 2
                ? lastDateOfMonth
                : duration_type === 3
                ? weeks(7, getMySqlDate(start_date))
                : duration_type === 4
                ? momentDate(addMonthinDate(getKenyanDateTime(), 3))
                : end_date
            ),
          }
        );

        setDisplayTaskLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          if (status_id === "4") {
            setLeaveList(leaves.reverse());
          } else {
            setLeaveList(leaves);
          }
          resolve(leaves);
        } else {
          reject([]);
        }
      } else {
        reject();
      }
    });
  };

  const viewLeave = (leaveObject, isViewOnly = false) => {
    toggleDisplayLeaveModal(true);
    setLeaveDetails({
      ...leaveObject,
      isViewOnly,
    });
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const remindSuperior = (leaveRecord) => {
    const { confirm } = Modal;
    confirm({
      icon: "",
      closable: true,
      content:
        "Do you want to send reminder notification to HOD & Assistant HOD?",
      async onOk() {
        const { user_leave_id, user_id } = leaveRecord;
        const { code } = await CALL_API(
          "leave-notification-to-hod-assistant-hod",
          "post",
          {
            user_leave_id,
            user_id,
          }
        );
        if (code === STATUS_CODE.SUCCESS) {
          message.success(`Notification has been sent.`);
        } else {
          message.error(`Something went wrong. Please try again.`);
        }
      },
      onCancel() {},
    });
  };

  function weeks(days, date) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date.toISOString().slice(0, 10);
  }
  const [fileNamelabel, setFileNameLabel] = useState("");
  const prepareCSV = async () => {
    let res = result(
      find(statusListFromID, (e) => {
        return e?.status_id === form.getFieldValue("status_id");
      }),
      "status_name"
    );
    setFileNameLabel(res);
    if (filterleavereport.length > 0) {
      setCSVLeaveList(
        reduce(
          filterleavereport,
          function (result, leave) {
            if (leaveStatusId === "4") {
              result.push({
                "Employee ID": leave?.employeeid,
                "User ID": leave?.userid,
                Name: leave?.name,
                Department: leave?.department_name,
                "Start Date":
                  leave?.new_start_date === "" ||
                  leave?.new_start_date === null ||
                  leave?.new_start_date === "null"
                    ? `${moment(leave?.start_date).format("DD MM YYYY")}`
                    : `${moment(leave?.new_start_date).format("DD MM YYYY")}`,
                "Start Day":
                  leave?.new_start_date === "" ||
                  leave?.new_start_date === null ||
                  leave?.new_start_date === "null"
                    ? moment(leave?.start_date).format("dddd")
                    : `${moment(leave?.new_start_date).format("dddd")}`,
                "End Date":
                  leave?.new_end_date === "" ||
                  leave?.new_end_date === null ||
                  leave?.new_end_date === "null"
                    ? `${moment(leave?.end_date).format("DD MM YYYY")}`
                    : `${moment(leave?.new_end_date).format("DD MM YYYY")}`,
                "End Day":
                  leave?.new_end_date === "" ||
                  leave?.new_end_date === null ||
                  leave?.new_end_date === "null"
                    ? `${moment(leave?.end_date).format("dddd")}`
                    : `${moment(leave?.new_end_date).format("dddd")}`,
                "Leave Type": leave?.leave_category_name,
                Days:
                  leave?.new_number_of_days === "" ||
                  leave?.new_number_of_days === null ||
                  leave?.new_number_of_days === "null"
                    ? leave?.number_of_days
                    : leave?.new_number_of_days,
                "Amended Leave":
                  leave?.having_cancel_request === 1 ||
                  leave?.having_cancel_request === "1"
                    ? "Yes"
                    : "No",
                Description: leave?.reason,
              });
            }
            if (leaveStatusId === "5") {
              result.push({
                "Employee ID": leave?.employeeid,
                "User ID": leave?.userid,
                Name: leave?.name,
                Department: leave?.department_name,
                "Start Date":
                  leave?.new_start_date === "" ||
                  leave?.new_start_date === null ||
                  leave?.new_start_date === "null"
                    ? `${moment(leave?.start_date).format("DD MM YYYY")}`
                    : `${moment(leave?.new_start_date).format("DD MM YYYY")}`,
                "Start Day":
                  leave?.new_start_date === "" ||
                  leave?.new_start_date === null ||
                  leave?.new_start_date === "null"
                    ? moment(leave?.start_date).format("dddd")
                    : `${moment(leave?.new_start_date).format("dddd")}`,
                "End Date":
                  leave?.new_end_date === "" ||
                  leave?.new_end_date === null ||
                  leave?.new_end_date === "null"
                    ? `${moment(leave?.end_date).format("DD MM YYYY")}`
                    : `${moment(leave?.new_end_date).format("DD MM YYYY")}`,
                "End Day":
                  leave?.new_end_date === "" ||
                  leave?.new_end_date === null ||
                  leave?.new_end_date === "null"
                    ? `${moment(leave?.end_date).format("dddd")}`
                    : `${moment(leave?.new_end_date).format("dddd")}`,
                "Leave Type": leave?.leave_category_name,
                Days:
                  leave?.new_number_of_days === "" ||
                  leave?.new_number_of_days === null ||
                  leave?.new_number_of_days === "null"
                    ? leave?.number_of_days
                    : leave?.new_number_of_days,
                "Approved by": leave?.approved_rejected_byName,
                Description: leave?.reason,
              });
            }
            if (leaveStatusId === "6") {
              result.push({
                "Employee ID": leave?.employeeid,
                "User ID": leave?.userid,
                Name: leave?.name,
                Department: leave?.department_name,
                "Start Date":
                  leave?.new_start_date === "" ||
                  leave?.new_start_date === null ||
                  leave?.new_start_date === "null"
                    ? `${moment(leave?.start_date).format("DD MM YYYY")}`
                    : `${moment(leave?.new_start_date).format("DD MM YYYY")}`,
                "Start Day":
                  leave?.new_start_date === "" ||
                  leave?.new_start_date === null ||
                  leave?.new_start_date === "null"
                    ? moment(leave?.start_date).format("dddd")
                    : `${moment(leave?.new_start_date).format("dddd")}`,
                "End Date":
                  leave?.new_end_date === "" ||
                  leave?.new_end_date === null ||
                  leave?.new_end_date === "null"
                    ? `${moment(leave?.end_date).format("DD MM YYYY")}`
                    : `${moment(leave?.new_end_date).format("DD MM YYYY")}`,
                "End Day":
                  leave?.new_end_date === "" ||
                  leave?.new_end_date === null ||
                  leave?.new_end_date === "null"
                    ? `${moment(leave?.end_date).format("dddd")}`
                    : `${moment(leave?.new_end_date).format("dddd")}`,
                "Leave Type": leave?.leave_category_name,
                Days:
                  leave?.new_number_of_days === "" ||
                  leave?.new_number_of_days === null ||
                  leave?.new_number_of_days === "null"
                    ? leave?.number_of_days
                    : leave?.new_number_of_days,
                "Rejected by": leave?.approved_rejected_byName,
                "Decline Reason": leave?.decline_reason,
              });
            }
            return result;
          },
          []
        )
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const STAFF_LIST_ALL = (
    leaveStatusId === "4" ? data1 : leaveStatusId === "5" ? data2 : leaveList
  ).map((val) => {
    return { user_id: val?.userid, name: val?.name };
  });
  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }
  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const filterleavereport = filter(
    leaveStatusId === "4" ? data1 : leaveStatusId === "5" ? data2 : leaveList,
    (val) => {
      if (form.getFieldValue("staff_name") === "") {
        return val;
      } else if (val?.userid === form.getFieldValue("staff_name")) {
        return val;
      }
    }
  ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };

  return (
    <>
      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                duration_type: 4,
                staff_name: "",
                month: dateInDetail(getKenyanDateTime()).monthName,
                status_id: "4",
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
              }}
              onFinish={getLeaveList}
            >
              <Form.Item
                name="status_id"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  items={statusListFromID}
                  fieldNames={{
                    label: "status_name",
                    value: "status_id",
                  }}
                  options={statusListFromID}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={APPROVAL_FILTER_TYPES}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Row>
                        <Col ref={containerRef}>
                          <Form.Item
                            name="start_date"
                            rules={[
                              () => ({
                                // first parameter is needed
                                validator(rule, value) {
                                  const date = moment(value);
                                  return date.isValid()
                                    ? Promise.resolve()
                                    : Promise.reject();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              format="DD-MM-YYYY"
                              placeholder="Start date"
                              onChange={validateDateRange}
                              allowClear={false}
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col ref={containerRef}>
                          <Form.Item
                            name="end_date"
                            rules={[
                              () => ({
                                // first parameter is needed
                                validator(rule, value) {
                                  const date = moment(value);
                                  return date.isValid()
                                    ? Promise.resolve()
                                    : Promise.reject();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              placeholder="End date"
                              format="DD-MM-YYYY"
                              onChange={validateDateRange}
                              allowClear={false}
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : duration_type === 3 ? (
                    <Form.Item
                      name="start_date"
                      rules={[
                        () => ({
                          // first parameter is needed
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid()
                              ? Promise.resolve()
                              : Promise.reject();
                          },
                        }),
                      ]}
                    >
                      <DatePicker
                        placeholder="Start date"
                        format="DD-MM-YYYY"
                        onChange={validateDateRange}
                        allowClear={false}
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  ) : duration_type === 2 ? (
                    <div ref={containerRef}>
                      <Form.Item
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select..."
                          options={MONTH_LIST}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </div>
                  ) : (
                    ""
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col
          xs={{ order: 1, span: 24 }}
          lg={{ order: 2, span: 4 }}
          className="align-right"
        >
          <ButtonOutlined onClick={prepareCSV}>Export CSV file</ButtonOutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVLeaveList}
            filename={`leave-list-${fileNamelabel}.csv`}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <Spin spinning={displayTaskLoader}>
        <StyledTable
          dataSource={filterleavereport}
          columns={columns}
          // first parameter is needed
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_leave_id"
          pagination={
            filterleavereport.length > 100 ? paginationOptions : false
          }
        />
      </Spin>
      {console.log("leave details--->", leaveDetails)}
      <LeaveFormModel
        isModalVisible={displayLeaveModal}
        closeModal={() => toggleDisplayLeaveModal(false)}
        userRoleId={userRoleId}
        leaveDetails={leaveDetails}
        toggleDisplayLeaveModal={toggleDisplayLeaveModal}
        getLeaveList={getLeaveList}
        setIsModalOpen={setIsModalOpen}
        setLeaveID={setLeaveID}
        sethavingCancel={sethavingCancel}
        ClientId={ClientId}
      />
      <Modal
        title="Approve leave"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Yes
          </Button>,
        ]}
      >
        <p>Are you sure you want to approve leave?</p>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, ["4", "5", "6"]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
  ClientId: getClientid(state),
  userList: getUserList(state),
  menuList: getMenuList(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Approval);
