import React, { useEffect } from "react";
import styled from "styled-components";
import {
  getUserName,
  getSelectedDepartmentId,
  getDepartmentList,
  getRoleName,
  getDepartmentName,
  getLoadingDataFirstTime,
  getUserRoleId,
  getRoleList,
  getUserRoleList,
  getNotificationList,
  getUserInfo,
  getProfilePic,
  getAllDepartmentList,
  getClientid,
  getMenuList,
} from "redux/selectors";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
// nee this
// import Task from "../assets/productlogo/Task.svg";
import COLORS from "common/Colors";
import Icon from "atoms/Icon";
import { setActiveMenuList, setMenuList } from "redux/actions";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { DEVICE } from "common/Device";

const StyledAppDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  color: white;
  width: 70px;
  padding: 2px;
  margin: auto;
  background-color: ${COLORS.sidebar};

  .icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding-top: 10px;
    svg {
      color: ${COLORS.TEXT.SECONDARY} !important;
      // fill: ${COLORS.TEXT.SECONDARY};
    }
  }
`;

const menuIconStyle = `
  font-size: 18px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;
const SideBarToggleIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  height: 60px;
  cursor: pointer;
  color: white;
`;

const BoxIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  height: 60px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background-color: #1d4174;
    color: #ffffff;
  }
  span {
    line-height: 1.5;
  }
`;

export function handleManagementView() {
  const jwtToken = window.localStorage.getItem("token");
  const hyperlink = `https://www.dev.mgntview.tymeplushr.com/?jwtToken=${jwtToken}`;
  window.open(hyperlink, "_blank");
}

const AppSideBar = ({
  collapsed,
  toggleCollapse,
  showDrawer,
  setCollapsed,
}) => {
  const activePage = useLocation().pathname.replace("/", "");
  const allMenuList = useSelector(getMenuList);
  const roleId = useSelector(getUserRoleId);
  const dispatch = useDispatch();

  const menuList = allMenuList[roleId]?.filter((item) => item?.system === "hr");

  const history = useHistory();

  const hasSubMenu = (menu) =>
    menu.subMenu?.length > 0 && menu.subMenu.some((subMenu) => subMenu.access);

  const isManagementViewWithAccess = (menu) =>
    menu.key === "managementview" && menu.access;

  const isNotProfile = (menu) => menu.key !== "profile";

  const menuListWithSubmenu = menuList?.filter((menu) => {
    return (
      (hasSubMenu(menu) || isManagementViewWithAccess(menu)) &&
      isNotProfile(menu)
    );
  });

  const isAnyMenuActive = menuListWithSubmenu?.some((menu) => menu.active);

  // need for future
  // function handleTaskView() {
  //   const jwtToken = window.localStorage.getItem("token");
  //   const hyperlink = `https://www.dev.app.tractask.com/?jwtToken=${jwtToken}&roleId=${userRoleId}`;
  //   window.open(hyperlink, "_blank");
  // }

  const handleOtherMenu = (item) => {
    setCollapsed(false);
    item.active = !item.active;

    dispatch(
      setMenuList({
        [roleId]: menuList.map((menu) => {
          if (menu.key === item.key) {
            return item;
          }
          return { ...menu, active: false };
        }),
      })
    );

    if (item.key === "managementview") {
      handleManagementView();
      return;
    }

    dispatch(
      setActiveMenuList({
        [roleId]: item.subMenu,
      })
    );

    const firstActiveSubMenu = item.subMenu.find((subMenu) => subMenu.access);
    history.push(firstActiveSubMenu.path);
  };

  const isHomeActive = !isAnyMenuActive;

  const handleHome = () => {
    dispatch(
      setMenuList({
        [roleId]: menuList.map((menu) => {
          return { ...menu, active: false };
        }),
      })
    );
    dispatch(
      setActiveMenuList({
        [roleId]: menuList?.filter((menu) => {
          return menu.subMenu?.length === 0 && menu.key !== "managementview";
        }),
      })
    );

    const firstMenu = menuList?.find((menu) => {
      return menu.subMenu?.length === 0 && menu.key !== "managementview";
    });
    history.push(firstMenu?.path);
  };

  useEffect(() => {
    if (activePage === "managementview") {
      handleHome();
    }
  }, [activePage]);

  return (
    <>
      {/* <div className="w-100 text-center">
        <StyledMenuToggle1 className="hide" collapsed={collapsed}>
          {React.createElement(
            collapsed ? StyledMenuFoldOutlined : StyledMenuUnfoldOutlined,
            {
              onClick: activePage !== "employee" ? toggleCollapse : "",
            }
          )}
        </StyledMenuToggle1>

        <StyledMenuToggle collapsed={collapsed}>
          {React.createElement(
            collapsed ? StyledMenuFoldOutlined : StyledMenuUnfoldOutlined,
            {
              onClick: showDrawer,
            }
          )}
        </StyledMenuToggle>
      </div> */}
      <StyledAppDiv>
        <SideBarToggleIcon onClick={toggleCollapse}>
          {/* {isSideBarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} */}
          <MenuOutlined />
        </SideBarToggleIcon>
        <div className="icons">
          <BoxIcon
            className={`${isHomeActive ? "active" : ""}`}
            onClick={() => handleHome()}
          >
            <Icon name="home" />
            <span>Home</span>
          </BoxIcon>

          {menuListWithSubmenu?.map((menu) => (
            <BoxIcon
              key={menu.id}
              className={`${menu?.active ? "active" : ""}`}
              onClick={() => handleOtherMenu(menu)}
            >
              {menu.icon && <Icon name={menu.icon} />}
              <span>{menu.title}</span>
            </BoxIcon>
          ))}
        </div>
      </StyledAppDiv>
    </>
  );
};

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  roleName: getRoleName(state),
  departmentName: getDepartmentName(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userRoleList: getUserRoleList(state),
  notificationList: getNotificationList(state),
  userInfo: getUserInfo(state),
  profilePic: getProfilePic(state),
  allDepartmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps)(AppSideBar);
