import styled from "@emotion/styled";
import { Checkbox, Form, Input, Modal, Select, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { ButtonFilled } from "reusableComponent/UIButtons";
import moment from "moment";

const StyledModal = styled(Modal)`
.ant-modal-wrap{
background: #fff !important
}
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    outline: 0;
    width: 100%;
    text-align: center;
  }
`;

function EditDeletepage({
  isModalVisible,
  closeModal,
  leaveCategoryList,
  leaveDetails,
}) {
  const [halfDay, setHalfDay] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const resetForm = () => {
    form.setFieldsValue({
      incidenttype: "",
      incidentlocation: "",
      date: moment(),
      time: "",
      description: "",
      remark: "",
    });
  };

  useEffect(() => {
    resetForm();
  }, [leaveCategoryList]);

  const updateHalfDay = (halfday) => {
    const { number_of_days } = form.getFieldsValue();
    if (halfday) {
      form.setFieldsValue({
        number_of_days: Math.max(0.5, number_of_days - 0.5),
      });
    } else {
      form.setFieldsValue({
        number_of_days: Math.ceil(number_of_days),
      });
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      if (
        moment(leaveDetails?.end_date).format("dddd") === "Saturday" &&
        moment(leaveDetails?.start_date).format("dddd") === "Saturday" &&
        moment(leaveDetails?.start_date).format("L") === moment(leaveDetails?.end_date).format("L")
      ) {
        setHalfDay(true);
      } else {
        setHalfDay(false);
      }
    }
  }, [isModalVisible, leaveDetails]);

  const restrictPastDate = (current, element) => {
    if (element === "end_date") {
      return current && current < moment(form.getFieldValue("start_date"));
    }
    return current && current < moment();
  };

  return (
    <StyledModal visible={isModalVisible} onCancel={closeModal} footer={null}>
      <Form form={form}>
        <Form.Item label="Leave Type" name="leave_type" rules={[{ required: true }]}>
          <Select
            options={leaveCategoryList?.map((item) => ({
              label: item.leave_category_name,
              value: item.leave_category_id,
            })) || []}
          />
        </Form.Item>

        <Form.Item label="Start Date" name="start_date" rules={[{ required: true }]}>
          <DatePicker disabledDate={(current) => restrictPastDate(current, "start_date")} />
        </Form.Item>
        <Form.Item label="End Date" name="end_date" rules={[{ required: true }]}>
          <DatePicker disabledDate={(current) => restrictPastDate(current, "end_date")} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item label="Half Day" name="halfday" valuePropName="checked">
          <Checkbox onChange={(e) => updateHalfDay(e.target.checked)} />
        </Form.Item>
        <Form.Item>
          <ButtonFilled htmlType="submit">Submit</ButtonFilled>
        </Form.Item>
      </Form>
    </StyledModal>
  );
}

export default EditDeletepage;
