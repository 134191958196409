import React from "react";
import { ReactComponent as Dashboard } from "assets/Dashboard - Grey.svg";
import { ReactComponent as Approvals } from "assets/Approval - Grey.svg";
import { ReactComponent as Approval } from "assets/Approval.svg";
import { ReactComponent as Settings } from "assets/Settings - Grey.svg";
import { ReactComponent as Calendar } from "assets/Calendar - Grey.svg";
import { ReactComponent as Team } from "assets/People- Grey.svg";
import { ReactComponent as Report } from "assets/Report  - Grey.svg";
import { ReactComponent as Attendance } from "assets/attendance.svg";
import { ReactComponent as Leave } from "assets/leave.svg";
import { ReactComponent as Break } from "assets/break.svg";
import { ReactComponent as Overtime } from "assets/overtime.svg";
import { ReactComponent as Location } from "assets/location.svg";
import { ReactComponent as Logout } from "assets/logout.svg";
import { ReactComponent as Notification } from "assets/notification.svg";
import { ReactComponent as Play } from "assets/play.svg";
import { ReactComponent as Calendar2 } from "assets/calendar_2.svg";
import { ReactComponent as StopBreak } from "assets/stop_break.svg";
import { ReactComponent as Task } from "assets/task.svg";
import { ReactComponent as Document } from "assets/documents.svg";
import { ReactComponent as Chat } from "assets/chat.svg";
import { ReactComponent as BabyCarrier } from "assets/baby_carrier.svg";
import { ReactComponent as PregnantWoman } from "assets/pregnant_woman.svg";
import { ReactComponent as MedicalBox } from "assets/medical_box.svg";
import { ReactComponent as Broadcast } from "assets/broadcast.svg";
import { ReactComponent as Upload } from "assets/upload.svg";
import { ReactComponent as Employee } from "assets/employee.svg";
import { ReactComponent as AddEmployee } from "assets/add_employee.svg";
import { ReactComponent as Xl } from "../assets/excel1.svg";
import { ReactComponent as Word } from "../assets/word.svg";
import { ReactComponent as Pdf } from "../assets/pdf.svg";
import { ReactComponent as Image } from "../assets/img.svg";
import { ReactComponent as Video } from "../assets/video.svg";
import { ReactComponent as Zip } from "../assets/zip.svg";
import { ReactComponent as Ppt } from "../assets/ppt.svg";
import { ReactComponent as Department } from "assets/department.svg";
import { ReactComponent as Exceptionreport } from "../assets/exceptionreport.svg";
import { ReactComponent as Earlycheckout } from "../assets/earlychekout.svg";
import { ReactComponent as Latecheckin } from "../assets/latecheckin.svg";
import { ReactComponent as Absentreport } from "../assets/absentreport.svg";
import { ReactComponent as Role } from "../assets/role.svg";
import { ReactComponent as Shift } from "../assets/shift.svg";
import { ReactComponent as Daily } from "../assets/daily.svg";
import { ReactComponent as Monthly } from "../assets/monthly.svg";
import { ReactComponent as Roles } from "../assets/roles.svg";
import { ReactComponent as Demographics } from "../assets/demographics.svg";
import { ReactComponent as Subdashboard } from "../assets/subdashboard.svg";
import { ReactComponent as Trends } from "../assets/trends.svg";
import { ReactComponent as Delete } from "../assets/deleteicon.svg";
import { ReactComponent as Appraisal } from "../assets/Apprisal.svg";
import { ReactComponent as Band } from "../assets/band.svg";
import { ReactComponent as Policy } from "../assets/policy.svg";
import { ReactComponent as DashboardPeople } from "../assets/dashboardpeople.svg";
import { ReactComponent as DashboardBreak } from "../assets/dashboardBreak.svg";
import { ReactComponent as DashboardDepartment } from "../assets/dashboardDepartment.svg";
import { ReactComponent as DashboardHoliday } from "../assets/dashboardHoliday.svg";
import { ReactComponent as DashboardLeave } from "../assets/dashboardLeave.svg";
import { ReactComponent as DashboardLocation } from "../assets/dashboardLocation.svg";
import { ReactComponent as DashboardRole } from "../assets/dashboardRole.svg";
import { ReactComponent as PeopleBtn } from "../assets/peoplebtn.svg";
import { ReactComponent as RoleBtn } from "../assets/rolebtn.svg";
import { ReactComponent as DepartmentBtn } from "../assets/departmentbtn.svg";
import { ReactComponent as LocationBtn } from "../assets/locationbtn.svg";
import { ReactComponent as BreakBtn } from "../assets/breakbtn.svg";
import { ReactComponent as LeaveBtn } from "../assets/leavebtn.svg";
import { ReactComponent as HolidayBtn } from "../assets/holidaybtn.svg";
import { ReactComponent as TrachBin } from "../assets/Trash_Full.svg";
import { ReactComponent as Edit } from "../assets/Edit_Pencil_01.svg";
import { ReactComponent as Search } from "../assets/Vector.svg";
import { ReactComponent as Filter } from "../assets/Filter.svg";
import { ReactComponent as LinkIcon } from "../assets/linkicon.svg";
import { ReactComponent as ProfileEdit } from "../assets/Frame-140.svg";
import { ReactComponent as Terminate } from "../assets/User_Close.svg";
import { ReactComponent as Travel } from "../assets/Frame 2147225394 (1).svg";
import { ReactComponent as MyFolder } from "../assets/My Folder - Grey.svg";
import { ReactComponent as FileUpload } from "../assets/fileupload.svg";
import { ReactComponent as DropDownFilter } from "../assets/dropdown_Filter.svg";
import { ReactComponent as ArrowDown } from "../assets/Chevron_Down.svg";
import { ReactComponent as Trash } from "../assets/trash.svg";
import { ReactComponent as ActivityLog } from "../assets/Activity log - Grey.svg";
import { ReactComponent as HR } from "../assets/HR - Grey.svg";
import { ReactComponent as Home } from "../assets/home.svg";
import { ReactComponent as AnalyticsIcon } from "../assets/analytics.svg";
import { ReactComponent as Travels } from "../assets/travels.svg";
import { ReactComponent as Tymeplusround } from "../assets/productlogo/tymeplus.svg";
import { ReactComponent as MyIncident } from "../assets/myincident.svg";
import { ReactComponent as MyDisciplinary } from "../assets/mydisciplinary.svg";

const styleIcon = {
  height: "20px",
  width: "20px",
};

const iconTypes = {
  dashboard: Dashboard,
  employee: Team,
  addemployee: AddEmployee,
  approvals: Approvals,
  calendar: Calendar,
  team: Team,
  report: Report,
  attendance: Attendance,
  leave: Leave,
  break: Break,
  overtime: Overtime,
  location: Location,
  logout: Logout,
  notification: Notification,
  play: Play,
  calendar2: Calendar2,
  stop_break: StopBreak,
  task: Task,
  document: Document,
  chat: Chat,
  pregnant_woman: PregnantWoman,
  baby_carrier: BabyCarrier,
  medical_box: MedicalBox,
  broadcast: Broadcast,
  upload: Upload,
  xl: Xl,
  word: Word,
  pdf: Pdf,
  image: Image,
  video: Video,
  zip: Zip,
  ppt: Ppt,
  department: Department,
  earlycheckout: Earlycheckout,
  latecheckin: Latecheckin,
  absentreport: Absentreport,
  exceptionreport: Exceptionreport,
  role: Role,
  shift: Shift,
  daily: Daily,
  monthly: Monthly,
  roles: Roles,
  trends: Trends,
  demographics: Demographics,
  subdashboard: Subdashboard,
  delete: Delete,
  appraisal: Appraisal,
  policy: Policy,
  band: Band,
  dashboardrole: DashboardRole,
  dashboardbreak: DashboardBreak,
  dashboardlocation: DashboardLocation,
  dashboarddepartment: DashboardDepartment,
  dashboardleave: DashboardLeave,
  dashboardholiday: DashboardHoliday,
  dashboardpeople: DashboardPeople,
  peoplebtn: PeopleBtn,
  rolebtn: RoleBtn,
  departmentbtn: DepartmentBtn,
  locationbtn: LocationBtn,
  leavebtn: LeaveBtn,
  breakbtn: BreakBtn,
  holidaybtn: HolidayBtn,
  trash: TrachBin,
  trashbin: TrachBin,
  edit: Edit,
  search: Search,
  filter: Filter,
  linkicon: LinkIcon,
  profileEdit: ProfileEdit,
  terminate: Terminate,
  travel: Travel,
  folder: MyFolder,
  fileupload: FileUpload,
  dropDownFilter: DropDownFilter,
  arrowDown: ArrowDown,
  trash: Trash,
  activitylog: ActivityLog,
  settings: Settings,
  hr: HR,
  home: Home,
  analytics: AnalyticsIcon,
  travels: Travels,
  tymeplusround: Tymeplusround,
  approval: Approval,
  mydisciplinary: MyDisciplinary,
  myincident: MyIncident,
};

const Icon = ({ name, ...props }) => {
  let Icon = iconTypes[name];
  if (!Icon) {
    console.error(`Icon with name "${name}" does not exist in iconTypes.`);
    return null; // Return null to avoid rendering an invalid component
  }
  return <Icon style={styleIcon} {...props} />;
};
export default Icon;
