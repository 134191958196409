import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@mui/icons-material";
import axios from "axios";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Upload,
} from "antd";
import styled from "styled-components";
import { ReactComponent as Edit } from "../../assets/Edit_Pencil_01.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { ReactComponent as UploadIcon } from "../../assets/uploadicon.svg";
import { ReactComponent as Tick } from "../../assets/tick.svg";
import { ReactComponent as Suspend } from "../../assets/suspend.svg";
import ViewDetailsDisciplinary from "./ViewDetailsDisciplinary";
import CategoryView from "./CategoryView";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";
import {
  NON_EMPTY_REGEX,
  STATUS_CODE,
  VALIDATE_SYMBOL,
} from "common/Constants";
import { CALL_API } from "common/API";
import dayjs from "dayjs";
const props = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const Styleddiv = styled.div`
  .header-disciplinary {
    display: flex;
    justify-content: space-between;
  }
  .header-text {
    font-size: 20px;
    font-weight: 500;
  }
  .create-disciplinary {
    width: 170px;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
  }
  .total-ticket {
    font-size: 16px;
    font-weight: 400;
  }
  .category-view {
    border: none;
    color: #2d53da;
    font-size: 16px;
    font-weight: 400;
  }
  .all-field-style {
    display: flex;
    gap: 10px;
  }
  .searchbar {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .searchicon {
    color: #636980;
  }
  .category-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .category-dropdown:hover .ant-select-selector {
    border-color: #e2e2e8 !important;
  }
  .category-dropdown.ant-select-focused .ant-select-selector {
    border-color: #e2e2e8 !important;
  }
  .custom-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .custom-dropdown:hover .ant-select-selector {
    border-color: #e2e2e8 !important;
  }
  .custom-dropdown.ant-select-focused .ant-select-selector {
    border-color: #e2e2e8 !important;
  }
  .datepicker-style {
    width: 100px;
    height: 34px;
    border-radius: 24px;
  }
  .action-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .ant-table {
    border: 1px solid #e2e2e8;
    border-radius: 8px;
  }
  .ant-table-thead > tr > th {
    font-size: 16px;
    font-weight: 500;
    color: #1f243b;
  }
  .ant-table-tbody > tr > td {
    font-size: 15.2px;
    font-weight: 400;
    color: #636980;
  }
  .created-by {
    position: relative;
    display: flex;
    align-items: center;
  }
  .created-by .name-text {
    display: block;
  }
  .created-by .action-icons {
    display: none;
    gap: 15px;
  }
  .hover-row .created-by .name-text {
    display: none;
  }
  .hover-row .created-by .action-icons {
    display: flex;
  }
  .clearance-text {
    color: #08c108;
  }
  .suspend-text {
    color: #2d53da;
    text-decoration: underline;
  }
  .no-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #2d53da;
  }
  .yes-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
  }
  .date-range-container {
    display: flex !important;
    gap: 10px;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal {
    top: 0 !important; /* Moves the modal to the top of the screen */
    margin-top: 0 !important; /* Remove any margin at the top */
    position: fixed !important; /* Ensure it stays fixed to the top */
  }
  .ant-modal-title {
    font-size: 18px;
    font-weight: 500;
  }
  .custom-cancel-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #2d53da;
    font-size: 16px;
    font-weight: 400;
    color: #2d53da;
  }
  .custom-ok-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    background-color: #2d53da;
    color: #ffffff;
  }
  .form-split {
    display: flex;
    gap: 32px;
  }
  .input-width {
    width: 50%;
  }
  .form-datepicker {
    width: 100%;
  }
  .upload-style {
    width: 190px;
    height: 37px;
    border-radius: 5px;
    background-color: #e3e8fc;
    font-size: 16px;
    font-weight: 400;
    color: #2d53da;
  }
  .upload-icon {
    margin-bottom: 3px;
  }
  .button-style {
    gap: 20px;
  }
  .success-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sent-successfully {
    font-size: 16px;
    font-weight: 400;
  }
  .suspension-from {
    display: flex;
    gap: 150px;
  }
  .suspension-to {
    display: flex;
    gap: 167px;
  }
  .payment-type {
    display: flex;
    gap: 170px;
  }
  .suspension-title {
    font-weight: 500;
    font-size: 14px;
    color: #636980;
  }
  .suspension-data {
    font-weight: 400;
    font-size: 16px;
  }
  .close-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #2d53da;
    color: #2d53da;
  }
  .no-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #2d53da;
    color: #2d53da;
  }
  .yes-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    background-color: #2d53da;
    color: #ffffff;
  }
  .delete-modal-text {
    font-weight: 400;
    font-size: 16px;
    color: #636980;
  }
  .yes-no-button {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
`;
function HrMyDisciplinary({ clientId }) {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedDisciplinary, setSelectedDisciplinary] = useState(null);
  //const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);
  const [form] = Form.useForm();
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [selectedSuspendRecord, setSelectedSuspendRecord] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeletedModalOpen, setIsDeletedModalOpen] = useState(false);
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSavedModalOpen, setIsSavedModalOpen] = useState(false);
  const [selectedCategoryView, setSelectedCategoryView] = useState(null);
  const [showDateRange, setShowDateRange] = useState(false); // State to track custom date range selection
  const [fileList, setFileList] = useState([]);
 // const [datum, setData] = useState([]);
  const [showLoader, toggleLoader] = useState(false);
  const [assetsData, setDisData] = useState([]);
  const [deletedata, setDeletedata] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editData, setEditData] = useState([]);
  const [showmessage, setShowMessage] = useState(false);

  const handleSelectChange = (value) => {
    if (value === "custom") {
      setShowDateRange(true); // Show date pickers if "Custom Date range" is selected
    } else {
      setShowDateRange(false); // Hide date pickers for other options
    }
  };
  const handleRowClick = (record) => {
    setSelectedPath(record);
  };

  const handleGoBack = () => {
    setSelectedPath(false);
  };
  const handleSuspendClick = (record) => {
    setSelectedSuspendRecord(record); // Store selected record
    setIsSuspendModalOpen(true); // Open modal
  };
  const handleCreateDisciplinary = () => {
    setIsEditing(false);
    setSelectedDisciplinary(null);
    form.resetFields();
    setIsModalOpen(true);
  };

  const parseDate = (date, format = "DD-MM-YYYY") =>
    dayjs(date, format).isValid() ? dayjs(date, format) : "";

  const handleEditDisciplinary = (record) => {
    console.log("record---->", record);
    setIsEditing(true);
    setSelectedDisciplinary(record);
    form.setFieldsValue({
      ...record,
      dateofincident: parseDate(record?.dateofincident),
      created_on: parseDate(record?.created_on),
    });

    setIsModalOpen(true);
    console.log("form values -->", form.getFieldsValue());
  };
  useEffect(() => {
    console.log("form-->", form.getFieldsValue());
  }, [form]);
  const handleOk = async () => {
    try {
      await form.validateFields();
      setIsModalOpen(false);

      // Set modal message based on action
      setSuccessMessage(isEditing ? "Changes Saved" : "Sent Successfully");
      setIsSavedModalOpen(true);

      // Auto-close after 2 seconds
      setTimeout(() => {
        setIsSavedModalOpen(false);
      }, 2000);
    } catch (error) {
      message.error("Please fill in all required fields.");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDeleteClick = (record) => {
    setSelectedDeleteRecord(record);
    setIsDeleteOpen(true);
  };
  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };
  const handleDeleteConfirm = () => {
    setIsDeleteOpen(false); // Close the delete confirmation modal
    setIsDeletedModalOpen(true); // Show "Deleted" modal
    setTimeout(() => {
      setIsDeletedModalOpen(false); // Auto-close after 2 seconds
    }, 2000);
  };

  const handleCategoryViewClick = () => {
    setSelectedCategoryView(true);
  };
  const handleCategoryViewBack = () => {
    setSelectedCategoryView(false);
  };

  // const data = [
  //   {
  //     key: "1",
  //     disciplinaryId: "DISI123456",
  //     employee: "Joseph Wick",
  //     employeeID: "EM1234",
  //     date_of_incident: "25th Augesh, 2025",
  //     category: "Criminal Offense",
  //     action: "First Warning",
  //     moved_to: "Clearance",
  //     created_on: "25th Augesh, 2025",
  //     created_by: "Alvin David",
  //   },
  //   {
  //     key: "2",
  //     disciplinaryId: "DISI123456",
  //     employee: "Joseph Wick",
  //     employeeID: "EM1234",
  //     date_of_incident: "25th Augesh, 2025",
  //     category: "Criminal Offense",
  //     action: "Second Warning",
  //     moved_to: "Suspend",
  //     created_on: "25th Augesh, 2025",
  //     created_by: "Alvin David",
  //   },
  //   {
  //     key: "3",
  //     disciplinaryId: "DISI123456",
  //     employee: "Joseph Wick",
  //     employeeID: "EM1234",
  //     date_of_incident: "25th Augesh, 2025",
  //     category: "Criminal Offense",
  //     action: "First Warning",
  //     moved_to: "Clearance",
  //     created_on: "25th Augesh, 2025",
  //     created_by: "Alvin David",
  //   },
  //   {
  //     key: "4",
  //     disciplinaryId: "DISI123456",
  //     employee: "Joseph Wick",
  //     employeeID: "EM1234",
  //     date_of_incident: "25th Augesh, 2025",
  //     category: "Criminal Offense",
  //     action: "Second Warning",
  //     moved_to: "Suspend",
  //     created_on: "25th Augesh, 2025",
  //     created_by: "Alvin David",
  //   },
  // ];

  const columns = [
    {
      title: "Discip.ID",
      dataIndex: "disciplinaryId",
      key: "disciplinaryId",
    },
    {
      title: "Name",
      dataIndex: "employee",
      key: "employee",
    },
    {
      title: "Employee ID",
      dataIndex: "employeeID",
      key: "employeeID",
    },
    {
      title: "Date of Incident",
      dataIndex: "dateofincident",
      key: "dateofincident",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
    },
    // {
    //   title: "Moved to",
    //   dataIndex: "moved_to",
    //   key: "moved_to",
    // },
    {
      title: "Moved to",
      dataIndex: "moved_to",
      key: "moved_to",
      render: (text, record) => {
        if (text === "Clearance") {
          return <span className="clearance-text">{text}</span>;
        } else if (text === "Suspend") {
          return (
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleSuspendClick(record);
              }}
            >
              <span className="suspend-text">{text}</span>
              &nbsp;
              <Suspend />
            </span>
          );
        }
      },
    },
    {
      title: "Created on",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      render: (text, record) => (
        <div className="created-by">
          <span className="name-text">{text}</span>
          <span className="action-icons">
            <Edit
              onClick={(event) => {
                event.stopPropagation();
                handleEditDisciplinary(record);
              }}
            />
            <Trash
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(record);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  const [dateValue, setDateValue] = useState("");

  const handleFormSubmit = async () => {
    try {
      // Validate the form
      const formData = await form.validateFields();
      // Prepare the payload
      const payload = {
        employee: formData.employee,
        category: formData.category,
        actions: formData.actions,
        dateofincident: formData.dateofincident,
        noofappeal: formData.noofappeal,
        description: formData.description,
        attachment: formData.attachment,
      };
      // Create FormData if image is included, as API expects a multipart form
      const formDataToSend = new FormData();
      formDataToSend.append("name", payload.employee);
      formDataToSend.append("category", payload.category);
      formDataToSend.append("action", payload.actions);
      formDataToSend.append("name", payload.dateofincident);
      formDataToSend.append("category", payload.noofappeal);
      formDataToSend.append("action", payload.description);

      console.log("formDataToSend--->", formDataToSend);
      console.log("payload--->", payload);
      if (payload.attachment) {
        formDataToSend.append("image", payload.attachment);
      }
      // Make POST request
      const { response, code } = await CALL_API(
        "/disciplinary",
        "post",
        payload
      );
      if (code === STATUS_CODE.SUCCESS) {
        console.log("API Response:", response);
        setIsModalOpen(false);
        setIsSavedModalOpen(true);
        form.resetFields();
        setFileList([]);
        setTimeout(() => {
          setIsSavedModalOpen(false);
        }, 2000);
      }
    } catch (error) {
      console.error("API Error:", error);
      message.error("Failed to add asset. Please try again.");
    }
  };
  // Table data source

  const [loading, setLoading] = useState(false);

  const getDisData = async () => {
    try {
      toggleLoader(true); // Show loader while fetching data

      // Make GET request
      const { data, code } = await CALL_API("disciplinary", "get", {});

      if (code === "SUCCESS") {
        setDisData(data);
      } else {
        message.error("Failed to fetch data. Please try again.");
      }
    } catch (error) {
      console.error("API Error:", error);
      message.error("Something went wrong while fetching data.");
    } finally {
      toggleLoader(false); // Hide loader after API call
    }
  };

  useEffect(() => {
    getDisData(); // Call the correct function
  }, []);
  const deleteDisci = async () => {
    // let name = editData.assetName;
    setDeleteLoading(true);

    try {
      const { code } = await CALL_API(
        `disciplinary/${clientId}/${selectedDeleteRecord?.disciplinaryId}`,
        "delete"
      );

      if (code === "SUCCESS") {
        getDisData();
        setDeleteLoading(false);
        setShowMessage(false);
        setDeletedata(false);
        setEditData([]);
        message("Deleted successfully");
      } else {
        message.error("Failed to Delete");
      }
    } catch (error) {
      console.error("API Error:", error);
      message.error("Something went wrong while deleting.");
    }
    finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <Styleddiv>
        {selectedCategoryView ? (
          <CategoryView onBack={handleCategoryViewBack} />
        ) : selectedPath ? (
          <ViewDetailsDisciplinary data={selectedPath} onBack={handleGoBack} />
        ) : (
          <div>
            <div className="header-disciplinary">
              <div className="header-text">Disciplinary Management</div>
              <div>
                <Button
                  type="primary"
                  onClick={handleCreateDisciplinary}
                  className="create-disciplinary"
                >
                  Create Disciplinary
                </Button>
              </div>
            </div>
            <br />
            <div className="header-disciplinary">
              <div className="total-ticket">Total Tickets: 7</div>
              <Button
                className="category-view"
                onClick={handleCategoryViewClick}
              >
                Category View
              </Button>
            </div>
            <br />
            <div className="all-field-style">
              <div>
                <Input
                  className="searchbar"
                  placeholder="Search"
                  prefix={<SearchOutlined className="searchicon" />}
                />
              </div>
              <div>
                <Select placeholder="Category" className="category-dropdown">
                  <Select.Option value="absence">
                    Absence from duty without permission
                  </Select.Option>
                  <Select.Option value="unwillingness">
                    Unwillingness and inability to perform duties
                  </Select.Option>
                  <Select.Option value="damage">
                    Damage to Company property
                  </Select.Option>
                  <Select.Option value="loss">
                    Loss of Company property
                  </Select.Option>
                  <Select.Option value="unauthorized">
                    Unauthorized possession of Company property
                  </Select.Option>
                  <Select.Option value="insubordination">
                    Insubordination
                  </Select.Option>
                  <Select.Option value="use">
                    Use of abusive language, harassment, or bullying
                  </Select.Option>
                  <Select.Option value="gross">Gross negligence</Select.Option>
                  <Select.Option value="wilful">
                    Wilful damage to or theft of company property
                  </Select.Option>
                  <Select.Option value="fraud">
                    Fraud or dishonesty in dealing with the comapny
                  </Select.Option>
                  <Select.Option value="theft">
                    Theft or misappropriation of Company funds
                  </Select.Option>
                  <Select.Option value="under">
                    Under drugs or alcohol during working hours
                  </Select.Option>
                  <Select.Option value="noncompliance">
                    Noncompliance to standard oparting procedure
                  </Select.Option>
                </Select>
              </div>
              <div>
                <Select
                  placeholder="Date"
                  className="action-dropdown"
                  onChange={handleSelectChange}
                >
                  <Select.Option value="work">Last week</Select.Option>
                  <Select.Option value="first">Last month</Select.Option>
                  <Select.Option value="second">Last 6 months</Select.Option>
                  <Select.Option value="custom">
                    Custom Date range
                  </Select.Option>
                </Select>
              </div>
              {showDateRange && ( // Conditionally render the date pickers
                <div className="date-range-container">
                  <div>
                    <DatePicker
                      placeholder="From"
                      className="datepicker-style"
                    />
                  </div>
                  <div>
                    <DatePicker placeholder="To" className="datepicker-style" />
                  </div>
                </div>
              )}
              <div>
                <Select placeholder="Action" className="action-dropdown">
                  <Select.Option value="verbal">Verbal Warning</Select.Option>
                  <Select.Option value="first">First Warning</Select.Option>
                  <Select.Option value="second">Second Warning</Select.Option>
                  <Select.Option value="third">
                    Third & Final Warning
                  </Select.Option>
                  <Select.Option value="show">Show Cause</Select.Option>
                  <Select.Option value="suspend">Suspend</Select.Option>
                  <Select.Option value="demotion">Demotion</Select.Option>
                  <Select.Option value="exoneration">Exoneration</Select.Option>
                  <Select.Option value="clearance">Clearance</Select.Option>
                  <Select.Option value="termination">Termination</Select.Option>
                </Select>
              </div>
            </div>
            <br />
            <div>
              <Table
                columns={columns}
                dataSource={assetsData}
                onRow={(record) => ({
                  onMouseEnter: () => setHoveredRow(record.key),
                  onMouseLeave: () => setHoveredRow(null),
                  className: hoveredRow === record.key ? "hover-row" : "",
                  onClick: (e) => {
                    e.stopPropagation();
                    handleRowClick(record);
                  },
                })}
                scroll={{ x: "max-content" }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <StyledModal
              width={950}
              height={120}
              title={
                isEditing
                  ? `Editing Disciplinary (${selectedDisciplinary?.disciplinaryId})`
                  : "Creating Disciplinary"
              }
              open={isModalOpen}
              onOk={handleFormSubmit}
              onCancel={handleCancel}
              getDisData={getDisData}
              assetsData={assetsData}
              // cancelText="Cancel"
              // okText="Create & Send"
              // okButtonProps={{ className: "custom-ok-button" }}
              // cancelButtonProps={{ className: "custom-cancel-button" }}
              style={{ top: "10px" }}
              // className="custom-modal-footer"
              footer={
                <div
                  style={{
                    marginRight: "10px",
                    marginTop: "-10px",
                    marginBottom: "10px",
                  }}
                >
                  <Button
                    className="custom-cancel-button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="custom-ok-button"
                    onClick={() => {
                      handleFormSubmit();
                      getDisData();
                    }}
                    getDisData={getDisData}
                  >
                    {isEditing ? "Save Changes" : "Create & Send"}
                  </Button>
                </div>
              }
            >
              <Form layout="vertical" name="disciplinary-form" form={form}>
                <Form.Item
                  label="Employee"
                  name="employee"
                  rules={[
                    { required: true, message: "Please select employee" },
                  ]}
                >
                  <Select placeholder="Select employee">
                    <Select.Option value="John Williams">
                      John Wiiliams
                    </Select.Option>
                  </Select>
                </Form.Item>

                <div className="form-split">
                  <div className="input-width">
                    <Form.Item
                      label="Category"
                      name="category"
                      rules={[
                        { required: true, message: "Please select category" },
                      ]}
                    >
                      <Select placeholder="Crminial Offense">
                        <Select.Option value="absence">
                          Absence from duty without permission
                        </Select.Option>
                        <Select.Option value="unwillingness">
                          Unwillingness and inability to perform duties
                        </Select.Option>
                        <Select.Option value="damage">
                          Damage to Company property
                        </Select.Option>
                        <Select.Option value="loss">
                          Loss of Company property
                        </Select.Option>
                        <Select.Option value="unauthorized">
                          Unauthorized possession of Company property
                        </Select.Option>
                        <Select.Option value="insubordination">
                          Insubordination
                        </Select.Option>
                        <Select.Option value="use">
                          Use of abusive language, harassment, or bullying
                        </Select.Option>
                        <Select.Option value="gross">
                          Gross negligence
                        </Select.Option>
                        <Select.Option value="wilful">
                          Wilful damage to or theft of company property
                        </Select.Option>
                        <Select.Option value="fraud">
                          Fraud or dishonesty in dealing with the comapny
                        </Select.Option>
                        <Select.Option value="theft">
                          Theft or misappropriation of Company funds
                        </Select.Option>
                        <Select.Option value="under">
                          Under drugs or alcohol during working hours
                        </Select.Option>
                        <Select.Option value="noncompliance">
                          Noncompliance to standard oparting procedure
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="input-width">
                    <Form.Item
                      label="Action"
                      name="actions"
                      rules={[
                        { required: true, message: "Please select action" },
                      ]}
                    >
                      <Select placeholder="First Warning">
                        <Select.Option value="verbal">
                          Verbal Warning
                        </Select.Option>
                        <Select.Option value="first">
                          First Warning
                        </Select.Option>
                        <Select.Option value="second">
                          Second Warning
                        </Select.Option>
                        <Select.Option value="third">
                          Third & Final Warning
                        </Select.Option>
                        <Select.Option value="show">Show Cause</Select.Option>
                        <Select.Option value="suspend">Suspend</Select.Option>
                        <Select.Option value="demotion">Demotion</Select.Option>
                        <Select.Option value="exoneration">
                          Exoneration
                        </Select.Option>
                        <Select.Option value="clearance">
                          Clearance
                        </Select.Option>
                        <Select.Option value="termination">
                          Termination
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="form-split">
                  <div className="input-width">
                    <Form.Item
                      label="Date of Incident"
                      name="dateofincident"
                      rules={[
                        {
                          required: true,
                          message: "Please select date of incident",
                        },
                      ]}
                    >
                      <DatePicker
                        className="form-datepicker"
                        // defaultValue={dateValue ? dayjs(dateValue) : null} // Ensure dateValue is parsed into a Moment object
                        format="Do MMMM, YYYY"
                        // }} // Save as ISO format
                        placeholder="Select a date"
                      />
                    </Form.Item>
                  </div>
                  <div className="input-width">
                    <Form.Item
                      label="Number of Appeals the employee can have"
                      name="noofappeal"
                      rules={[
                        {
                          required: true,
                          message: "Please select Number of Appeals",
                        },
                      ]}
                    >
                      <Select placeholder="1">
                        <Select.Option value="1">1</Select.Option>
                        <Select.Option value="2">2</Select.Option>
                        <Select.Option value="3">3</Select.Option>
                        <Select.Option value="4">4</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <Form.Item label="Description" name="description">
                  <Input.TextArea placeholder="Description" rows={2} />
                </Form.Item>
                <Form.Item label="Attachment (Optional)" name="attachment">
                  <Upload {...props}>
                    <Button className="upload-style">
                      <UploadIcon className="upload-icon" /> &nbsp; Add
                      Attachment
                    </Button>
                  </Upload>
                </Form.Item>
              </Form>
            </StyledModal>
            <StyledModal
              width={190}
              height={70}
              open={isSavedModalOpen}
              onCancel={() => setIsSavedModalOpen(false)}
              footer={false}
              closable={false}
              style={{ marginTop: "130px" }}
            >
              <div className="success-modal">
                <Tick />
                <p className="sent-successfully">success message</p>
              </div>
            </StyledModal>
            <StyledModal
              width={570}
              height={300}
              title="Suspension Details"
              open={isSuspendModalOpen}
              onCancel={() => setIsSuspendModalOpen(false)}
              footer={[
                <Button
                  key="cancel"
                  onClick={() => setIsSuspendModalOpen(false)}
                  className="close-button"
                >
                  Close
                </Button>,
              ]}
            >
              <div className="suspension-from">
                <div className="suspension-title">Suspension From</div>
                <div className="suspension-data">05th Aug 2024</div>
              </div>
              <br />
              <div className="suspension-to">
                <div className="suspension-title">Suspension To</div>
                <div className="suspension-data">08th Aug 2024</div>
              </div>
              <br />
              <div className="payment-type">
                <div className="suspension-title">Payment Type</div>
                <div className="suspension-data">With Pay</div>
              </div>
            </StyledModal>
            <StyledModal
              width={470}
              height={140}
              footer={false}
              closable={false}
              open={isDeleteOpen}
              onCancel={handleDeleteCancel}
              style={{ marginTop: "100px" }}
            >
              <p className="delete-modal-text">
                Are you sure you want to delete this disciplinary ticket?
              </p>
              <br />
              <div className="yes-no-button">
                <Button onClick={handleDeleteCancel} className="no-button">
                  No
                </Button>
                <Button
                  loading={deleteLoading}
                  onClick={async () => {
                    await deleteDisci(); // Call delete function
                    handleDeleteConfirm(); // Call after delete completes
                  }}
                  className="yes-button"
                >
                  Yes
                </Button>
              </div>
            </StyledModal>
            <StyledModal
              width={190}
              height={70}
              open={isDeletedModalOpen}
              onCancel={() => setIsDeletedModalOpen(false)}
              footer={false}
              closable={false}
              style={{ marginTop: "130px" }}
            >
              <div className="success-modal">
                <Tick />
                <p className="sent-successfully">Deleted</p>
              </div>
            </StyledModal>
          </div>
        )}
      </Styleddiv>
    </>
  );
}

const mapStateToProps = (state) => ({
  clientId: getClientid(state),
});

export default connect(mapStateToProps)(HrMyDisciplinary);
