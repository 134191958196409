import React, { useRef, useState } from "react";
import {
  Grid,
  IconButton,
  Switch,
  Box,
  Button as MuiButton,
  Tooltip,
  FormControl,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Radio,
  Checkbox,
} from "@mui/material";
import { Button } from "antd";
import { message } from "antd";
import { CALL_API } from "common/API";
import { CustomTextField } from "common/common";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectFormQuestions, formQuestions } from "./formSlice";
import FormOptions from "./options";
import { CustomIconButton } from "../../common/common";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Select, Divider } from "antd";

const { Option } = Select;

const optionLabelStyle = {
  textTransform: "none",
  color: "#C9C9C9",
  fontWeight: 400,
};
function EachFormSection({
  fullFormDetail,
  setFullFormDetail,
  formbasicDetail,
  setSavedForm,
  pageView,
  selectedItem,
  setPageView,
  fetchData,
  isFormBuilder = false,
  addEditFormBuilder,
  access = true,
}) {
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);

  let history = useHistory();
  const createdQuestions = useAppSelector(selectFormQuestions);

  const [loading, setLoading] = useState(false);

  const [additionalFormOption, setAdditionalFormOption] = useState(null);

  const handleAdditionalFormClick = (event) => {
    setAdditionalFormOption(event.currentTarget);
  };

  const handleAdditionalFormClose = () => {
    setAdditionalFormOption(null);
  };

  React.useEffect(() => {
    if (fullFormDetail?.length === 0) {
      // Add a default question form
      setFullFormDetail([
        {
          question: "",
          question_placeholder: "Question",
          options: [{ optionText: "Option 1", placeholder: "Option 1" }],
          formType: "MULIPLE_CHOICE",
          id: 1,
          requiredSwitch: false,
        },
      ]);
    }
  }, []);

  React.useEffect(() => {
    const autoSaveForm = fullFormDetail;
    dispatch(formQuestions(autoSaveForm));
  }, [fullFormDetail]);

  //question value
  const handleQuestionValue = (text, i) => {
    var questionText = [...fullFormDetail];
    questionText[i].question = text;
    setFullFormDetail(questionText);
  };

  // Add option
  const addOption = (i) => {
    var addOptions = [...fullFormDetail];
    // Only Add option is for Dropdown,Checkbox and multi choice
    addOptions[i].options.push({
      optionText: "Option " + (addOptions[i].options.length + 1),
    });
    setFullFormDetail(addOptions);
  };

  //Delete each form
  const deleteQuestion = (i) => {
    let fullFormContent = [...fullFormDetail];
    fullFormContent.splice(i, 1);
    setFullFormDetail(fullFormContent);
  };

  //option value
  const handleOptionValue = (text, i, optionindex) => {
    const optionValue = [...fullFormDetail];
    optionValue[i].options[optionindex].optionText = text;
    setFullFormDetail(optionValue);
  };

  //select form type
  const handleSeleteFormChange = (value, i) => {
    const optionType = [...fullFormDetail];
    optionType[i].formType = value;

    //Not to carry options array when user changes the option type
    //Default 1 option should be their for every option
    optionType[i].options = [{ optionText: "Option 1" }];
    setFullFormDetail(optionType);
  };

  //to indicate mandatory question
  const handleRequiredChange = (event, i) => {
    const requireQuestion = [...fullFormDetail];
    requireQuestion[i].requiredSwitch = event.target.checked;
    setFullFormDetail(requireQuestion);
  };

  //remove each option
  const optionRemove = (i, optionindex) => {
    const questionOption = [...fullFormDetail];
    //Only when option is more than one user can delete the options
    if (questionOption[i].options.length > 1) {
      questionOption[i].options.splice(optionindex, 1);
      setFullFormDetail(questionOption);
    }
  };

  //create duplicate of a form
  const createDuplicate = (i) => {
    const copyForm = [...fullFormDetail];
    const newOptions = [];
    copyForm[i].options.forEach((opn) => {
      const opn1new = {
        optionText: opn?.optionText,
      };
      newOptions.push(opn1new);
    });
    const newQuestion = {
      question: copyForm[i].question,
      options: newOptions,
      formType: copyForm[i].formType,
      id: fullFormDetail.length + 1,
      requiredSwitch: false,
    };
    setFullFormDetail((fullFormDetail) => [...fullFormDetail, newQuestion]);
  };

  //Add a form
  const addExtraForm = () => {
    setFullFormDetail((fullFormDetail) => [
      ...fullFormDetail,
      {
        question: "",
        question_placeholder: "Question",
        options: [{ optionText: "Option 1" }],
        formType: "MULIPLE_CHOICE",
        id: fullFormDetail.length + 1,
        requiredSwitch: false,
      },
    ]);
  };

  // Checked the API need to change like another API Call
  const saveForm = async () => {
    if (formbasicDetail.title && formbasicDetail.description) {
      setLoading(true);
      const combinedData = {
        ...formbasicDetail,
        questions: fullFormDetail,
      };
      const { code } = await CALL_API(`appraisalform`, "post", combinedData);

      if (code === "SUCCESS") {
        setSavedForm(true);
        history.push("/appraisal");
        message.success("Form created successfully");
      }
      setLoading(false);
    } else {
      message.error("Empty Title and Description can't be saved");
    }
  };

  const updateForm = async (id) => {
    if (formbasicDetail.title !== 0 && formbasicDetail.description !== 0) {
      setSavedForm(true);
      const combinedData = {
        ...formbasicDetail,
        questions: fullFormDetail,
      };
      const { code } = await CALL_API(
        `appraisalform/${id}`,
        "patch",
        combinedData
      );

      if (code === "SUCCESS") {
        fetchData();
        setPageView(false);
        message.success("Appraisal Updated successfully");
      }
    } else {
      message.error("Empty Question can't be saved");
    }
  };

  const onSubmit = () => {
    console.log("isFormBuilder --->", isFormBuilder);
    if (isFormBuilder) {
      const combinedData = {
        ...formbasicDetail,
        questions: fullFormDetail,
      };
      return addEditFormBuilder(combinedData, selectedItem?.formBuilderId);
    } else if (pageView === "edit") {
      updateForm(selectedItem.appraisalFormId);
    } else {
      saveForm();
    }
  };

  //shuffle the option
  const shuffleOptions = async (i) => {
    var fullOption = [...fullFormDetail];
    await fullOption[i].options.sort(() => 0.5 - Math.random());
    setFullFormDetail(fullOption);
    handleAdditionalFormClose();
  };

  return (
    <React.Fragment>
      {fullFormDetail?.map((form, i) => {
        return (
          <>
            <div className="mt-3 bg_light rounded-2">
              <Box className="pt-3">
                <div className="px-4">
                  <Grid container>
                    <Grid item md={8} sm={11} xs={11}>
                      <Box sx={{ pb: 2 }}>
                        <CustomTextField
                          className="mt-3 fw-bold"
                          id="standard-basic1"
                          variant="standard"
                          value={form?.question || ""}
                          placeholder={form?.question_placeholder}
                          onChange={(e) =>
                            handleQuestionValue(e.target.value, i)
                          }
                          fullWidth
                        />
                      </Box>
                    </Grid>
                    <Grid item md={1} sm={1} xs={1}>
                      {form?.requiredSwitch && (
                        <Box className="text-danger fw-bold">*</Box>
                      )}
                    </Grid>
                    <Grid item md={3} sm={12} xs={12} ref={containerRef}>
                      <Select
                        id={`simple-select-filled${form.id}`}
                        value={form.formType || "MULIPLE_CHOICE"}
                        onChange={(value) => handleSeleteFormChange(value, i)}
                        style={{ width: "100%", borderRadius: "5px" }}
                        getPopupContainer={(trigger) => trigger.parentNode} // Use trigger.parentNode as popup container
                      >
                        <Option value="SHORT_ANS">Short answer</Option>
                        <Option value="PARAGRAPH">Paragraph</Option>
                        <Option value="MULIPLE_CHOICE">Multiple Choice</Option>
                        <Option value="CHECKBOX">Checkboxes</Option>
                        <Option value="DROPDOWN">Dropdown</Option>
                        <Option value="DATE">Date</Option>
                        <Option value="TIME">Time</Option>
                      </Select>
                    </Grid>
                  </Grid>

                  <FormOptions
                    form={form}
                    i={i}
                    handleOptionValue={handleOptionValue}
                    optionRemove={optionRemove}
                  />

                  {/* Add Option are only shown for radio button,checkbox & DropDown */}
                  <Grid container>
                    <Grid item lg={12}>
                      {fullFormDetail[i]?.formType === "MULIPLE_CHOICE" && (
                        <>
                          <Radio disabled key={`radio-disbaled-${i}`} />

                          <MuiButton
                            size="small"
                            onClick={() => {
                              addOption(i);
                            }}
                            style={optionLabelStyle}
                          >
                            Add Option
                          </MuiButton>
                        </>
                      )}

                      {fullFormDetail[i]?.formType === "CHECKBOX" && (
                        <>
                          <Checkbox disabled key={`check-disbaled-${i}`} />

                          <MuiButton
                            size="small"
                            onClick={() => {
                              addOption(i);
                            }}
                            style={optionLabelStyle}
                          >
                            Add Option
                          </MuiButton>
                        </>
                      )}

                      {fullFormDetail[i]?.formType === "DROPDOWN" && (
                        <>
                          <MuiButton
                            size="small"
                            onClick={() => {
                              addOption(i);
                            }}
                            style={optionLabelStyle}
                          >
                            Add Option
                          </MuiButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: "5px 15px",
                    borderTop: 1,
                    borderColor: "divider",
                    backgroundColor: "#F8F8F8",
                  }}
                >
                  <Tooltip title="Duplicate">
                    <IconButton onClick={() => createDuplicate(i)}>
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Box
                    sx={{
                      borderRight: 1,
                      borderColor: "rgba(0, 0, 0, 0.12)",
                      pl: "10px",
                    }}
                  >
                    <Tooltip title="Delete">
                      <CustomIconButton
                        onClick={() => deleteQuestion(i)}
                        className="mx-2"
                      >
                        <DeleteOutlinedIcon />
                      </CustomIconButton>
                    </Tooltip>
                  </Box>
                  <FormGroup aria-label="position" row>
                    {/* RequiredSwitch shows user as mandatory Question */}
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          style={{ color: "#2D53DA" }}
                          checked={form.requiredSwitch || false}
                          onChange={(event) => handleRequiredChange(event, i)}
                        />
                      }
                      label="Required"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Box>
              </Box>
            </div>
          </>
        );
      })}
      <Box className="mt-3 pb-4">
        <div className="text-center">
          <Button
            className="btn-style rounded-2"
            type="primary"
            onClick={addExtraForm}
          >
            <AddIcon className=" pb-1" />
          </Button>
        </div>
        <div className="text-end mt-3">
          {access && (
            <Button
              className="btn-style"
              type="primary"
              onClick={onSubmit}
              loading={loading}
            >
              {pageView === "edit" ? "Update" : "Create"}
            </Button>
          )}
        </div>
      </Box>
    </React.Fragment>
  );
}

export default EachFormSection;
