import COLORS from "common/Colors";
import Styled from "styled-components";

const Wrapper = Styled.div`
    h6{
        cursor: pointer;
    }
   .template{
    /* background: #f7fffd; */
    padding: 20px;
    h6{
      font-weight: bold;
      font-size: 18px;
    }
     span{
        cursor: pointer;
        font-weight: 500;
        margin-right: 20px;
        color: ${COLORS.PRIMARY}
     }
     .card-container{
      padding: 20px;
      display: flex;
      gap:20px;
      flex-wrap: wrap;
      .cards{
        border: 1px solid #f1f2f4;
        border-radius: 10px;
        max-width: 210px;
        img{
            width: 100%;
        }
      }
   }
}
    @media (max-width: 425px) {
    .template {
      padding: 0 !important;
      margin-left: 5px !important;
      margin-top: 15px !important;
    }
   .create-form-container {
      text-align: left !important;
    }
    .card-container {
      width:100% !important;
       margin-left: -15px !important;
       .cards{
         max-width: 210px !important;
        //  img{
        //     width: 150px !important;
        // }
      }
    }
  }
   @media (max-width: 320px) {
    .card-container {
       .cards{
         max-width: 210px !important;
         img{
            width: 150px !important;
        }
      }
    }
  }
  


`;

export const CardImage = Styled.div`
  background: ${(props) => props.backgroundColor || "#F0EBF8"};
  border-radius: 10px 10px 0px 0px;
  max-width: 210px;

  img {
    width: 100%;
  }
`;

export default Wrapper;
