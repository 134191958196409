import React, { useEffect, useState, useRef } from "react";
import EmployeeCard from "molecules/EmployeeCard";
import {
  Row,
  Col,
  Select,
  Button,
  Spin,
  Modal,
  Tabs,
  message,
  Empty,
  Dropdown,
  Pagination,
  Form,
} from "antd";
import { DEVICE } from "common/Device";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reduce, uniqBy } from "lodash";
import { CSVLink } from "react-csv";
import {
  getUserList,
  getUserRoleId,
  getAllDepartmentList,
  getDepartmentUserList,
  getSelectedDepartmentId,
  getClientid,
  getMenuList,
  getFilters,
  getLocations,
} from "redux/selectors";
import { setUserList, setFilter } from "redux/actions";
import styled from "@emotion/styled";
import { CALL_API } from "common/API";
import { map, concat } from "lodash";
import Icon from "atoms/Icon";
import AddEmployee from "organisms/AddEmployee";
import EditEmployee from "organisms/EditEmployee";
import Confirm from "../../src/assets/Confirm.svg";
import { countryList, STATUS_CODE } from "common/Constants";
import { bindActionCreators } from "redux";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import { getMySqlDate } from "../utils/Date";
import moment from "moment";
import { ReactComponent as IconLink } from "assets/Component 275 – 1.svg";
import { DropdownCheckBox } from "atoms/UIDropdown/Dropdown";

const StyledCol = styled(Col)`
  padding: 10px;
  .ant-card {
    border-radius: 10px !important;
  }
`;
const StyledWrap = styled.div`
  .ant-table-tbody > tr > td {
    font-weight: 400 !important;
  }
`;
const StyledTitle = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
  .ant-row {
    display: flex;
    flex-direction: column;
  }
  .ant-tabs-ink-bar {
    background-color: #2d53da;
  }
  #rc-tabs-1-tab-1:hover,
  #rc-tabs-1-tab-2:hover {
    color: #2d53da;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2d53da !important;
  }
  .ant-tabs-tab {
    color: #b2beb5;
  }
`;

const HeaderRow = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;

  .copy-icon {
    cursor: pointer;
  }
`;

const StyledFilterRow = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  .custom-prefix-icon {
    color: #636980;
  }
  .people_font_style {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20.83px;
  }
  .ant-select-selector {
    box-shadow: none !important;
    border: none !important;
  }
  .custom-select-container {
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }
  .custom-select .ant-select-selector {
    border: none !important;
    flex: 1;
    border-radius: 10px;
    padding-right: 50px;
  }
  .ant-select-selection-placeholder {
    color: #636980 !important;
  }
  .custom-dropdown-icon {
    color: #636980;
    width: 10px;
  }
  .count_cls {
    display: flex;
  }
  .selectbox {
    width: 100%;
    margin-left: 10px;
  }
  .select_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 12px;
  }
  .filter_row {
    display: flex;
    justify-content: space-between;

    align-items: flex-start;
    gap: 8px;
    margin-left: 150px;
  }
  .row_cls_style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter_col {
    display: flex;
    gap: 15px;
    flex-direction: row;
    margin-right: 10px;
  }
  @media ${DEVICE.tablet} {
    .selectbox {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`;
const StyleSubmitButton = styled(ButtonOutlined)`
  @media (max-width: 320px) {
    margin: 0 !important;
  }
`;
const StyledContainerRow = styled(Row)`
  padding-top: 20px;
  .loader_container {
    width: 100%;
    text-align: center;
  }
  .noEmployee {
    width: 100%;
    text-align: center;
    color: grey;
    margin-top: 10%;
  }
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 35px 0px 20px 0px;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    padding: 0px 0px 35px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-body {
    padding: 24px 0px 30px 0px;
  }
  .modal-body {
    justify-content: center;
    margin-top: 10px;
  }
  .ant-modal-close-x {
    color: #151515;
  }
  .modal-body-content {
    justify-content: center;
    font-size: 16px;
    color: #151515;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Employee({
  ClientId,
  updateUserList,
  menuList,
  userRoleId,
  type,
  userList,
  allDepartmentList,
  locationList,
}) {
  const [displayAddEmployeeModal, toggleAddEmployeeModal] = useState(false);
  const [displayEditEmployeeModal, toggleEditEmployeeModal] = useState(false);
  const [EmployeeDetails, setEmployeeDetails] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [employeeFilterType, setEmployeeFilterType] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const departmentFilter = "";
  const [displayEmployeeLoader, setdisplayEmployeeLoader] = useState(false);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [deactivateEmployee, toggledeactivateEmployee] = useState(false);
  const [confirmdeactivateEmployee, toggleconfirmdeactivateEmployee] =
    useState(false);
  const [empId, setEmpId] = useState(0);
  const [employeeCSVlist, setEmployeeCSVlist] = useState([]);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [employeeFilterList, setEmployeeFilterList] = useState([]);
  const [employeeType, setEmployeeType] = useState("1");

  const csvLinkEl = useRef();
  const containerRef = useRef(null);
  const [form] = Form.useForm();

  const getFilterList = () => {
    const country = form.getFieldValue("country") || [];
    const name = form.getFieldValue("name") || [];
    const department = form.getFieldValue("department") || [];
    const location = form.getFieldValue("location") || [];
    if (
      country?.length > 0 ||
      name?.length > 0 ||
      department?.length > 0 ||
      location?.length > 0
    ) {
      return employeeFilterList?.filter((item) => {
        const nameMatch =
          name && name?.length > 0 ? name.some((n) => item.userid === n) : true;

        const countryMatch =
          country && country?.length > 0
            ? country.includes(item?.nationality)
            : true;

        const departmentMatch =
          department && department?.length > 0
            ? department.includes(item?.basedepartmentid)
            : true;

        const locationMatch =
          location && location?.length > 0
            ? location.includes(item?.baselocationid)
            : true;

        return nameMatch && countryMatch && departmentMatch && locationMatch;
      });
    } else {
      return employeeFilterList;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex =
    ((getFilterList()?.length > 50 ? currentPage : 1) - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const getDisplayList = () => {
    return getFilterList().slice(startIndex, endIndex);
  };

  const STAFF_LIST =
    employeeFilterList?.map((val) => {
      return {
        user_id: val?.userid,
        name: val?.fullname,
        type: val?.usertype,
        department: val?.userdepartmentid,
      };
    }) || [];

  const menuName = "employee";
  const filters = useSelector(getFilters);
  const initialFilter = filters[menuName];

  const dispatch = useDispatch();

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    dispatch(setFilter(menuName, "name", values.name || []));
    dispatch(
      setFilter(menuName, "managingoffice", values.managingoffice || [])
    );
    dispatch(setFilter(menuName, "status", values.status || []));
    dispatch(setFilter(menuName, "owner", values.owner || []));
    dispatch(
      setFilter(
        menuName,
        "relationship_manager",
        values.relationship_manager || []
      )
    );
  };

  const items1 = [
    {
      key: 1,
      label: "Employee",
      component: <></>,
    },
    {
      key: 2,
      label: "Ex Employee",
      component: <></>,
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <Link
          onClick={() => toggleAddEmployeeModal(true)}
          className="fw-semibold"
        >
          New Employee Form
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="pending-employee" className="fw-semibold">
          Pending Employee
        </Link>
      ),
    },
  ];

  const CSVHeaders = [
    { label: "User Id", key: "User Id" },
    { label: "Name", key: "Name" },
    { label: "Start Date", key: "Start Date" },
    { label: "Date of Birth", key: "Date of Birth" },
    { label: "Overtime Status", key: "Overtime Status" },
    { label: "Gender", key: "Gender" },
    { label: "Age", key: "Age" },
    { label: "Marital Status", key: "Marital Status" },
    { label: "Nationality", key: "Nationality" },
    { label: "UAN", key: "UAN" },
    { label: "PAN", key: "PAN" },
    { label: "Address", key: "Address" },
    { label: "Phone No", key: "Phone No" },
    { label: "Institute Name", key: "Institute Name" },
    { label: "Degree", key: "Degree" },
    { label: "Specialization", key: "Specialization" },
    { label: "Year of Completion", key: "Year of Completion" },
    { label: "Company Name", key: "Company Name" },
    { label: "Job Title", key: "Job Title" },
    { label: "Professional Since", key: "Professional Since" },
    { label: "Hired Date", key: "Hired Date" },
    { label: "Relevant", key: "Relevant" },
    { label: "Employee Sponser", key: "Employee Sponser" },
    { label: "Job Description", key: "Job Description" },
    { label: "Emergency Contact Name", key: "Emergency Contact Name" },
    { label: "Emergency Contact Number", key: "Emergency Contact Number" },
    { label: "Terminated Date", key: "Terminated Date" },
  ];

  const prepareCSV = async () => {
    togglebtnLoader(true);
    if (employeeFilterList?.length > 0) {
      setEmployeeCSVlist(
        reduce(
          getFilterList(),
          function (result, list) {
            result.push({
              "User Id": list?.userid || "",
              Name: list?.fullname || "",
              "Start Date":
                list?.startdate && moment(list.startdate).isValid()
                  ? moment(list?.startdate).format("DD/MM/YYYY")
                  : "-",
              "Date of Birth":
                list?.dateofbirth && moment(list.dateofbirth).isValid()
                  ? moment(list?.dateofbirth).format("DD/MM/YYYY")
                  : "-",
              "Overtime Status": list?.overtimestatus || "",
              Gender: list?.gender || "",
              Age: list?.age || "",
              "Marital Status": list?.maritalstatus || "",
              Nationality: list?.nationality || "",
              UAN: list?.uan || "",
              PAN: list?.pan || "",
              Address: list?.presentaddress || "",
              "Phone No": list?.phoneno || "",
              "Institute Name": list?.institutename || "",
              Degree: list?.degree || "",
              Specialization: list?.specialization || "",
              "Year of Completion":
                list?.yearofcompletion &&
                moment(list.yearofcompletion).isValid()
                  ? moment(list?.yearofcompletion).format("DD/MM/YYYY")
                  : "-",
              "Company Name": list?.companyname || "",
              "Job Title": list?.jobtitle || "",
              "Professional Since":
                list?.professionalsince &&
                moment(list.professionalsince).isValid()
                  ? moment(list.professionalsince).format("DD/MM/YYYY")
                  : "-",
              "Hired Date":
                list?.hireddate && moment(list.hireddate).isValid()
                  ? moment(list.hireddate).format("DD/MM/YYYY")
                  : "-",
              Relevant: list?.relevant || "",
              "Employee Sponser": list?.employeesponser || "",
              "Job Description": list?.jobdescription || "",
              "Emergency Contact Name": list?.emergencycontactname || "",
              "Emergency Contact Number": list?.emergencycontactnumber || "",
            });
            if (list?.statusid === "0") {
              result.push({
                "Terminated Date": list?.terminateddate
                  ? moment(list?.terminateddate).format("DD/MM/YYYY")
                  : "",
              });
            }
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
    togglebtnLoader(false);
  };
  useEffect(() => {
    var result;
    if (employeeType) {
      result = EmployeeList?.filter((data) => data?.statusid == employeeType);
    }
    setEmployeeFilterList(result);
  }, [selectedDepartment, employeeType, EmployeeList]);

  useEffect(() => {
    getEmployeeList();
  }, [setEmployeeList]);

  const getEmployeeList = async () => {
    try {
      setdisplayEmployeeLoader(true);
      const { response } = await CALL_API(`add-users/${ClientId}`, "get", {});
      const sortedResponse = response.sort((a, b) => {
        return a.fullname.localeCompare(b.fullname);
      });
      setEmployeeList(sortedResponse);
      setdisplayEmployeeLoader(false);
    } catch (error) {
      console.log("[getEmployeeList] Error-->", error);
    }
  };
  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        updateUserList(userList);
      }
    }
  };

  const ViewEmployee = (EmployeeObject, isViewOnly = false) => {
    toggleEditEmployeeModal(true);
    setEmployeeDetails({
      ...EmployeeObject,
      isViewOnly,
    });
  };
  const deletedEmployee = (EmpId) => {
    toggledeactivateEmployee(true);
    setEmpId(EmpId);
  };
  const deleteEmployee = async (employee_id) => {
    togglebtnLoader(true);
    await CALL_API(`add-users/${ClientId}`, "delete", {
      user_id: employee_id,
      status_id: "0",
      status_name: "inactive",
    });
    togglebtnLoader(false);
    toggledeactivateEmployee(false);
    toggleconfirmdeactivateEmployee(true);
    fetchMasterData();
    getEmployeeList();
  };
  const handleTabSelect = (key) => {
    if (key === 2) {
      setEmployeeType("0");
      setEmployeeNameFilter("");
    } else {
      setEmployeeType("1");
      setEmployeeNameFilter("");
    }
  };

  const Menulist =
    menuList[userRoleId] || Object?.values(menuList)[0] || menuList;

  const filtermenulist =
    Menulist &&
    Menulist?.filter(
      (data) =>
        data.key !== "managerdashboard" &&
        data.key !== "hrdashboard" &&
        data.key !== "teamcalendar" &&
        data.key !== "allcalendar"
    );

  const linkToCopy = `https://main.dn8ugqr16xmnv.amplifyapp.com/?clientid=${ClientId}`;
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch(() => {
        message.error("Failed to copy link");
      });
  };

  const defaultPath =
    filtermenulist?.length > 0 ? filtermenulist[0]?.path : "/";

  const headerstyle = { fontSize: "20px", fontWeight: "bold" };

  const uniqueLocationName = uniqBy(
    locationList
      ?.filter((val) => val?.login_from_anywhere !== 1)
      .map((item) => {
        return {
          label: item?.location_name,
          value: item?.location_id,
        };
      }),
    "value"
  );

  const uniqueDepartmentName = uniqBy(
    allDepartmentList?.map((item) => {
      return {
        label: item?.department_name,
        value: item?.department_id,
      };
    }),
    "value"
  );

  const uniqueCountryName = uniqBy(
    countryList?.map((item) => {
      return {
        label: item,
        value: item,
      };
    }),
    "value"
  );

  const uniqueName = uniqBy(
    employeeFilterList?.map((item) => {
      return {
        label: item?.fullname,
        value: item?.userid,
      };
    }),
    "value"
  );
  return (
    <>
      {!displayAddEmployeeModal && !displayEditEmployeeModal && (
        <>
          <Row className="page-title">
            <Col sm={24} md={12} style={headerstyle}>
              List of Employee
            </Col>
            <Col sm={24} md={12} className="align-right">
              {Menulist?.find(
                (item) =>
                  item?.key === "manage" &&
                  item?.subMenu?.find(
                    (subItem) =>
                      subItem?.key === "employee" && subItem?.permission.edit
                  )
              ) && (
                <ButtonFilled onClick={() => toggleAddEmployeeModal(true)}>
                  <span>+</span>
                  <span className="add-title">Add People</span>
                </ButtonFilled>
              )}
            </Col>
          </Row>

          <StyledTitle className="page-title-head">
            <Row>
              <Tabs
                defaultActiveKey="1"
                items={items1}
                onChange={handleTabSelect}
                indicator={{
                  size: (origin) => origin - 20,
                  align: "center",
                }}
              />
            </Row>
          </StyledTitle>
          {/* <StyledFilterRow className="filter_row" gutter={16}>
            <Col className="" lg={6} md={12} sm={24} xs={24}>
              <div className="people_font_style mb-2">
                Total People: {getFilterList()?.length}
              </div>
            </Col>
            <Col className="select_div" lg={18} md={12} sm={24} xs={24}>
              <div className="custom-select-container">
                <Icon name="search" className=" text-center" />
                <Select
                  className="custom-select"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input?.toLowerCase())
                  }
                  value={employeeNameFilter}
                  placeholder="Search People"
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                  onChange={(value) => setEmployeeNameFilter(value)}
                />
              </div>
              <div className="custom-select-container">
                <Icon name="filter" className="text-center" />
                <Select
                  className="custom-select"
                  placeholder="Type"
                  options={[
                    { label: "All Type", value: "All type" },
                    { label: "Full time", value: "Full time" },
                    { label: "Part time", value: "Part time" },
                    { label: "Contract", value: "Contract" },
                  ]}
                  onChange={(selectedType) => {
                    setEmployeeFilterType(selectedType);
                  }}
                />
              </div>
            </Col>
          </StyledFilterRow> */}
          <>
            <Row>
              <Col sm={24}>
                <StyledWrap>
                  <Form
                    form={form}
                    layout="vertical"
                    className="mt-2"
                    onValuesChange={handleFormChange}
                    initialValues={{ ...initialFilter }}
                  >
                    <Row gutter={12} className="g-3 mb-2">
                      {/* <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="country"
                    label="Country"
                    options={uniqueCountryName}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col> */}
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={6}
                        ref={containerRef}
                      >
                        <DropdownCheckBox
                          name="name"
                          label="People"
                          options={uniqueName}
                          form={form}
                          handleFormChange={handleFormChange}
                        />
                      </Col>

                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        xl={6}
                        ref={containerRef}
                      >
                        <DropdownCheckBox
                          name="department"
                          label="Department"
                          options={uniqueDepartmentName}
                          form={form}
                          handleFormChange={handleFormChange}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <DropdownCheckBox
                          name="location"
                          label="Location"
                          options={uniqueLocationName}
                          form={form}
                          handleFormChange={handleFormChange}
                        />
                      </Col>
                      {/* <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                  <DropdownCheckBox
                    name="relationship_manager"
                    label="Relationship Manager"
                    // options={uniquerelationshipmanagervalue}
                    form={form}
                    handleFormChange={handleFormChange}
                  />
                </Col> */}
                    </Row>
                  </Form>
                </StyledWrap>
              </Col>
            </Row>
            <HeaderRow>
              <div className="people_font_style">
                Total People:
                {getFilterList()?.length}
              </div>

              <CSVLink
                header={CSVHeaders}
                data={employeeCSVlist}
                filename={"Employee-list.csv"}
                asyncOnClick={true}
                ref={csvLinkEl}
              />
              <StyleSubmitButton className="m-0 ms-auto" onClick={prepareCSV}>
                <span className="add-title">Export CSV File</span>
              </StyleSubmitButton>

              {Menulist?.find(
                (item) => item?.key === "employee" && item?.permission.create
              ) && (
                <>
                  <IconLink className="copy-icon" onClick={handleCopyClick} />
                  <Dropdown
                    menu={{ items }}
                    placement="bottom"
                    trigger={["hover"]}
                  >
                    <ButtonFilled className="apply-leave-sm m-0">
                      Add People{" "}
                    </ButtonFilled>
                  </Dropdown>
                  {/* <ButtonFilled onClick={() => toggleAddEmployeeModal(true)}>
                    <span>+</span>
                    <span clas sName="add-title">Add People</span>
                  </ButtonFilled> */}
                </>
              )}
            </HeaderRow>
            <StyledContainerRow gutter={16}>
              {displayEmployeeLoader ? (
                <div className="loader_container">
                  <Spin spinning={displayEmployeeLoader} />
                </div>
              ) : (
                <>
                  {employeeFilterList?.length > 0 ? (
                    <>
                      {map(getDisplayList(), (data, index) => (
                        <>
                          <StyledCol
                            key={index}
                            xs={24}
                            sm={12}
                            md={12}
                            lg={8}
                            xl={6}
                          >
                            <Link
                              to={`/profile-details/${data.userid}/?type=manage`}
                            >
                              <EmployeeCard
                                id={data.userid}
                                name={data.fullname}
                                Department={data.department_name}
                                clientid={data.clientid}
                                employeeid={data.employeeid}
                                data={data}
                                getEmployeeList={fetchMasterData}
                                ViewEmployee={ViewEmployee}
                                deletedEmployee={deletedEmployee}
                                profile={data.profileurl}
                                employeeType={employeeType}
                                rolename={data.rolename}
                              />
                            </Link>
                          </StyledCol>
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <p className="noEmployee">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </p>
                    </>
                  )}
                </>
              )}
            </StyledContainerRow>
            {/* Pagination component */}
            {getFilterList()?.length > pageSize && (
              <Pagination
                current={getFilterList()?.length > 50 ? currentPage : 1}
                total={getFilterList()?.length}
                pageSize={pageSize}
                onChange={handlePageChange}
                style={{ marginTop: "20px", textAlign: "center" }}
              />
            )}
          </>
          {/* <StyledContainerRow gutter={16}>
            {displayEmployeeLoader ? (
              <div className="loader_container">
                <Spin spinning={displayEmployeeLoader} />
              </div>
            ) : (
              <>
                {employeeFilterList?.length > 0 ? (
                  <>
                    {map(getFilterList(), (data, index) => (
                      <>
                        <StyledCol
                          key={index}
                          xs={24}
                          sm={12}
                          md={12}
                          lg={8}
                          xl={6}
                        >
                          <Link to={`/profile-details/${data.userid}/?type=${type}`}>
                            <EmployeeCard
                              id={data.userid}
                              name={data.fullname}
                              Department={data.department_name}
                              clientid={data.clientid}
                              employeeid={data.employeeid}
                              data={data}
                              getEmployeeList={getEmployeeList}
                              ViewEmployee={ViewEmployee}
                              deletedEmployee={deletedEmployee}
                              profile={data.profileurl}
                              employeeType={employeeType}
                              rolename={data.rolename}
                            />
                          </Link>
                        </StyledCol>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <p className="noEmployee">
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </p>
                  </>
                )}
              </>
            )}
          </StyledContainerRow> */}
        </>
      )}
      {displayAddEmployeeModal && (
        <AddEmployee
          isModalVisible={displayAddEmployeeModal}
          closeModal={() => toggleAddEmployeeModal(false)}
          getEmployeeList={getEmployeeList}
          setSelectedDepartment={setSelectedDepartment}
          EmployeeList={EmployeeList}
        />
      )}
      {displayEditEmployeeModal && (
        <EditEmployee
          isModalVisible={displayEditEmployeeModal}
          closeModal={() => toggleEditEmployeeModal(false)}
          getEmployeeList={getEmployeeList}
          EmployeeDetails={EmployeeDetails}
          EmployeeList={EmployeeList}
        />
      )}
      <StyleddeactivateModal
        title="Are you sure you want to terminate this Employee?"
        visible={deactivateEmployee}
        width={500}
        onCancel={() => toggledeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="page-title-head" gutter={16}>
          <Col>
            <StyledButton
              type="primary"
              htmlType="submit"
              onClick={() => toggledeactivateEmployee(false)}
            >
              No
            </StyledButton>
          </Col>
          <Col>
            <StyledButton
              loading={btnLoader}
              type="primary"
              htmlType="submit"
              onClick={() => deleteEmployee(empId)}
            >
              {btnLoader ? "Yes" : "Yes"}
            </StyledButton>
          </Col>
        </Row>
      </StyleddeactivateModal>
      <StyledModal
        visible={confirmdeactivateEmployee}
        width={500}
        onCancel={() => toggleconfirmdeactivateEmployee(false)}
        footer={[]}
        centered
      >
        <Row className="modal-body">
          <Col>
            <img src={Confirm} alt="confirm" height={45} width={45} />
          </Col>
        </Row>
        <Row className="modal-body-content">
          <Col>
            <p>This Employee has been terminated</p>
          </Col>
        </Row>
      </StyledModal>
    </>
  );
}
const mapStateToProps = (state) => ({
  userList: getUserList(state),
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  departmentUserList: getDepartmentUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
  locationList: getLocations(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Employee);
