import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Spin, message } from "antd";

// Redux

import { connect } from "react-redux";
import { getUserRoleId, getClientid, getMenuList } from "redux/selectors";
import styled from "styled-components";
import Dropzone from "molecules/Dropzone";
import COLORS from "common/Colors";
import { get, map, includes } from "lodash";
import Document from "molecules/Document";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { DEVICE } from "common/Device";

const StyledDocumentPageTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 0px;
`;

const StyleDocumentRow = styled(Row)`
  padding-top: 20px;
  @media (max-width: 992px) {
    gap: 16px; /* Add gap for tablet sizes */
  }
`;

const StyledDocumentDiv = styled.div`
  border: solid 1px ${COLORS.GREY};
  border-radius: 5px;
  padding: 10px;
  height: 100%;
  .nodata {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      letter-spacing: 2px;
      font-weight: bold;
      color: ${COLORS.GREY};
    }
  }
  ${({ height }) =>
    height &&
    `height: ${height}px; min-height: ${height}px; max-height: ${height}px; overflow: auto;`};
  @media ${DEVICE.tablet} {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`;

const StyledDocumentContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

function MyDocuments({ userRoleId, ClientId, menuList }) {
  const [documentList, setDocumentList] = useState([]);
  const [height, setHeight] = useState(200);
  const [fileName, setFileName] = useState("");
  const [isUploading, toggleUploading] = useState(false);
  const [isLoadingDocuments, toggleLoadingDocuments] = useState(false);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = async () => {
    toggleLoadingDocuments(true);
    const { list } = await CALL_API(`document-list/${ClientId}`, "post", {
      method: "document-list",
    });
    setDocumentList(list);
    toggleLoadingDocuments(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = async function (e) {
        toggleUploading(true);
        if (
          includes(
            [
              "jpg",
              "png",
              "x-icon",
              "jpeg",
              "svg",
              "svg+xml",
              "xls",
              "xlsx",
              "pdf",
              "csv",
              "doc",
              "docx",
              "ms-doc",
              "txt",
              "ppt",
              "avi",
              "zip",
              "mov",
              "plain",
              "vnd.ms-excel",
              "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "pdf",
              "csv",
              "msword",
              "vnd.openxmlformats-officedocument.wordprocessingml.document",
              "vnd.ms-powerpoint",
              "vnd.openxmlformats-officedocument.presentationml.presentation",
            ],
            get(file, "type", "").split("/")[1]
          )
        ) {
          const { code } = await CALL_API(
            `upload-document/${ClientId}`,
            "post",
            {
              name: get(file, "name", ""),
              type: get(file, "type", ""),
              content: e.target.result,
              permissionType: "non-confidential",
            }
          );
          if (code === STATUS_CODE.SUCCESS) {
            message.success("Document uploaded");
            toggleUploading(false);
            getDocumentList();
          } else {
            message.error("Something went wrong!! Please try again.");
          }
        } else message.error("Invalid file format");
        toggleUploading(false);
      };
      console.log("file----->", file);
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const deleteDocument = async (id) => {
    const response = await CALL_API(
      `upload-document/${ClientId}/${id}`,
      "delete",
      {
        // documentId : id, //Commented Code so that it's used in future
        status_id: 0,
      }
    );
    message.success("Document has been Deleted");
    getDocumentList();
  };

  useEffect(() => {
    setHeight(
      window.innerHeight -
        document.querySelector(".ant-layout-header").getBoundingClientRect()
          .height -
        100
    );

    window.uploadDocumentFromApp = async (base64, name, MIMEtype) => {
      toggleUploading(true);
      const { code } = await CALL_API(`upload-document/${ClientId}`, "post", {
        name,
        type: MIMEtype,
        content: base64,
        permissionType: "non-confidential",
      });
      if (code === STATUS_CODE.SUCCESS) {
        message.success("Document uploaded");
        toggleUploading(false);
        getDocumentList();
      } else {
        message.error("Something went wrong!! Please try again.");
      }
    };
  }, []);

  const uploadDocument = () => {
    if (navigator.userAgent === "application") {
      window.Print.postMessage("upload-document");
    }
  };

  return (
    <>
      <StyledDocumentPageTitle>My Documents</StyledDocumentPageTitle>
      <StyleDocumentRow gutter={20}>
        <Col md={24} sm={24} xs={24} lg={12} onClick={uploadDocument}>
          <Spin spinning={isUploading}>
            <Dropzone
              onDrop={onDrop}
              accept={
                ("image/*,.png,.jpeg",
                ".jpg,.svg,.xls,.xlsx,.pdf,.csv,.doc,.docx,.txt,.ppt,.avi,.zip,.mov")
              }
              fileName={fileName}
              access={Menulist?.find(
                (item) => item?.key === "mydocuments" && item?.permission.create
              )}
            />
          </Spin>
        </Col>

        <Col md={24} sm={24} xs={24} lg={12}>
          <Spin spinning={isLoadingDocuments}>
            <StyledDocumentDiv height={height}>
              {documentList?.length > 0 ? (
                <Row gutter={24}>
                  {map(
                    documentList,
                    ({ documentId, documentName, createdon }, index) => (
                      <Col md={8} lg={8} sm={12} xs={24} key={index}>
                        <StyledDocumentContainer>
                          <Document
                            id={documentId}
                            name={documentName}
                            staffName=""
                            uploadTime={createdon}
                            deleteDocument={deleteDocument}
                          />
                        </StyledDocumentContainer>
                      </Col>
                    )
                  )}
                </Row>
              ) : (
                <div className="nodata">
                  <p>No Data</p>
                </div>
              )}
            </StyledDocumentDiv>
          </Spin>
        </Col>
      </StyleDocumentRow>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  menuList: getMenuList(state),
});

export default connect(mapStateToProps, null)(MyDocuments);
