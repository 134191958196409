import styled from "@emotion/styled";
import { SearchOutlined } from "@mui/icons-material";
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Typography,
  message,
} from "antd";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { EXPENSE_STATUS, STATUS_CODE } from "common/Constants";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Filter from "../../../../assets/Filter.svg";
import ViewBusinessTrip from "../../MyTravelExpense/BusinessTrip/ViewBusinessTrip";
import { CheckCircleFilled } from "@ant-design/icons";

const { TextArea } = Input;

const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-cell:nth-last-child(2) {
    // min-width: 200px;
    white-space: normal;
  }
  .non-white {
    background: #f9f9f9;
  }

  .ant-modal-header {
    border-bottom: none !important;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  max-width: 300px; /* Default width */
  height: 32px;
  margin-right: 1rem;
  margin-top: 1.5px;

  @media (max-width: 768px) {
    width: 200px !important; /* Adjust for tablets */
  }
`;
const StyledSelect = styled(Select)`
  @media (max-width: 768px) {
    width: 200px !important; /* Adjust for tablets */
    margin-left: 110px;
  }
  @media (max-width: 571px) {
    margin-left: 0 !important;
  }
`;

const HrTravelExpense = ({
  ClientId,
  userInfo,
  userList,
  updateSuccessModal,
  Menulist,
}) => {
  const columns = [
    {
      title: "Travel ID",
      dataIndex: "travelId",
      key: "travelId",
    },
    {
      title: "Name",
      dataIndex: "userId",
      key: "userId",
      width: "10%",
      render: (userId) => {
        const user = userList.find((user) => user.userid === userId);
        return user ? user.fullname : userId;
      },
    },
    {
      title: "Employee ID",
      dataIndex: "userId",
      key: "userId",
      render: (userId) => {
        const user = userList.find((user) => user.userid === userId);
        return user ? user.employeeid : userId;
      },
    },

    // {
    //   title: "Department",
    //   dataIndex: "departure",
    //   key: "departure",
    //   render: (date) => {
    //     const dateFormat = moment(date).format("DD MMM YYYY");
    //     return `${dateFormat}`;
    //   },
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
      width: "5%",
    },

    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
  ];

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = {
    color: `${COLORS.TEXT.PRIMARY}`,
    fontSize: "16px",
  };
  const containerRef = useRef(null);

  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [approveData, setApproveData] = useState(false);
  const [view, setView] = useState(false);
  const [showLoader, toggleLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [edit, setEdit] = useState(false);
  const [traveldata, setTraveldata] = useState([]);
  const [value, setValue] = useState({
    reason: "",
    days: 0,
    departure: null,
    arrival: null,
    destination: "",
  });

  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  const [filteredData, setFilteredData] = useState(traveldata);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    lastEvaluatedKey: null,
  });
  useEffect(() => {
    // const filtered = traveldata.filter((item) => item.status === "Waiting");
    setFilteredData(traveldata);
  }, [traveldata]);

  const [searchString, setSearchString] = useState("");

  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    setSearchString(searchString);
    if (searchString.length) {
      const filtered = filteredData.filter((item) =>
        String(item?.travelId)
          .toLowerCase()
          .includes(String(searchString.toLowerCase()))
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
  };

  const handleSelectChange = (selectedValue) => {
    if (selectedValue !== "All") {
      const filtered =
        traveldata.filter((item) => item.status === selectedValue) || [];
      setFilteredData(filtered);
    } else {
      setFilteredData(traveldata);
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    toggleLoader(true);
    setTimeout(() => {
      setOpen(false);
      toggleLoader(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
    setValue({
      reason: "",
      days: 0,
      departure: null,
      arrival: null,
      destination: "",
    });
    setSelectedItem([]);
    setEdit(false);
  };

  const onChangeValue = (e) => {
    const { name, value: inputValue } = e.target;
    setValue((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const onChangeEdit = (e) => {
    const { name, value: inputValue } = e.target;
    setSelectedItem((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
  };

  const onChange = (date, dateString, field) => {
    setValue((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  const onChangeEditdate = (date, dateString, field) => {
    setSelectedItem((prevState) => ({
      ...prevState,
      [field]: date,
    }));
  };

  const disabledDateBefore = (current, targetDate) => {
    return current && current < moment(targetDate).startOf("day");
  };

  const disabledDateAfter = (current, targetDate) => {
    return current && current > moment(targetDate).endOf("day");
  };

  const updateDays = (departure, arrival) => {
    if (departure && arrival) {
      const daysDifference = moment(arrival).diff(departure, "days");

      let days;
      if (daysDifference === 0) {
        days = 1;
      } else if (daysDifference === 1) {
        days = 2;
      } else if (daysDifference > 1) {
        days = daysDifference + 1;
      } else {
        days = 0;
      }

      if (edit) {
        setSelectedItem((prevState) => ({
          ...prevState,
          days: days,
        }));
      } else {
        setValue((prevState) => ({
          ...prevState,
          days: days,
        }));
      }
    }
  };

  const handleRowClick = (record) => {
    setSelectedItem(record);
    setView(true);
  };

  const getData = async () => {
    setDisplayTableLoader(true);
    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/businesstrip?LastEvaluatedKey=0&clientId=${ClientId}&reportingmanager=${userInfo?.userid}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata(data);
      setDisplayTableLoader(false);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const getMoreData = async () => {
    setDisplayTableLoader(true);
    const { code, data, LastEvaluatedKey } = await CALL_API(
      `travel-expense/businesstrip?LastEvaluatedKey=${
        pagination.lastEvaluatedKey || 0
      }&clientId=${ClientId}`,
      "get"
    );
    if (code === STATUS_CODE.SUCCESS) {
      setTraveldata([...traveldata, ...data]);
      setDisplayTableLoader(false);
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: LastEvaluatedKey,
      }));
    } else {
      toggleLoader(false);
      message.error("Error");
      setDisplayTableLoader(false);
    }
  };

  const handleSubmitedit = async (status) => {
    if (comment === ("" || null || undefined)) {
      setError("Comment cannot be empty");
      return;
    }
    toggleLoader(true);
    const { code } = await CALL_API(
      `travel-expense/businesstrip?status=${status}`,
      "patch",
      {
        comments: comment,
        id: selectedItem.id,
        clientId: ClientId,
        userId: userInfo.userid,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      toggleLoader(false);
      updateSuccessModal({
        visibility: true,
        message: status + "d",
        Sucessicon:
          status === "Decline" ? (
            <div className="d-flex justify-content-center align-itemw-center">
              <CheckCircleFilled style={{ color: "red" }} />
            </div>
          ) : (
            ""
          ),
      });
      handleCancel();
      setDeleteData(false);
      setComment("");
      setError(false);
      setApproveData(false);
      getData();
      setPagination((prevPagination) => ({
        ...prevPagination,
        lastEvaluatedKey: 0,
      }));
    } else {
      toggleLoader(false);
      message.error("Error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleNextPage = () => {
    if (pagination.lastEvaluatedKey !== null) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: prevPagination.current + 1,
      }));
    }
  };

  useEffect(() => {
    if (pagination.lastEvaluatedKey !== null) {
      getMoreData();
    }
  }, [pagination.current]);

  const sortDataByStatus = (data) => {
    return data.sort((a, b) => {
      if (a.status === "Waiting" && b.status !== "Waiting") {
        return -1;
      }
      if (a.status !== "Waiting" && b.status === "Waiting") {
        return 1;
      }
      return 0;
    });
  };

  return (
    <div>
      <Row className="page-title">
        <Col
          xs={24}
          sm={8}
          md={5}
          className="page-title-head pe-3"
          ref={containerRef}
        >
          <StyledInput
            style={styledInput}
            prefix={<SearchOutlined style={styledIcon} />}
            placeholder="Search "
            onChange={handleSearchChange}
            value={searchString}
            className="me-3"
          />
        </Col>
        <Col
          xs={24}
          sm={8}
          md={5}
          className="page-title-head"
          ref={containerRef}
        >
          <StyledSelect
            placeholder={
              <>
                <img src={Filter} alt="filter" />{" "}
                <span className="Status-placeholder">Status</span>
              </>
            }
            options={EXPENSE_STATUS}
            defaultValue={"All"}
            getPopupContainer={() => containerRef.current}
            className="w-100"
            onChange={handleSelectChange}
          />
        </Col>
      </Row>

      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        ></Col>
      </Row>
      <Spin spinning={displayTableLoader}>
        <div style={{ overflowX: "auto" }}>
          <StyledTable
            dataSource={sortDataByStatus(filteredData)}
            columns={columns}
            rowKey="user_leave_id"
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
          />
          {pagination.lastEvaluatedKey !== null && (
            <div className="d-flex justify-content-center align-items-center my-3">
              <Button
                type="primary"
                onClick={handleNextPage}
                disabled={displayTableLoader}
              >
                Load More
              </Button>
            </div>
          )}
        </div>
      </Spin>

      <Modal
        open={open}
        onOk={handleOk}
        showLoader={showLoader}
        onCancel={handleCancel}
        closable={false}
      >
        <Row>
          <Col xs={24} sm={24} md={24} className="p-2">
            <Typography>Reason for Travel</Typography>
            <TextArea
              value={edit ? selectedItem?.reason : value.reason}
              name="reason"
              onChange={(e) => (edit ? onChangeEdit(e) : onChangeValue(e))}
              autoSize={{ minRows: 3 }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} className="p-2">
            <Typography>Date of Departure</Typography>
            <DatePicker
              name="departure"
              className="w-100"
              value={edit ? moment(selectedItem?.departure) : value.departure}
              onChange={(date, dateString) => {
                if (edit) {
                  onChangeEditdate(date, dateString, "departure");
                  updateDays(date, moment(selectedItem?.arrival));
                } else {
                  onChange(date, dateString, "departure");
                  updateDays(date, value.arrival);
                }
              }}
              disabledDate={(current) =>
                disabledDateAfter(
                  current,
                  edit ? moment(selectedItem?.arrival) : value.arrival
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} className="p-2">
            <Typography>Return date</Typography>
            <DatePicker
              name="arrival"
              className="w-100"
              value={edit ? moment(selectedItem?.arrival) : value.arrival}
              onChange={(date, dateString) => {
                if (edit) {
                  onChangeEditdate(date, dateString, "arrival");
                  updateDays(moment(selectedItem?.departure), date);
                } else {
                  onChange(date, dateString, "arrival");
                  updateDays(value.departure, date);
                }
              }}
              disabledDate={(current) =>
                disabledDateBefore(
                  current,
                  edit ? moment(selectedItem?.departure) : value.departure
                )
              }
            />
          </Col>
          <Col xs={24} sm={24} md={12} className="p-2">
            <Typography>No of Days</Typography>
            <Typography>{edit ? selectedItem?.days : value.days}</Typography>
          </Col>
          <Col xs={24} sm={24} md={12} className="p-2">
            <Typography>Travel Destination</Typography>
            <Input
              onChange={(e) => (edit ? onChangeEdit(e) : onChangeValue(e))}
              name="destination"
              value={edit ? selectedItem?.destination : value.destination}
            />
          </Col>
        </Row>
      </Modal>

      <Modal
        open={approveData}
        footer={[
          <Button key="cancel" onClick={() => setApproveData(false)}>
            No
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleSubmitedit("Approve")}
            disabled={showLoader}
          >
            {showLoader ? <Spin /> : "Yes"}
          </Button>,
        ]}
        closable={false}
      >
        <Typography.Title className="p-2" level={5}>
          Are you sure you want to approve this travel request?
        </Typography.Title>
      </Modal>

      <Modal
        open={deleteData}
        footer={[
          <Button
            key="cancel"
            onClick={() => setDeleteData(false)}
            disabled={showLoader}
          >
            {showLoader ? <Spin /> : "No"}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleSubmitedit("Decline")}
            disabled={showLoader}
          >
            {showLoader ? <Spin /> : "Yes"}
          </Button>,
        ]}
        closable={false}
      >
        <Typography.Title className="p-2" level={5}>
          Are you sure you want to decline this travel request?
        </Typography.Title>
      </Modal>

      <ViewBusinessTrip
        view={view}
        setView={setView}
        selectedItem={selectedItem}
        setEdit={setEdit}
        setApproveData={setApproveData}
        setDeleteData={setDeleteData}
        showModal={showModal}
        hrView={true}
        comment={comment}
        setComment={setComment}
        handleSubmitedit={handleSubmitedit}
        error={error}
        setError={setError}
        Menulist={Menulist}
      />
    </div>
  );
};

export default HrTravelExpense;
