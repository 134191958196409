import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "antd";
import AppsIcon from "@mui/icons-material/Apps";
import styled from "styled-components";
import HR from "assets/TymeplusHR.svg";
import Pay from "assets/TymeplusPay.svg";
import Crm from "assets/CRM.svg";
import LMS from "assets/LMS.svg";
import { jwtDecode } from "jwt-decode";
import { ClickAwayListener, Fab } from "@mui/material";

const Styleddiv = styled.div`
  .active_icon {
    background-color: #e3e8fc;
  }
  .app_icon {
    background-color: transparent;
    box-shadow: none;
  }
  .styled_container {
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(199, 229, 255, 0.44);
  }
  .disabled-img {
    opacity: 0.5; /* Makes the image look disabled */
    cursor: not-allowed;
  }
  .enabled-img {
    cursor: pointer;
  }
`;

const jwtToken = window.localStorage.getItem("token");

const handleManagementView = () => {
  const hyperlink = `https://www.dev.mgntview.tymeplushr.com/?jwtToken=${window.localStorage.getItem(
    "token"
  )}`;
  window.open(hyperlink, "_blank");
};

const handlePay = () => {
  const hyperlink = `https://www.pay.tymeplushr.com/?token=${jwtToken}`;
  window.open(hyperlink, "_blank");
};

const handleLMS = () => {
  const hyperlink = `https://www.dev.learn.tymeplushr.com/?token=${jwtToken}`;
  window.open(hyperlink, "_blank");
};

const FloatingButton = ({ menuList, productInfo }) => {
  const [open, setOpen] = useState(false);
  const [canAccessPay, setCanAccessPay] = useState(false);

  const toggleButtons = () => setOpen(!open);

  useEffect(() => {
    // Check for a valid token
    let token = null;
    try {
      if (jwtToken) {
        token = jwtDecode(jwtToken);
        setCanAccessPay(!!token?.signInTo?.tymeplusPay); // Set access based on the token
      }
    } catch (error) {
      console.error("Invalid token:", error);
      setCanAccessPay(false); // Reset access if token is invalid
    }
  }, [jwtToken]);

  const handleWarning = () => {
    Modal.warning({
      title: "Access Denied",
      content:
        "Please reach out to our customer support or sales team to access the upgraded features.",
      okText: "OK",
    });
  };

  const items = [
    // {
    //   key: "2",
    //   label: "HR",
    //   // disabled: !canAccessPay, // Use state variable for access
    //   // onClick: canAccessPay ? handlePay : handleWarning,
    //   logo: HR,
    // },
    // {
    //   key: "2",
    //   label: "Pay",
    //   // disabled: !canAccessPay, // Use state variable for access
    //   onClick: productInfo?.tymeplusPayStatus ? handlePay : handleWarning,
    //   logo: Pay,
    // },
    {
      key: "3",
      label: "Analytics",
      disabled: false,
      onClick: handleManagementView,
      logo: Crm,
    },
    // {
    //   key: "3",
    //   label: "LMS",
    //   disabled: false,
    //   onClick: productInfo?.tymeplusLmsStatus ? handleLMS : handleWarning,
    //   logo: LMS,
    // },
  ];

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Styleddiv className="mx-2">
        <Fab
          className={open ? "active_icon" : "app_icon"}
          style={{ width: "36px", maxHeight: "36px" }}
          onClick={toggleButtons}
        >
          <AppsIcon />
        </Fab>

        {open && (
          <div
            className="styled_container"
            style={{
              position: "absolute",
              top: 80,
              right: 40,
              padding: "10px",
              width: "250px",
            }}
          >
            <Row>
              {items.map((item) => (
                <Col sm={12} className="text-center p-2" key={item.key}>
                  <img
                    src={item.logo}
                    alt={item.label}
                    className={item.disabled ? "disabled-img" : "enabled-img"}
                    onClick={!item.disabled ? item.onClick : null}
                  />
                  <h6>{item.label}</h6>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </Styleddiv>
    </ClickAwayListener>
  );
};

export default FloatingButton;
