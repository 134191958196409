import React, { useState, useEffect } from "react";

import ManagerDashboard from "./ManagerDashboard";
import HrDashboard from "./HrDashboard";
import MyDashboard from "./MyDashboard";
import TabsList from "molecules/TabsList";

// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getClientid,
  getRoleList,
  getUserRole,
  getMenuList,
  getSelectedDepartmentId,
  getDepartmentList,
  getAllDepartmentList,
  getLeaveCategoryList,
  getUserList,
  getLeaveList,
  getUserInfo,
} from "redux/selectors";
import { CALL_API } from "common/API";
import { setLeaveList, toggleSuccessModal } from "redux/actions";
import { bindActionCreators } from "redux";

function Dashboard({
  userRoleId,
  menuList,
  ClientId,
  selectedDepartmentId,
  departmentList,
  AllDepartmentList,
  roleList,
  leaveCategoryList,
  userList,
  updateSuccessModal,
  setLeaveListData,
  leaveList,
  userInfo,
}) {
  const Menulist =
    menuList[userRoleId] || Object.values(menuList)[0] || menuList;

  const filteredUserList = userList.filter((user) => user.statusid === "1");
  let tabMenus =
    Menulist &&
    Menulist.filter(
      (item) =>
        (item.key === "dashboard" ||
          item.key === "managerdashboard" ||
          item.key === "hrdashboard") &&
        item.system === "hr"
    );

  let newArray = [];
  newArray.push(...tabMenus);
  console.log("newArray--->", newArray);
  const items =
    newArray &&
    newArray
      ?.filter((data) => data.access === true)
      .map((data, index) => ({
        id: index,
        label: data.subtitle === "Team" ? "My Team" : data.subtitle,
        component:
          data.path === "dashboard" ? (
            <MyDashboard />
          ) : data.path === "managerdashboard" ? (
            <ManagerDashboard
              ClientId={ClientId}
              selectedDepartmentId={selectedDepartmentId}
              userRoleId={userRoleId}
              departmentList={departmentList}
              AllDepartmentList={AllDepartmentList}
              roleList={roleList}
              Menulist={Menulist}
              leaveCategoryList={leaveCategoryList}
              userList={filteredUserList}
              updateSuccessModal={updateSuccessModal}
              leaveList={leaveList}
              setLeaveList={setLeaveListData}
              userInfo={userInfo}
            />
          ) : data.path === "hrdashboard" ? (
            <HrDashboard
              ClientId={ClientId}
              selectedDepartmentId={selectedDepartmentId}
              userRoleId={userRoleId}
              departmentList={departmentList}
              AllDepartmentList={AllDepartmentList}
              roleList={roleList}
              Menulist={Menulist}
              leaveCategoryList={leaveCategoryList}
              userList={filteredUserList}
              updateSuccessModal={updateSuccessModal}
              leaveList={leaveList}
              setLeaveList={setLeaveListData}
            />
          ) : (
            <MyDashboard />
          ),
      }));

  return (
    <>
      {items.length > 1 && <TabsList items={items} />}
      {items.length === 1 && (
        <>
          {newArray?.map((data) => (
            <>
              {data?.access && data?.path === "dashboard" && <MyDashboard />}
              {data?.access && data?.path === "managerdashboard" && (
                <ManagerDashboard />
              )}
              {data?.access && data?.path === "hrdashboard" && <HrDashboard />}
            </>
          ))}
        </>
      )}
      {/* <MyDashboard /> */}
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userRoleType: getUserRole(state),
  menuList: getMenuList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  AllDepartmentList: getAllDepartmentList(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  leaveList: getLeaveList(state),
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
      setLeaveListData: setLeaveList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
