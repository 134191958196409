import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Table, Spin } from "antd";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getClientid,
  getUserInfo,
} from "redux/selectors";
import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMySqlDate,
  getinitialdate,
} from "utils/Date";
import { STATUS_CODE } from "common/Constants";
import { CALL_API } from "common/API";
import { includes } from "lodash";
import COLORS from "common/Colors";

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
`;

const StyledChartContainer = styled.div`
  border: solid 1px #9b9b9b;
  border-radius: 3px;
  margin-bottom: 25px;
  /* min-height: 350px;
  max-height: 350px; */
  // overflow-y: auto;
`;

const StyledTable = styled(Table)`
  .ant-table-content {
    overflow-x: auto;
    min-height: 450px;
    max-height: 450px;
    white-space: nowrap;
  }
  .ant-table-cell {
    padding: 10px;
    font-size: 0.8rem;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
`;

function DashboardPendingLeaves({
  userRoleId,
  selectedDepartmentId,
  ClientId,
  userInfo,
}) {
  const [pendingApprovals, setPendingApprovals] = useState([]);
  const [displayPendingApprovalLoader, togglePendingApprovalLoader] =
    useState(false);

  const [searchterm1, setSearchterm1] = useState(pendingApprovals);

  const data1 = pendingApprovals.filter((val) => {
    if (searchterm1 === "") {
      return val;
    } else if (val.status_name.toLowerCase().includes("wait".toLowerCase())) {
      return val;
    } else if (
      val.status_name.toLowerCase().includes("Approved".toLowerCase())
    ) {
      if (
        JSON.stringify(val.having_cancel_request)
          .toLowerCase()
          .includes("1".toLowerCase())
      ) {
        return val;
      }
      // return val;
    }
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      // align: 'center'
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
      // align: 'center'
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      width: "20%",
      // align: 'center',
      render: (_, record) => {
        if (record.new_start_date === null || record.new_start_date === "") {
          const dateFormat = moment(record.start_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_start_date).format(
            "DD MMM YYYY"
          );
          return `${dateFormat}`;
        }
      },
    },
    // {
    //   title: 'Start Day',
    //   dataIndex: 'start_date',
    //   key: 'start_date',
    //   width: '10%',
    //   align: 'center',
    //   render: (startDate) => {
    //     const getdayAlone = moment(startDate).format("dddd");
    //     return `${getdayAlone}`;
    //   }
    // },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      width: "20%",
      // align: 'center',
      render: (_, record) => {
        if (record.new_end_date === null || record.new_end_date === "") {
          const dateFormat = moment(record.end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        } else {
          const dateFormat = moment(record.new_end_date).format("DD MMM YYYY");
          return `${dateFormat}`;
        }
      },
    },
    // {
    //   title: 'End Day',
    //   dataIndex: 'end_date',
    //   key: 'end_date',
    //   width: '5%',
    //   align: 'center',
    //   render: (endDate) => {
    //     const getdayAlone = moment(endDate).format("dddd");
    //     return `${getdayAlone}`;
    //   }
    // },
    {
      title: "Leave Type",
      dataIndex: "leave_category_name",
      key: "leave_category_name",
      width: "20%",
      // align: 'center'
    },
    {
      title: "Amended Leave",
      dataIndex: "having_cancel_request",
      key: "having_cancel_request",
      // align: 'center',
      render: (having_cancel_request) => {
        if (having_cancel_request === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
  ];

  const getPendingLeaves = async () => {
    const initialdate = getinitialdate();
    const startDate = getKenyanDateTime();
    const endDate = addDaysinDate(startDate, 365);
    togglePendingApprovalLoader(true);
    const { code, leaves = [] } = await CALL_API(
      `leave-list/${ClientId}`,
      "post",
      {
        status_id: "",
        department_id: [""],
        selected_role_id: userRoleId,
        start_date: getMySqlDate(initialdate),
        end_date: getMySqlDate(endDate),
        reportingmanager: userInfo?.userid,
      }
    );

    togglePendingApprovalLoader(false);
    if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
      setPendingApprovals(leaves);
    }
  };

  useEffect(() => {
    if (
      (userRoleId !== 1 && selectedDepartmentId !== 0) ||
      userRoleId === 1 ||
      userRoleId === 6
    ) {
      getPendingLeaves();
    }
  }, []);

  useEffect(() => {
    if (userRoleId !== 1 || userRoleId === 6) {
      getPendingLeaves();
    }
  }, [selectedDepartmentId]);

  return (
    <>
      <StyledTitle>
        {userRoleId == 1 || userRoleId == 6
          ? "OVERALL WAITING FOR APPROVAL LIST"
          : "LEAVE APPLICATIONS WAITING FOR APPROVAL"}
      </StyledTitle>
      <StyledChartContainer>
        <Spin spinning={displayPendingApprovalLoader}>
          <StyledTable
            dataSource={data1}
            columns={columns}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "non-white"
            }
            rowKey="user_leave_id"
            pagination={false}
          />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(DashboardPendingLeaves);
