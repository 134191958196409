import { SearchOutlined } from "@mui/icons-material";
import { Input, Switch } from "antd";
import Icon from "atoms/Icon";
import Table from "atoms/Table";
import COLORS from "common/Colors";
import AlertModal from "organisms/AlertModal";
import React, { useState } from "react";
import { ButtonFilled } from "reusableComponent/UIButtons";
import AddHiringProcess from "./AddHiringProcess";
import { CALL_API } from "common/API";
import ProcessListModal from "./ProcessListModal";
import styled from "styled-components";
const Styledtotal = styled.div`
  @media (max-width: 320px) {
    .search-container {
      display: block !important;
    }
  }
`;
function HiringProcess({
  data,
  getData,
  tableloading,
  MenuList,
  searchTerm,
  setSearchTerm,
}) {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  // const [searchTerm, setSearchTerm] = React.useState("");
  const [viewOpen, setViewOpen] = useState(false);

  const handleSearchChange = (e) => {
    setSearchTerm(e?.target?.value);
  };

  const filteredDataSource = data?.filter((item) =>
    item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const handleEdit = async (item) => {
    const { title, steps, processId } = item;
    setSelectedItem({ title, steps, processId });
    setIsEdit(true);
    setShowAdd(true);
  };

  const handleView = async (item) => {
    const { title, steps, processId } = item;
    setSelectedItem({ title, steps, processId });
    setViewOpen(true);
  };

  const handleStatusChange = async (item) => {
    item.defaultStatus = true;
    await CALL_API(
      `recruitment/hiringprocess/${item?.processId}`,
      "patch",
      item
    );
    getData();
  };
  const columns = [
    {
      title: "Name of Hiring Process",
      dataIndex: "title",
      key: "title",
      render: (title, record) => (
        <div className="w-100" onClick={() => handleView(record)}>
          {title}
        </div>
      ),
    },
    {
      title: "Set as Default",
      dataIndex: "defaultStatus",
      key: "defaultStatus",
      render: (val, record) => {
        return (
          <Switch
            className="mx-5"
            size="small"
            checked={val}
            disabled={val}
            onChange={() => handleStatusChange(record)}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (val, record) => {
        if (record?.title === "Default") {
          return "";
        }
        return (
          <div className="d-flex gap-5">
            {MenuList?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "recruitment" && subItem?.permission.edit
                )
            ) && <Icon name="edit" onClick={() => handleEdit(record)} />}

            {MenuList?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "recruitment" && subItem?.permission.delete
                )
            ) && (
              <Icon
                name="trash"
                onClick={() => {
                  setDeleteId(record?.processId);
                  setDeleteOpen(true);
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const deleteData = async () => {
    setLoading(true);
    await CALL_API(`recruitment/hiringprocess/${deleteId}`, "patch", {
      deleteStatus: true,
    });
    getData();
    setLoading(false);
    setDeleteOpen(false);
  };

  const styledIcon = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "18px" };
  const styledInput = { color: `${COLORS.TEXT.PRIMARY}`, fontSize: "16px" };

  const handleSubmit = async (form) => {
    setLoading(true);
    if (isEdit) {
      // API For Edit Hiring Process
      await CALL_API(
        `recruitment/hiringprocess/${selectedItem?.processId}`,
        "patch",
        form
      );
      getData();
      setLoading(false);
      setIsEdit(false);
      setShowAdd(false);
      return;
    }
    // API For Adding Hiring Process
    await CALL_API(`recruitment/hiringprocess`, "post", form);
    getData();
    setLoading(false);
    setIsEdit(false);
    setShowAdd(false);
  };

  return (
    <div>
      <Styledtotal>
        <div className="search-container d-flex justify-content-between">
          <div className="mt-2">
            <Input
              style={styledInput}
              value={searchTerm}
              prefix={<SearchOutlined style={styledIcon} />}
              placeholder="Search"
              onChange={handleSearchChange}
            />
          </div>
          <div>
            {MenuList?.find(
              (item) =>
                item?.key === "HRResources" &&
                item?.subMenu?.find(
                  (subItem) =>
                    subItem?.key === "recruitment" && subItem?.permission.create
                )
            ) && (
              <ButtonFilled onClick={() => setShowAdd(true)}>
                + Add New Process
              </ButtonFilled>
            )}
          </div>
        </div>
      </Styledtotal>
      <Table
        dataSource={filteredDataSource}
        columns={columns}
        pagination={false}
        loading={tableloading}
      />
      <AlertModal
        isModalOpen={deleteOpen}
        handleCancel={() => setDeleteOpen(false)}
        handleOk={deleteData}
        name={"Are you sure you want to delete this hiring Process?"}
        loading={loading}
      />

      <AddHiringProcess
        isModalOpen={showAdd}
        handleCancel={() => {
          setShowAdd(false);
          setIsEdit(false);
          setSelectedItem({});
        }}
        handleOk={() => setShowAdd(false)}
        handleSubmit={handleSubmit}
        loading={loading}
        initialValue={selectedItem}
        isEdit={isEdit}
      />

      <ProcessListModal
        isModalOpen={viewOpen}
        handleCancel={() => {
          setViewOpen(false);
          setIsEdit(false);
        }}
        handleOk={() => {
          setViewOpen(false);
        }}
        name={"Are you sure you want to delete this hiring Process?"}
        loading={loading}
        onEdit={(item) => {
          handleEdit(item);
          setViewOpen(false);
        }}
        onDelete={(id) => {
          setDeleteId(id);
          setViewOpen(false);
          setDeleteOpen(true);
        }}
        selectedItem={selectedItem}
        MenuList={MenuList}
      />
    </div>
  );
}

export default HiringProcess;
