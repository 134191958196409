import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  message,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getClientid,
  getLeaveCategoryList,
  getUserList,
  getUserInfo,
} from "redux/selectors";

import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";

import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
} from "utils/Date";
import COLORS from "common/Colors";
import { concat, find, flatMap, get, includes, orderBy, reduce } from "lodash";
import ViewMore from "organisms/ViewMore";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    /* white-space: nowrap; */
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .normal-column {
    min-width: 300px;
    white-space: normal;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .stylediv {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .styleleavediv {
    background: #26a4ff;
    color: white;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
  }
  .stylebutton {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    border: none;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton3 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  border-radius: 5px;
`;
const StyledButtonoutlined = styled(ButtonOutlined)`
  @media (max-width: 768px) {
    margin-left: -40px !important;
    margin-top: -1px !important;
  }
  @media (max-width: 575px) {
    margin-left: 0 !important;
    position: absolute;
    left: 0;
    margin-top: -1px;
  }
`;
function AbsentReport({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  ClientId,
  userList,
  userInfo,
}) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [response, setresponse] = useState([]);
  const [CSVabsentreportlist, setCSVabsentreportlist] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();

  const filterList = [];

  const [monthList, setMonthList] = useState([]);
  const containerRef = useRef(null);

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = response.map((val) => {
    return { user_id: val.userId, name: val.name };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "User ID" },
    { label: "Name" },
    { label: "Department" },
    { label: "Date" },
    { label: "LeaveId" },
    { label: "Reason" },
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employeeId",
      key: "employeeId",
      width: "7%",
    },
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
      width: "3%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "3%",
    },
    {
      title: "Department",
      dataIndex: "baseDepartmentId",
      key: "baseDepartmentId",
      width: "5%",
      render: (departmentIdValue) => {
        if (departmentIdValue) {
          const department = allDepartmentList?.filter(
            (item) => departmentIdValue === item?.department_id
          );

          const departmentNames = department[0]?.department_name;
          const departmentName = departmentNames || "-";

          return <ViewMore value={departmentName} />;
        } else {
          return "-";
        }
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "5%",
      render: (date) => {
        const dateFormat = moment(date).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Day",
      dataIndex: "date",
      key: "date",
      width: "5%",
      render: (date) => {
        const getdayAlone = moment(date).format("dddd");
        return `${getdayAlone}`;
      },
    },
  ];

  let filterResponse = response?.filter((item) => item?.statusId == 1);
  const data1 = filterResponse?.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.userId === form.getFieldValue("staff_name")) {
      return val;
    }
  });

  useEffect(() => {
    list();
    getAttendanceReport();
  }, [
    selectedDepartmentId,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("start_date"),
    form.getFieldValue("departmentId"),
  ]);

  const getAttendanceReport = async () => {
    return new Promise(async () => {
      setresponse([]);
      const { start_date, end_date, duration_type, month } =
        form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );

      const { code, absentList = [] } = await CALL_API(
        `absent-report/${ClientId}`,
        "post",
        {
          roleId: userRoleId,
          departmentId: [""],
          startDate: getMySqlDate(
            duration_type === 2 ? startDateOfMonth : start_date
          ),
          endDate: getMySqlDate(
            duration_type === 2 ? lastDateOfMonth : end_date
          ),
          reportingmanager: userInfo?.userid,
        }
      );
      setDisplayTableLoader(false);
      if (includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)) {
        setresponse(orderBy(absentList, ["date"], ["desc"]));
      }
    });
  };

  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };

  const prepareCSV = async () => {
    if (data1.length > 0) {
      setCSVabsentreportlist(
        reduce(
          data1,
          function (result, record) {
            const departmentId = record?.baseDepartmentId;
            const department = allDepartmentList?.filter(
              (item) => departmentId === item?.department_id
            );

            const departmentName = department[0]?.department_name;

            const {
              year: end_year,
              date_th: startDate_th,
              shortMonthName: startMonthName,
            } = dateInDetail(record.date);
            result.push({
              "Employee ID": record?.employeeId,
              "User ID": record.userId,
              Name: record.name,
              Department: departmentName?.length > 0 ? departmentName : "-",
              Date: `${startDate_th} ${startMonthName} ${end_year}`,
              Day: `${moment(record?.date).format("dddd")}`,
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };

  const tableHeight = { height: "600px !important" };

  return (
    <>
      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                Action_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                departmentId: "",
              }}
              onFinish={getAttendanceReport}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            // Note : needed the first params
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                        ref={containerRef}
                      >
                        <DatePicker
                          placeholder="Start date"
                          format="DD-MM-YYYY"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            // Note : needed the first params
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                        ref={containerRef}
                      >
                        <DatePicker
                          placeholder="End date"
                          format="DD-MM-YYYY"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select..."
                        options={monthList}
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.name ?? "").toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(
                    {
                      user_id: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "user_id",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="departmentId">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <StyledButtonoutlined
            onClick={prepareCSV}
            disabled={response?.length === 0 || null || undefined}
          >
            Export CSV file
          </StyledButtonoutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVabsentreportlist}
            filename={"absent-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <br />
      <Spin spinning={displayTableLoader}>
        <StyledTable
          style={tableHeight}
          dataSource={data1}
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="checkin_id"
          pagination={data1.length > 100 ? paginationOptions : false}
        />
      </Spin>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  ClientId: getClientid(state),
  leaveCategoryList: getLeaveCategoryList(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(AbsentReport);
