import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Button,
  Select,
  Upload,
  Spin,
} from "antd";
import styled from "@emotion/styled";
import { NON_EMPTY_REGEX, VALIDATE_SYMBOL } from "common/Constants";
import { ReactComponent as DeleteIcon } from "../../assets/deleteicon.svg";
import { ButtonFilled } from "reusableComponent/UIButtons";
import { CALL_API } from "common/API";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    background: #f2f2f2 0% 0% no-repeat padding-box;
  }
  .ant-modal-title {
    font-weight: 500;
    font-size: 15px;
  }

  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-input {
    border-radius: 5px;
  }
  .ant-upload-select-picture-card {
    width: 100% !important;
    height: 40px !important;
  }
  .ant-upload-list-item-actions > a {
    display: none;
  }

  .File_name {
    padding: 10px;
    width: 90%;
    color: #397ef4;
    background: #f7f7f8;
  }
`;

const StyledFooterDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
`;

const { TextArea } = Input;
const { Option } = Select;

const EditAsset = ({
  isModalVisible,
  closeModal,
  isViewOnly,
  getAssetsData,
  editData,
  assets,
  edit,
}) => {
  const [form] = Form.useForm();
  const [showLoader, toggleLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileDeleted, setFileDeleted] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const CategoryList = ["Laptop", "Monitor", "Hard Disk"];
  console.log("getAssetsData-->>>", getAssetsData);
  console.log("assests-->>>", assets);
  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        name: editData.assetName,
        category: editData.category,
        description: editData.description,
      });
      // if (editData.image) {
      //   setFileList([
      //     {
      //       uid: editData.uniqueId,
      //       name: editData.imageName,
      //       status: "done",
      //       thumbUrl: editData.image.thumbUrl,
      //     },
      //   ]);
      // }
    }
  }, [editData]);

  const onFinish = async () => {
    const id = editData.uniqueId;
    await updateAssetData(id);
  };

  const handleUploadChange = ({ fileList }) => setFileList(fileList);

  const updateAssetData = async (id) => {
    const name = form.getFieldValue("name");
    const category = form.getFieldValue("category");
    const description = form.getFieldValue("description");
    toggleLoader(true);
    const { code } = await CALL_API(`assets/${editData?.assetId}`, "patch", {
      assetName: name,
      category: category,
      description: description,
      image: {
        imageName: fileDeleted ? fileList[0]?.name : editData.image?.imageName,
        uniqueId: fileDeleted
          ? `${editData.image?.uniqueId.split("_")[0]}_${fileList[0]?.name}`
          : editData.image?.uniqueId,
      },
      assetStatus: editData.assetStatus,
      assignedTo: editData.assignedTo,
      assignedDate: editData.assignedDate,
    });

    if (code === "SUCCESS") {
      if (fileDeleted && fileList.length > 0) {
        postAssetFile(id);
      } else {
        getAssetsData();
        toggleLoader(false);
        closeModal(false);
        setFileDeleted(false);
        message.success("Asset updated successfully");
      }
    } else {
      message.error("Failed to update Asset");
    }
  };

  const postAssetFile = async (id) => {
    const { code } = await CALL_API(`upload-document/WASZS4S`, "post", {
      name: fileList[0]?.name,
      type: "image/jpeg",
      content: fileList[0]?.thumbUrl,
      identity: "assets",
      uniqueId: id,
    });
    if (code === "SUCCESS") {
      getAssetsData();
      toggleLoader(false);
      closeModal(false);
      setFileDeleted(false);
      form.resetFields();
      setFileList([]);
      message.success("Asset updated successfully");
    } else {
      message.error("Failed to upload Asset image");
    }
  };

  const deletefile = async () => {
    setDeleteLoading(true);
    const { code } = await CALL_API(
      `delete-userdocuments?uniqueId=${editData.image?.uniqueId}&method=assets`,
      "delete"
    );

    if (code === "SUCCESS") {
      setDeleteLoading(false);
      setFileDeleted(true);
    } else {
      message.error("Failed to Delete file");
    }
  };

  return (
    <StyledModal
      title={"Edit Asset"}
      visible={isModalVisible}
      onCancel={() => {
        closeModal(false);
        form.resetFields();
        setFileList([]);
        setFileDeleted(false);
      }}
      width={500}
      footer={[]}
    >
      <Spin spinning={deleteLoading}>
        <Form
          form={form}
          name="AddLocation"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item
                label="Asset Name:"
                name="name"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Asset Name is required",
                  },
                  {
                    required: false,
                    pattern: VALIDATE_SYMBOL,
                    message: "Asset Name should not contain special characters",
                  },
                ]}
              >
                <Input
                  placeholder="Enter"
                  disabled={isViewOnly}
                  maxLength={50}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item
                label="Category:"
                name="category"
                rules={[
                  { required: true, message: "Please select a category" },
                ]}
              >
                <Select placeholder="Enter" disabled={isViewOnly}>
                  {CategoryList?.map((data) => (
                    <Option key={data} value={data}>
                      {data}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item
                label="Description:"
                name="description"
                rules={[
                  {
                    required: true,
                    pattern: NON_EMPTY_REGEX,
                    message: "Asset description is required",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter"
                  maxLength={500}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>
            {!fileDeleted && (
              <Col xs={24} sm={24} md={24} xl={24} className="d-flex">
                <div className="File_name me-2">
                  {editData.image?.imageName}
                </div>
                <DeleteIcon onClick={() => deletefile()} />
              </Col>
            )}
            <Col xs={24} sm={24} md={24} xl={24}>
              <Form.Item
                label="Image:"
                name="image"
                rules={[
                  {
                    validator: (_, value) =>
                      !fileDeleted
                        ? Promise.resolve()
                        : fileList.length > 0
                        ? Promise.resolve()
                        : Promise.reject(new Error("Please upload an image")),
                  },
                ]}
              >
                <Upload
                  listType="picture-card"
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={() => false}
                  accept=".jpg,.jpeg,.png"
                  disabled={!fileDeleted}
                >
                  {fileList.length >= 1 ? null : (
                    <div>
                      <div>Attach File</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <StyledFooterDiv>
            <StyledButton loading={showLoader} type="primary" htmlType="submit">
              Update
            </StyledButton>
          </StyledFooterDiv>
        </Form>
      </Spin>
    </StyledModal>
  );
};

export default EditAsset;
