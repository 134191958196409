import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import COLORS from "common/Colors";
const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    /* padding-right: 20px; */
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
    position: absolute;
    bottom: -1px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }

  .ant-tabs-nav::before {
    border: none !important;
  }
  .ant-tabs-nav-list {
    border-bottom: 2px solid #80808033;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    .ant-tabs-tab {
      margin: 0 !important;
    }
    .ant-tabs-nav .ant-tabs-nav-list {
      gap: 1rem;
    }
  }
`;

const TabsList = ({ items, handleTabSelect, tabId }) => {
  return (
    <StyledTabs
      defaultActiveKey={tabId}
      onChange={handleTabSelect}
      items={items?.map((val) => {
        return {
          label: val?.label,
          key: val?.id,
          children: val?.component,
        };
      })}
    />
  );
};
export default TabsList;
