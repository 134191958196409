import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Spin,
  Select,
  DatePicker,
  Space,
  Form,
  message,
  Input,
  Modal,
} from "antd";
import Button from "atoms/Button";
import moment from "moment";
import styled from "@emotion/styled";
import { CSVLink } from "react-csv";
// Redux
import { connect } from "react-redux";
import {
  getUserRoleId,
  getAllDepartmentList,
  getSelectedDepartmentId,
  getShiftTimeMaster,
  getClientid,
  getUserList,
  getMenuList,
  getUserInfo,
} from "redux/selectors";

import { CALL_API } from "common/API";
import {
  MONTH_LIST,
  REPORT_DURATION_TYPES,
  STATUS_CODE,
} from "common/Constants";

import {
  addDaysinDate,
  dateInDetail,
  getKenyanDateTime,
  getMonthDateRange,
  getMySqlDate,
  momentDate,
  addMonthinDate,
  differenceBetweenTwoTime,
  generateHourMinutesFromMinutes,
} from "utils/Date";
import COLORS from "common/Colors";
import { concat, find, get, includes, reduce } from "lodash";
import Form1 from "react-bootstrap/Form";
import ViewMore from "organisms/ViewMore";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledTable = styled(Table)`
  padding-top: 20px;
  .ant-table-content {
    overflow-x: auto;
    white-space: nowrap;
  }
  table {
    width: 100% !important;
  }
  .ant-table-cell {
    padding: 20px;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .non-white {
    background: #f9f9f9;
  }
  .normal-column {
    min-width: 200px;
    white-space: normal;
  }
  .stylediv {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .styleddiv {
    background: #26a4ff;
    color: white;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .stylebutton-yes {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    border: none;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    :hover {
      background: #198754;
      color: white;
    }
  }
  .stylebutton-no {
    background: #f6f6f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000014;
    border-radius: 5px;
    opacity: 1;
    border: none;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    :hover {
      background: #ff4d4f;
      color: white;
    }
  }
`;

const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-select-selector {
    max-width: 180px;
    min-width: 180px;
    margin-bottom: 10px;
    width: 180px;
  }
`;
const StyledButton2 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  width: initial;
  background: transparent;
  border-radius: 5px;
`;
const StyledButtonoutlined = styled(ButtonOutlined)`
  @media (max-width: 768px) {
    margin-left: -40px !important;
    margin-top: -1px !important;
  }
  @media (max-width: 575px) {
    margin-left: 0 !important;
    position: absolute;
    left: 0;
    margin-top: -1px;
  }
`;
function OvertimeReport({
  userRoleId,
  allDepartmentList,
  selectedDepartmentId,
  userList,
  ClientId,
  menuList,
  userInfo,
}) {
  const [displayTableLoader, setDisplayTableLoader] = useState(false);
  const [overtimeList, setOvertimeList] = useState([]);
  const [overtimeList1, setOvertimeList1] = useState([]);
  const [CSVOvertimeList, setCSVOvertimeList] = useState([]);
  const [form] = Form.useForm();
  const csvLinkEl = useRef();
  const [ActionType, setActionType] = useState("1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordlist, setrecordlist] = useState([]);
  const [clickactionId, setclickactionId] = useState(0);
  const [filterList, setFilterList] = useState([]);
  const { TextArea } = Input;
  const [monthList, setMonthList] = useState([]);
  const containerRef = useRef(null);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
    }
    setMonthList(month_list);
  };

  const STAFF_LIST_ALL = (
    ActionType === "1" ? overtimeList : overtimeList1
  ).map((val) => {
    return {
      userid: val.userid,
      name: val.fullname,
    };
  });

  for (let i = 0; i < STAFF_LIST_ALL.length; i++) {
    filterList.push(STAFF_LIST_ALL[i]);
  }

  const STAFF_LIST = [...new Set(filterList.map((a) => JSON.stringify(a)))].map(
    (a) => JSON.parse(a)
  );

  const overtimefilterdata = overtimeList.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.userid === form.getFieldValue("staff_name")) return val;
  });

  const overtimefilterdata1 = overtimeList1.filter((val) => {
    if (form.getFieldValue("staff_name") === "") {
      return val;
    } else if (val?.userid === form.getFieldValue("staff_name")) return val;
  });

  const errorValidation = (values) => {
    let errors = {};
    if (!values.hours) {
      errors.hours = "This field is required";
    }
    if (!values.notes) {
      errors.notes = "This field is required";
    }
    return errors;
  };

  const [values, setValues] = useState({
    hours: "00:00",
    notes: "",
  });
  const [dataIsCorrect, setDataiscorrect] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleFormsubmit = (event) => {
    event.preventDefault();

    setErrors(errorValidation(values));
    setDataiscorrect(true);
  };

  const resetValues = () => {
    setValues({
      notes: "",
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      update();
      setIsModalOpen(false);
      setTimeout(() => {
        getOvertimeReport();
      }, 2000);
    }
  }, [errors]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetValues();
  };

  console.log("recordlist", recordlist);

  const overtimeactionclick = (record) => {
    const diff = differenceBetweenTwoTime(
      record?.shiftendtime,
      record?.checkouttime
    );
    showModal();
    setrecordlist(record);
    setclickactionId(1);
    setValues({ hours: `${generateHourMinutesFromMinutes(diff)}` });
  };

  const overtimeactionclick1 = (record) => {
    showModal();
    setrecordlist(record);
    setclickactionId(2);
    setValues({ hours: "00:00" });
  };

  const update = async (record) => {
    const diff = differenceBetweenTwoTime(
      recordlist?.shiftendtime,
      recordlist?.checkouttime
    );
    const filteruser = userList?.filter(
      (item) => item?.userid === recordlist?.userid
    );

    const { code } = await CALL_API(`overtime-action/${ClientId}`, "patch", {
      user_id: recordlist?.userid,
      employeeid: filteruser[0]?.employeeid,
      name: recordlist?.fullname,
      start_time: recordlist?.checkintime,
      end_time: recordlist?.checkouttime,
      shiftendtime: recordlist?.shiftendtime,
      departmentname: recordlist?.departmentname,
      duration: generateHourMinutesFromMinutes(diff),
      status_id: clickactionId,
      overtime_update: `${values?.hours}:00`,
      department_id: recordlist?.departmentid,
      checkin_id: recordlist?.userpunchactionid,
      date: recordlist?.checkindate,
      commands: values?.notes,
      reportingmanager: recordlist?.reportingmanager || "",
    });
    resetValues();
  };

  const CSVHeaders = [
    { label: "Employee ID" },
    { label: "Employee Name" },
    { label: "Department" },
    { label: "Checkin Date" },
    { label: "Checkin Time" },
    { label: "Checkout Time" },
    { label: "Duration (Hours)" },
    { label: "Late time (Hours)" },
    ActionType === "2" && [
      { label: "Overtime Amended" },
      { label: "Comments" },
    ],
  ];

  const columns = [
    {
      title: "Employee ID",
      dataIndex: ActionType === "1" ? "userid" : "employeeId",
      key: ActionType === "1" ? "userid" : "employeeId",
      width: "7%",
      render: (userId) => {
        const filteruser = userList?.filter((item) => item?.userid === userId);
        return ActionType === "1" ? filteruser[0]?.employeeid : userId;
      },
    },
    {
      title: "Employee Name",
      dataIndex: ActionType === "1" ? "fullname" : "fullName",
      key: ActionType === "1" ? "fullname" : "fullName",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: ActionType === "1" ? "departmentname" : "departmentName",
      key: ActionType === "1" ? "departmentname" : "departmentName",
      width: "10%",
    },
    {
      title: "Checkin Date",
      dataIndex: ActionType === "2" ? "overtimeDate" : "checkindate",
      key: ActionType === "2" ? "overtimeDate" : "checkindate",
      width: "7%",
      render: (checkinDate) => {
        const checkinDateFormat = moment(checkinDate).format("DD MMM YYYY");
        return `${checkinDateFormat}`;
      },
    },
    {
      title: "Checkin Day",
      dataIndex: ActionType === "2" ? "overtimeDate" : "checkindate",
      key: ActionType === "2" ? "overtimeDate" : "checkindate",
      width: "10%",
      render: (checkinDate) => {
        const getdayAlone = moment(checkinDate).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Checkin Time",
      dataIndex: ActionType === "2" ? "startTime" : "checkintime",
      key: ActionType === "2" ? "startTime" : "checkintime",
      width: "10%",
      render: (time) => {
        const formattedTime = moment(time, "HH:mm:ss").format("HH:mm");
        return `${formattedTime}`;
      },
    },
    {
      title: "Checkout Date",
      dataIndex: ActionType === "2" ? "overtimeDate" : "checkoutdate",
      key: ActionType === "2" ? "overtimeDate" : "checkoutdate",
      width: "7%",
      render: (checkoutDate) => {
        const dateFormat = moment(checkoutDate).format("DD MMM YYYY");
        return `${dateFormat}`;
      },
    },
    {
      title: "Checkout Day",
      dataIndex: ActionType === "2" ? "overtimeDate" : "checkoutdate",
      key: ActionType === "2" ? "overtimeDate" : "checkoutdate",
      width: "10%",
      render: (checkoutDate) => {
        const getdayAlone = moment(checkoutDate).format("dddd");
        return `${getdayAlone}`;
      },
    },
    {
      title: "Checkout Time",
      dataIndex: ActionType === "1" ? "checkouttime" : "endTime",
      key: ActionType === "1" ? "checkouttime" : "endTime",
      width: "10%",
      render: (time) => {
        const formattedTime = moment(time, "HH:mm:ss").format("HH:mm");
        return `${formattedTime}`;
      },
    },
    {
      title: "Overtime Duration (Hours)",
      dataIndex: "overtimeDuration",
      key: "overtimeDuration",
      width: "10%",
      // Note : need this
      render: (_, record) => {
        if (ActionType === "1") {
          const diff = differenceBetweenTwoTime(
            record?.shiftendtime,
            record?.checkouttime
          );
          return generateHourMinutesFromMinutes(diff);
        } else {
          return record?.overtimeDuration;
        }
      },
    },
  ];
  if (
    ActionType === "1" &&
    Menulist?.find(
      (item) =>
        item?.key === "reports" &&
        item?.subMenu.find(
          (subItem) =>
            subItem?.key === "overtime-report" && subItem?.permission.edit
        )
    )
  ) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "overtimeDuration",
      width: "15%",
      render: (_, record) => {
        return (
          <Row
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row>
              <Col>
                <button
                  className="stylebutton-yes"
                  onClick={() => overtimeactionclick(record)}
                >
                  Yes
                </button>{" "}
              </Col>
              <Col>
                <button
                  className="stylebutton-no"
                  onClick={() => overtimeactionclick1(record)}
                >
                  No
                </button>
              </Col>
            </Row>
          </Row>
        );
      },
    });
  }
  if (ActionType === "2") {
    columns.push(
      {
        title: "Action",
        dataIndex: "action",
        key: "overtimeDuration",
        width: "15%",
        render: (_, record) => {
          return (
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {ActionType === "2" && (
                <Row>
                  {record?.statusId === 1 ? (
                    <Col>
                      <div
                        style={{ background: "#198754" }}
                        className="styleddiv"
                      >
                        Yes
                      </div>
                    </Col>
                  ) : (
                    <Col>
                      <div
                        style={{ background: "#FF4D4F" }}
                        className="styleddiv"
                      >
                        No
                      </div>
                    </Col>
                  )}
                </Row>
              )}
            </Row>
          );
        },
      },
      {
        title: "Overtime Amended",
        dataIndex: "overtimeUpdate",
        key: "overtimeUpdate",
        width: "10%",
      },
      {
        title: "Comments",
        dataIndex: "commands",
        key: "commands",
        width: "10%",
        className: "normal-column",
        render: (reason) => {
          return (
            <>
              <ViewMore value={reason} />
            </>
          );
        },
      }
    );
  }

  useEffect(() => {
    list();
    getOvertimeReport();
  }, [
    selectedDepartmentId,
    ActionType,
    userRoleId,
    form.getFieldValue("duration_type"),
    form.getFieldValue("start_date"),
    form.getFieldValue("end_date"),
    form.getFieldValue("month"),
    form.getFieldValue("department"),
  ]);

  // Function to generate report
  const getOvertimeReport = async () => {
    return new Promise(async (resolve, reject) => {
      setOvertimeList([]);
      const { start_date, end_date, duration_type, month, department } =
        form.getFieldsValue();
      setDisplayTableLoader(true);
      const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
        dateInDetail(getKenyanDateTime()).year,
        get(find(MONTH_LIST, { value: month }), "index", 0)
      );
      if (ActionType === "1") {
        const { code, overtimeList = [] } = await CALL_API(
          `overtime-report/${ClientId}`,
          "post",
          {
            department: [""],
            // userRoleId === 1 || userRoleId === 6
            //   ? [department]
            //   : selectedDepartmentId,
            start_date: getMySqlDate(
              duration_type === 2 ? startDateOfMonth : start_date
            ),
            end_date: getMySqlDate(
              duration_type === 2 ? lastDateOfMonth : end_date
            ),
            reportingmanager: userInfo?.userid,
          }
        );

        setDisplayTableLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          setOvertimeList(overtimeList);
        }
      } else {
        const { code, overTimeActionList = [] } = await CALL_API(
          `overtime-action/${ClientId}`,
          "post",
          {
            departmentId: [""],
            // userRoleId === 1 || userRoleId === 6
            //   ? [department]
            //   : selectedDepartmentId,

            start_date: getMySqlDate(
              duration_type === 2 ? startDateOfMonth : start_date
            ),
            end_date: getMySqlDate(
              duration_type === 2 ? lastDateOfMonth : end_date
            ),
            reportingmanager: userInfo?.userid,
          }
        );
        setDisplayTableLoader(false);
        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          setOvertimeList1(overTimeActionList.reverse());
        }
      }
    });
  };

  // Function to valiadate date range
  const validateDateRange = (date) => {
    const endDate = moment(form.getFieldValue("end_date"));
    if (endDate < date) {
      form.setFieldsValue({ end_date: date });
    }
  };
  // Function to Prepare CSV
  const prepareCSV = async (event, done) => {
    if (ActionType === "1") {
      if (overtimefilterdata?.length > 0) {
        setCSVOvertimeList(
          reduce(
            overtimefilterdata,
            function (result, record) {
              const filteruser = userList?.filter(
                (item) => item?.userid === record?.userid
              );
              const {
                date_th: day,
                shortMonthName,
                year,
                longDay,
              } = dateInDetail(record.checkindate);
              const {
                date_th: startDate_th1,
                shortMonthName: startMonthName1,
                year: end_year1,
              } = dateInDetail(record.checkoutdate);
              const diff = differenceBetweenTwoTime(
                record?.shiftendtime,
                record?.checkouttime
              );
              result.push({
                "Employee ID": filteruser[0]?.employeeid,
                "Employee Name": record?.fullname,
                Department: record?.departmentname,
                "Checkin Date": `${day} ${shortMonthName} ${year}`,
                "Checkin Day": `${moment(record.checkindate).format("dddd")}`,
                "Checkin Time": record?.checkintime,
                "Checkout Date": `${day} ${shortMonthName} ${year}`,
                "Checkout Day": `${moment(record.checkoutdate).format("dddd")}`,
                "Checkout Time": record?.checkouttime,
                "Duration (Hours)": generateHourMinutesFromMinutes(diff),
              });
              return result;
            },
            []
          )
        );
        setTimeout(() => {
          csvLinkEl.current.link.click();
        });
      } else {
        message.error(`No data available to download.`);
      }
    } else {
      if (overtimefilterdata1?.length > 0) {
        setCSVOvertimeList(
          reduce(
            overtimefilterdata1,
            function (result, record) {
              const {
                date_th: day,
                shortMonthName,
                year,
                longDay,
              } = dateInDetail(record.overtimeDate);
              const {
                date_th: startDate_th1,
                shortMonthName: startMonthName1,
                year: end_year1,
              } = dateInDetail(record.checkoutdate);
              const diff = differenceBetweenTwoTime(
                record?.shiftendtime,
                record?.endTime
              );
              result.push({
                "Employee ID": record?.employeeId,
                "Employee Name": record?.fullName,
                Department: record?.departmentname,
                "Checkin Date": `${day} ${shortMonthName} ${year}`,
                "Checkin Day": `${moment(record.overtimeDate).format("dddd")}`,
                "Checkin Time": record?.startTime,
                "Checkout Date": `${day} ${shortMonthName} ${year}`,
                "Checkout Day": `${moment(record.overtimeDate).format("dddd")}`,
                "Checkout Time": record?.endTime,
                "Duration (Hours)": record?.overtimeDuration,
                Action: record?.statusId === 1 ? "Yes" : "No",
                "Overtime Amended": record?.overtimeUpdate,
                Comments: record?.commands,
              });
              return result;
            },
            []
          )
        );
        setTimeout(() => {
          csvLinkEl.current.link.click();
        });
      } else {
        message.error(`No data available to download.`);
      }
    }
  };

  const paginationOptions = {
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: 100,
    showSizeChanger: false,
  };
  const { hours, notes } = values;

  return (
    <>
      <Row className="page-title">
        <Col
          xs={24}
          sm={19}
          md={19}
          className="page-title-head"
          ref={containerRef}
        >
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                staff_name: "",
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(), -1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime())),
                department: "",
              }}
              onFinish={getOvertimeReport}
            >
              <Form.Item
                name="duration_type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  options={REPORT_DURATION_TYPES}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
              <Select
                style={{ marginRight: "20px" }}
                className="selectbox"
                placeholder="Employee type"
                defaultValue={"1"}
                options={[
                  {
                    value: "1",
                    label: "Waiting For Action",
                  },
                  {
                    value: "2",
                    label: "Action history",
                  },
                ]}
                onChange={(e) => setActionType(e)}
                getPopupContainer={() => containerRef.current}
              />

              <Form.Item className="outer-form" shouldUpdate>
                {({ getFieldValue }) => {
                  const duration_type = getFieldValue("duration_type");
                  return duration_type === 1 ? (
                    <>
                      <Form.Item
                        name="start_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="Start date"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>

                      <Form.Item
                        name="end_date"
                        rules={[
                          () => ({
                            validator(rule, value) {
                              const date = moment(value);
                              return date.isValid()
                                ? Promise.resolve()
                                : Promise.reject();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          placeholder="End date"
                          onChange={validateDateRange}
                          allowClear={false}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <Form.Item
                      name="month"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select..."
                        options={monthList}
                        getPopupContainer={() => containerRef.current}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item
                name="staff_name"
                rules={[
                  {
                    required: false,
                    message: "",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.name ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.name ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.name ?? "").toLowerCase())
                  }
                  placeholder="Select Employee"
                  options={concat(
                    {
                      userid: "",
                      name: "All Employee",
                    },
                    STAFF_LIST
                  )}
                  fieldNames={{
                    label: "name",
                    value: "userid",
                  }}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
              {(userRoleId === 1 || userRoleId === 6) && (
                <Form.Item name="department">
                  <Select
                    placeholder="Select Department"
                    options={concat(
                      {
                        department_id: "",
                        department_name: "All Departments",
                      },
                      allDepartmentList
                    )}
                    fieldNames={{
                      label: "department_name",
                      value: "department_id",
                    }}
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              )}
            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <Col xs={24} sm={4} md={4} className="align-right">
          <StyledButtonoutlined
            onClick={prepareCSV}
            disabled={
              ActionType === "1"
                ? overtimefilterdata.length === 0
                : overtimefilterdata1.length === 0
            }
          >
            Export CSV file
          </StyledButtonoutlined>
          <CSVLink
            header={CSVHeaders}
            data={CSVOvertimeList}
            filename={"overtime-report.csv"}
            asyncOnClick={true}
            ref={csvLinkEl}
          />
        </Col>
      </Row>
      <br />
      <Spin spinning={displayTableLoader}>
        <StyledTable
          dataSource={
            ActionType === "1" ? overtimefilterdata : overtimefilterdata1
            // ActionType === "1" ? dummydata : dummydata
          }
          columns={columns}
          rowClassName={(record, index) => (index % 2 === 0 ? "" : "non-white")}
          rowKey="user_leave_id"
          // pagination={
          //   (ActionType === "1" ? dummydata : dummydata).length > 20
          //     ? paginationOptions
          //     : false
          // }
          pagination={
            (ActionType === "1" ? overtimefilterdata : overtimefilterdata1)
              .length > 100
              ? paginationOptions
              : false
          }
        />
      </Spin>
      <Modal
        title="Add Note"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
        keyboard={false}
      >
        <div>
          <Form1.Group>
            {clickactionId === 1 && (
              <>
                <Form1.Label
                  style={{ Font: "normal normal normal 16px/19px Lato" }}
                >
                  Duration:
                </Form1.Label>{" "}
                <br></br>
                <Input
                  style={{
                    width: "100%",
                    border: "1px solid #9B9B9B",
                    marginBottom: "10px",
                  }}
                  placeholder=""
                  type="time"
                  name="hours"
                  value={hours}
                  onChange={handleChange}
                />
                {errors.hours && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      marginBottom: "-5px",
                    }}
                  >
                    {errors.hours}
                  </p>
                )}
                <br></br>
              </>
            )}
            <Form1.Label
              style={{ Font: "normal normal normal 16px/19px Lato" }}
            >
              Note:
            </Form1.Label>{" "}
            <br></br>
            <TextArea
              style={{ width: "100%", border: "1px solid #9B9B9B" }}
              placeholder=""
              type="text"
              name="notes"
              value={notes}
              rows={4}
              maxLength={500}
              onChange={handleChange}
            />
            {errors.notes && (
              <p
                style={{ color: "red", fontSize: "10px", marginBottom: "-5px" }}
              >
                {errors.notes}
              </p>
            )}
          </Form1.Group>

          <Col
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
            }}
          >
            <Button
              style={{
                background: "#26A4FF",
                borderRadius: "5px",
                marginTop: "20px",
                color: "#FFFFFF",
              }}
              onClick={handleFormsubmit}
            >
              Save
            </Button>
          </Col>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  allDepartmentList: getAllDepartmentList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  shiftTimeMaster: getShiftTimeMaster(state),
  ClientId: getClientid(state),
  userList: getUserList(state),
  menuList: getMenuList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(OvertimeReport);
