import React, { useState } from "react";

import { connect } from "react-redux";
import {
  getClientid,
  getUserInfo,
  getUserList,
  getUserRoleId,
  getMenuList,
} from "redux/selectors";

import TabsList from "molecules/TabsList";
import MyBusinessTrip from "./BusinessTrip";
import MyReimbursement from "./Reimbursement";
import { Typography } from "antd";
import { toggleSuccessModal } from "redux/actions";
import { bindActionCreators } from "redux";
import styled from "@emotion/styled";

const StyledWrap = styled.div`
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-input[disabled] {
    color: black !important;
    background: transparent !important;
  }

  .ant-picker.ant-picker-disabled {
    color: black !important;
    background: transparent !important;
  }
`;
const StyledTabLabel = styled.span`
  font-size: 1rem; /* Default font size */

  @media (max-width: 320px) {
    /* For mobile screens */
    font-size: 11.5px;
  }
`;

const MyTravelExpense = ({
  ClientId,
  userInfo,
  updateSuccessModal,
  menuList,
  userRoleId,
}) => {
  const [hideTitle, setHideTitle] = useState(false);
  const [searchString, setSearchString] = useState("");

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const items = [
    {
      id: 1,
      label: <StyledTabLabel>Business Trip</StyledTabLabel>,
      component: (
        <MyBusinessTrip
          ClientId={ClientId}
          userInfo={userInfo}
          updateSuccessModal={updateSuccessModal}
          Menulist={Menulist}
          setSearchString={setSearchString}
          searchString={searchString}
        />
      ),
    },
    {
      id: 2,
      label: <StyledTabLabel>Reimbursement</StyledTabLabel>,
      component: (
        <MyReimbursement
          ClientId={ClientId}
          userInfo={userInfo}
          updateSuccessModal={updateSuccessModal}
          setHideTitle={setHideTitle}
          Menulist={Menulist}
          setSearchString={setSearchString}
          searchString={searchString}
        />
      ),
    },
  ];

  const handleTabSelect = () => {
    setHideTitle(false);
    setSearchString("");
  };

  return (
    <StyledWrap>
      {!hideTitle && (
        <Typography.Title className="p-2" level={4}>
          Travel & Expense
        </Typography.Title>
      )}

      <TabsList items={items} handleTabSelect={handleTabSelect} />
    </StyledWrap>
  );
};

const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  userInfo: getUserInfo(state),
  userList: getUserList(state),
  menuList: getMenuList(state),
  userRoleId: getUserRoleId(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateSuccessModal: toggleSuccessModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MyTravelExpense);
