import styled from "@emotion/styled";
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  TimePicker,
  Upload,
} from "antd";
import Button from "atoms/Button";
import { CALL_API } from "common/API";
import { displayErrorModal } from "common/common";
import { NON_EMPTY_REGEX, STATUS_CODE } from "common/Constants";
import { concat, find, get, includes, intersection, result } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setLeaveList } from "redux/actions";
import {
  getAllDepartmentList,
  getClientid,
  getLeaveCategoryList,
  getLeaveList,
  getRoleList,
  getSelectedDepartmentId,
  getUserInfo,
  getUserList,
  getUserRoleId,
} from "redux/selectors";
import {
  addDaysinDate,
  getKenyanDateTime,
  getMySqlDate,
  momentDate,
} from "utils/Date";
import { bindActionCreators } from "redux";
import { UploadOutlined } from "@ant-design/icons";
import { ButtonFilled } from "reusableComponent/UIButtons";
import { ButtonOutlined } from "reusableComponent/UIButtons";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .ant-modal-title {
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .ant-modal-body {
    padding-top: 0px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    outline: 0;
    width: 100%;
    text-align: center;
  }
    .small-text{
    #636980 !important;
    }
`;

function LeaveForm({
  isModalVisible,
  closeModal,
  leaveCategoryList,
  leaveDetails,
  ClientId,
  AllDepartmentList,
  userRoleId,
  roleList,
  userInfo,
  userList,
  setLeaveListData,
  selectedDepartmentId,
  editLeaveDisable,
}) {
  const IncidentTypeOption = [
    { id: 1, value: "Workplace Injury" },
    { id: 2, value: "Hazardous Material Exposure" },
    { id: 3, value: "Vehicle Accident" },
    { id: 4, value: "Fire or Explosion" },
    { id: 5, value: "Medical Emergency" },
    { id: 6, value: "Near miss" },
  ];

  const [showLoader, toggleLoader] = useState(false);
  const [showLeaveValidationLoader, toggleLeaveValidationLoader] =
    useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isCancelRequest, setCancelRequest] = useState(false);
  const [form] = Form.useForm();
  const [halfDay, setHalfDay] = useState(false);
  const [showHalfDayCheckbox, setShowHalfDayCheckbox] = useState(true);
  const { TextArea } = Input;
  const containerRef = useRef(null);

  const FindName = (userid) => {
    const finduser = userList?.find((val) => val?.userid === userid);
    return finduser?.fullname;
  };

  const filterRoleList = roleList?.filter(
    (item) => item?.roleId === userRoleId
  );
  const filterDepartmentId = filterRoleList[0]?.department;
  const filterDepartment = AllDepartmentList?.filter((item) =>
    filterDepartmentId?.includes(item?.department_id)
  );

  const resetForm = () => {
    setShowHalfDayCheckbox(true);
    form.setFieldsValue({
      incidenttype: "",
      incidentlocation: "",
      date: momentDate(getKenyanDateTime()),
      time: "",
      description: "",
      remark: "",
    });
  };

  useEffect(() => {
    resetForm();
  }, [userRoleId, leaveCategoryList]);

  const resetHalfDay = () => {
    setHalfDay(false);
    form.setFieldsValue({ number_of_days: 1 });
  };

  const updateHalfDay = (halfday) => {
    const { number_of_days } = form.getFieldsValue();
    const roundedDays = number_of_days;
    if (halfday === true) {
      if (number_of_days >= 0.5) {
        form.setFieldsValue({
          number_of_days: roundedDays - 0.5,
        });
      }
    } else {
      if (number_of_days >= 0.5) {
        form.setFieldsValue({
          number_of_days: Math.ceil(roundedDays),
        });
      }
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      if (
        moment(leaveDetails?.end_date).format("dddd") === "Saturday" &&
        moment(leaveDetails?.start_date).format("dddd") === "Saturday" &&
        moment(leaveDetails?.start_date).format("L") ===
          moment(leaveDetails?.end_date).format("L")
      ) {
        setShowHalfDayCheckbox(false);
      } else {
        setShowHalfDayCheckbox(true);
      }
    }
  }, [isModalVisible]);

  useEffect(() => {
    const {
      user_leave_id = "",
      start_date = "",
      end_date = "",
      reason = "",
      number_of_days = 0,
      leave_category_id,
      isViewOnly,
      isCancel = false,
      department_id = "",
    } = leaveDetails;
    if (user_leave_id !== "") {
      setIsViewOnly(isViewOnly);
      setCancelRequest(isCancel);

      form.setFieldsValue({
        leave_id: user_leave_id,
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
        number_of_days: number_of_days,
        leave_type: leave_category_id,
        description: reason,
        remark: "",
        department: department_id,
      });
      if (
        !isNaN(parseFloat(number_of_days)) &&
        number_of_days.toString().includes(".")
      ) {
        setHalfDay(true);
      } else {
        setHalfDay(false);
      }
    } else if (start_date && end_date) {
      setCancelRequest(isCancel);
      form.setFieldsValue({
        start_date: momentDate(start_date),
        end_date: momentDate(end_date),
        leave_id: "",
        number_of_days: 1,
        leave_type: filteredCategoriesbygender[0]?.leave_category_id,
        description: "",
        remark: "",
        department: "",
      });
      setHalfDay(false);
    }
  }, [leaveDetails, isModalVisible]);

  function getDates() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const startDate = new Date(currentYear - 2, 0, 1);
    const endDate = new Date(currentYear + 1, 11, 31);

    return {
      startDate,
      endDate,
    };
  }

  const dates = getDates();

  const getLeaveList = async () => {
    return new Promise(async (resolve, reject) => {
      if (selectedDepartmentId) {
        const { code, leaves = [] } = await CALL_API(
          `leave-list/${ClientId}`,
          "post",
          {
            status_id: "4",
            start_date: dates.startDate,
            end_date: dates.endDate,
          }
        );

        if (
          includes([STATUS_CODE.SUCCESS, STATUS_CODE.RECORD_NOT_FOUND], code)
        ) {
          setLeaveListData(leaves.reverse());

          resolve(leaves);
        } else {
          reject([]);
        }
      } else {
        reject();
      }
    });
  };

  // Note: First params is needed
  const onFinish = async (values, saveLeave = true) => {
    if (saveLeave) toggleLoader(true);
    else toggleLeaveValidationLoader(true);
    const {
      start_date,
      end_date,
      number_of_days,
      leave_type,
      description,
      remark,
    } = form.getFieldsValue();
    const filterleavetype = leaveCategoryList?.filter(
      (item) => item.leave_category_id === leave_type?.toString()
    );
    const deptName = result(
      find(AllDepartmentList, (e) => {
        return finduser?.basedepartmentid?.includes(e.department_id);
      }),
      "department_name"
    );
    const { user_leave_id = "" } = leaveDetails;
    if (leave_type !== "") {
      const { code, leaveBalance, totalNumberOfDaysInLeave, reason } =
        await CALL_API(`save-leave/${ClientId}`, "post", {
          leave_id: user_leave_id,
          start_date: getMySqlDate(start_date),
          end_date: getMySqlDate(end_date),
          number_of_days,
          leave_category_id: leave_type.toString(),
          reason: description,
          decline_reason: isCancelRequest ? remark : "",
          is_cancel_request: isCancelRequest,
          isSaving: saveLeave,
          reporting_manager: finduser?.reportingmanager,
          reporting_manager_name: FindName(finduser?.reportingmanager),
          old_number_of_day: leaveDetails?.number_of_days
            ? leaveDetails?.number_of_days
            : "",
          holiday: filterleavetype[0]?.holiday?.length
            ? filterleavetype[0]?.holiday
            : "No",
          weekend: filterleavetype[0]?.weekend?.length
            ? filterleavetype[0]?.weekend
            : "No",
          isSaturday: filterleavetype[0]?.isSaturday?.length
            ? filterleavetype[0]?.isSaturday
            : "No",
          employeeid: filteruser[0]?.employeeid,
          leave_category_name: get(
            find(filteredCategoriesbygender, {
              leave_category_id: leave_type.toString(),
            }),
            "leave_category_name",
            ""
          ),
          fullname: filteruser[0]?.fullname,
          halfday: halfDay === true ? "Yes" : "No",
          department_name: deptName,
          department_id: finduser?.basedepartmentid,
        });
      const payloadVariable = {
        leave_id: user_leave_id,
        start_date: getMySqlDate(start_date),
        end_date: getMySqlDate(end_date),
        number_of_days,
        leave_category_id: leave_type,
        reason: description,
        decline_reason: isCancelRequest ? remark : "",
        is_cancel_request: isCancelRequest,
        isSaving: saveLeave,
      };
      if (saveLeave) toggleLoader(false);
      else toggleLeaveValidationLoader(false);
      if (code === STATUS_CODE.SOMETHING_WENT_WRONG) {
        message.error(`Oops!! something went wrong`);
      } else if (code === STATUS_CODE.INVALID_PAYLOAD) {
        if (
          filterleavetype[0]?.holiday === "No" &&
          filterleavetype[0]?.weekend === "No"
        ) {
          message.error(`You cannot apply for leave on Holidays and Sundays`);
        } else if (filterleavetype[0]?.holiday === "No") {
          message.error(`You cannot apply for leave on Holidays`);
        } else if (filterleavetype[0]?.weekend === "No") {
          message.error(`You cannot apply for leave on Sundays`);
        } else {
          message.error(`Invalid payload. Please enter correct data`);
        }
      } else if (code === STATUS_CODE.RECORD_EXIST) {
        message.error(`Leave already exists`);
      } else if (code === STATUS_CODE.ANNUAL_LEAVE_AVAILABLE) {
        message.error(
          `You can not apply compassionate leave. You still have annual leaves.`
        );
      } else if (code === STATUS_CODE.SICK_LEAVE_AVAILABLE) {
        message.error(
          `You can not apply sick leave(half-pay). You still have sick leaves.`
        );
      } else if (code === STATUS_CODE.LEAVE_EXCEED) {
        form.setFieldsValue({
          number_of_days: totalNumberOfDaysInLeave,
        });
        displayErrorModal(
          `You are exceeding your leave quota. your current leave balance is ${leaveBalance}`
        );
      } else if (code === STATUS_CODE.SUCCESS) {
        if (saveLeave) {
          message.success(
            isCancelRequest
              ? "Leave application request has been submitted"
              : `Leave has been ${user_leave_id ? "updated" : "applied"}`
          );
          form.resetFields();
          closeModal(true);
          setShowHalfDayCheckbox(true);
          getLeaveList();
        } else {
          form.setFieldsValue({
            number_of_days: totalNumberOfDaysInLeave,
          });
        }
      }
    } else {
      message.error("kindly select the leave type");
      toggleLeaveValidationLoader(false);
    }
  };

  const restrictPastDate = (current, element) => {
    let customDate = moment("2022-08-01").format("YYYY-MM-DD");
    if (element === "end_date") {
      return current < moment(form.getFieldValue("start_date"));
    }
    return current && current < moment(customDate, "YYYY-MM-DD");
  };

  const validateDateRange = () => {
    const endDate = moment(form.getFieldValue("end_date"));
    const startDate = moment(form.getFieldValue("start_date"));
    if (endDate < startDate) {
      form.setFieldsValue({ end_date: startDate });
      onFinish({}, false);
    } else {
      onFinish({}, false);
    }
  };

  const dateChange = () => {
    onFinish({}, false);
    updateHalfDay();
  };

  const hideHalfDay = () => {
    const { start_date, end_date } = form.getFieldsValue();
    if (
      moment(end_date).format("dddd") == "Saturday" &&
      moment(start_date).format("dddd") == "Saturday" &&
      moment(start_date).format("L") == moment(end_date).format("L")
    ) {
      setShowHalfDayCheckbox(false);
    } else {
      setShowHalfDayCheckbox(true);
    }
  };

  const updateEndDate = () => {
    const { start_date, number_of_days } = form.getFieldsValue();
    if (number_of_days >= 1) {
      const roundedDays = Math.ceil(number_of_days);
      form.setFieldsValue({
        number_of_days: roundedDays,
        end_date: momentDate(addDaysinDate(start_date, roundedDays - 1)),
      });
    }
  };

  const checkBoxStyle = {
    marginTop: "55px",
    position: "relative",
    bottom: "15px",
  };

  const finduser = userList?.find((item) => item?.userid === userInfo?.userid);

  const filterrolelist = roleList?.filter((val) => val?.roleId === userRoleId);
  const filterdepartmentid = filterrolelist[0]?.department;
  const filterlocationid = filterrolelist[0]?.locationId;

  const filterleaves = [];
  leaveCategoryList?.forEach((item) => {
    if (item?.locationId?.length > 0) {
      if (intersection(item?.locationId, filterlocationid).length > 0) {
        filterleaves.push(item);
      }
    }
  });

  const filteruser = userList?.filter(
    (item) => item.userid === userInfo?.userid
  );
  const filtergender = filteruser[0]?.gender;
  const filteredCategoriesbygender =
    filtergender?.toLowerCase() === "male"
      ? filterleaves?.filter(
          (item) => item.eligible?.toLowerCase() !== "female"
        )
      : filterleaves?.filter((item) => item.eligible?.toLowerCase() !== "male");
  return (
    <StyledModal
      title="Incident Details"
      visible={isModalVisible}
      onCancel={() => {
        closeModal(false);
        resetHalfDay();
        resetForm();
      }}
      width={600}
      footer={[]}
    >
      <Form
        form={form}
        name="incidentform"
        layout="vertical"
        initialValues={{
          incidenttype: "",
          incidentlocation: "",
          date: momentDate(getKenyanDateTime()),
          time: "",
          description: "",
          remark: "",
        }}
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Incident Type"
              name="incidenttype"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Incident type is required",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Incident type"
                optionFilterProp="value"
                options={IncidentTypeOption}
                fieldNames={{
                  label: "value",
                  value: "id",
                }}
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="incidentlocation"
              label="Incident Location"
              rules={[
                {
                  required: true,
                  message: "Incident Location is required",
                },
              ]}
            >
              <Input type="text" placeholder="" maxLength={50} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Date is required",
                },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} ref={containerRef}>
            <Form.Item
              label="Time"
              name="time"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Time is required",
                },
              ]}
            >
              <TimePicker
                defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                getPopupContainer={() => containerRef.current}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} ref={containerRef}>
            <Form.Item
              className="mb-0 pb-0"
              label="Detailed Description"
              name="description"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Description is required",
                },
              ]}
            >
              <TextArea
                placeholder="Type something.."
                maxLength={250}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
            <span>
              <small className="small-text">Maximum 250 characters</small>
            </span>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item className="w-100 mt-2" label="Attach File" name="file">
              <Upload
                beforeUpload={() => false}
                showUploadList={false} // Hides uploaded file list
              >
                <div
                  style={{
                    width: "100%",
                    background: "#E8EDFD", // Light gray/blue background
                    padding: "12px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    border: "1px solid #D0D5DD", // Optional border
                  }}
                >
                  <UploadOutlined
                    style={{
                      fontSize: "16px",
                      color: "#4759C4",
                      marginRight: "8px",
                    }}
                  />
                  <span style={{ color: "#4759C4", fontWeight: "500" }}>
                    Upload File
                  </span>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <div className="text-end">
              <ButtonOutlined
                className=""
                type="primary"
                onClick={() => resetForm()}
              >
                Cancel
              </ButtonOutlined>
              <ButtonFilled
                className="m-0"
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                Submit
              </ButtonFilled>
            </div>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
}

const mapStateToProps = (state) => ({
  leaveCategoryList: getLeaveCategoryList(state),
  userInfo: getUserInfo(state),
  ClientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userList: getUserList(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  leaveList: getLeaveList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLeaveListData: setLeaveList,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(LeaveForm);
