import React, { useState, useEffect } from "react";
import { Form, Col, Switch, Row, Checkbox } from "antd";
import { connect } from "react-redux";
import {
  toggleProcessingModal,
  toggleSuccessModal,
  setAllDepartmentList,
  setRoleList,
  setMenuList,
} from "redux/actions";
import {
  getLocations,
  getClientid,
  getAllDepartmentList,
  getProductInfo,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import { CALL_API } from "common/API";
import { STATUS_CODE, VALIDATE_CHARACTER } from "common/Constants";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TabsList from "molecules/TabsList";
import EditSelectTable from "molecules/EditSelectTable";
import GridBuilder from "UIBuilderEngine/GridBuilder";
import RolesAndPermissions from "pages/RolesAndPermissions";
import { getEditRoleJson } from "JsonFormat/EditRoleJson";
import FormModal from "./FormModal";
import { ButtonFilled } from "reusableComponent/UIButtons";

function EditRole({
  isModalVisible,
  closeModal,
  getRoleList,
  RoleDetails,
  locationList,
  clientId,
  saveRoleList,
  AllDepartmentList,
  deleteRole,
  viewOnly,
  productInfo,
}) {
  const [form] = Form.useForm();

  const fetchMasterData = async (fetch_master_data) => {
    const { code, roleList } = await CALL_API(
      `master-data/${clientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveRoleList(roleList);
      }
    }
  };

  const [data, setData] = useState({
    roleName: RoleDetails?.roleName,
  });
  const { EditRoleForm } = getEditRoleJson(data, viewOnly);
  const [selectDepartmentId, setSelectDepartmentId] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [menuList, setMenuList] = useState(RoleDetails.menuList);
  const [openDepartmentModal, setOpenDepartmentModal] = useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [submit, setsubmit] = useState(false);
  const [selectAllDepartmentsChecked, setSelectAllDepartmentsChecked] =
    useState(false);
  const [selectAllLocationChecked, setSelectAllLocationChecked] =
    useState(false);

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setMenuList(RoleDetails.menuList);
    setSelectedLocationId(RoleDetails.locationId);
    setSelectDepartmentId(RoleDetails.department);
  }, [RoleDetails]);

  useEffect(() => {
    const {
      roleName = "",
      description = "",
      department = [],
      locationId = [],
    } = RoleDetails;
    form.setFieldsValue({
      role_name: roleName,
      description: description,
      department: department,
      location: locationId,
    });
    setSelectAllDepartmentsChecked(
      selectDepartmentId?.length === AllDepartmentList?.length
    );
    setSelectAllLocationChecked(
      selectedLocationId?.length === locationList?.length
    );
  }, [
    RoleDetails,
    selectDepartmentId,
    selectedLocationId,
    AllDepartmentList,
    locationList,
  ]);
  console.log("Rolesdetails------->>>", RoleDetails);
  const departmentcolumns = [
    {
      title: "Department Name",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id) => {
        const check = selectDepartmentId?.includes(department_id)
          ? true
          : false;
        return (
          <Switch
            disabled={viewOnly}
            checked={check}
            size="small"
            onChange={(checked) => getDepartmentId(department_id, checked)}
          />
        );
      },
    },
  ];

  const getDepartmentId = (department_id, checked) => {
    const newDepartmentId = checked
      ? [...selectDepartmentId, department_id]
      : selectDepartmentId.filter((item) => item !== department_id);
    setSelectDepartmentId(newDepartmentId);
    setsubmit(true);
  };

  const handleSelectAllDepartmentsChange = (e) => {
    const checked = e.target.checked;
    setSelectAllDepartmentsChecked(checked);
    const allDepartmentIds = AllDepartmentList.map(
      (item) => item.department_id
    );
    setSelectDepartmentId(checked ? allDepartmentIds : []);
    setsubmit(true);
  };

  const locatincolumns = [
    {
      title: "Location Name",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        const check = selectedLocationId?.includes(location_id) ? true : false;
        return (
          <Switch
            disabled={viewOnly}
            size="small"
            checked={check}
            onChange={(checked) => getLocationId(location_id, checked)}
          />
        );
      },
    },
  ];

  const getLocationId = (location_id, checked) => {
    const newLocationId = checked
      ? [...selectedLocationId, location_id]
      : selectedLocationId.filter((item) => item !== location_id);
    setSelectedLocationId(newLocationId);
    setsubmit(true);
  };

  const handleSelectAllLocationChange = (e) => {
    const checked = e.target.checked;
    setSelectAllLocationChecked(checked);
    const allDepaetmentIds = locationList.map((item) => item.location_id);
    setSelectedLocationId(checked ? allDepaetmentIds : []);
    setsubmit(true);
  };

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setsubmit(true);
  };

  const onFinish = async () => {
    setLoader(true);
    const { roleName } = data;
    const { code } = await CALL_API(
      `owner-roles/${clientId}/${RoleDetails.roleId}`,
      "patch",
      {
        rolename: roleName,
        department: selectDepartmentId,
        location: RoleDetails?.locationId,
        menuList: menuList,
        rolestatus: RoleDetails?.roleStatus,
        statusId: RoleDetails?.statusId,
      }
    );
    if (STATUS_CODE.SUCCESS === code) {
      fetchMasterData(true);
      getRoleList();
      closeModal();
    }
    setLoader(false);
  };

  useEffect(() => {
    if (submit) {
      onFinish();
    }
  }, [
    RoleDetails,
    selectedLocationId,
    selectDepartmentId,
    menuList,
    data,
    submit,
  ]);

  // const items = [
  //   // {
  //   //   id: 1,
  //   //   label: "Basic",
  //   //   component: (
  //   //     <>
  //   //       {viewOnly ? (
  //   //         <>
  //   //           <Row className="styled-card">
  //   //             <Col>
  //   //               <b>Role Name</b> : {data.rolename}
  //   //             </Col>
  //   //           </Row>
  //   //         </>
  //   //       ) : (
  //   //         <div className="styled-card">
  //   //           <GridBuilder
  //   //             formStructureJson={EditRoleForm}
  //   //             handleChange={handleFormChange}
  //   //             initialValues={RoleDetails}
  //   //           />
  //   //         </div>
  //   //       )}
  //   //     </>
  //   //   ),
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: "Department",
  //   //   component: (
  //   //     <>
  //   //       {!viewOnly && (
  //   //         <div className="mb-3 d-flex justify-content-between">
  //   //           {AllDepartmentList?.length > 0 && (
  //   //             <Checkbox
  //   //               className=""
  //   //               checked={selectAllDepartmentsChecked}
  //   //               onChange={handleSelectAllDepartmentsChange}
  //   //             >
  //   //               Select All
  //   //             </Checkbox>
  //   //           )}
  //   //         </div>
  //   //       )}
  //   //       <EditSelectTable
  //   //         columns={departmentcolumns}
  //   //         tabledata={AllDepartmentList}
  //   //         setOpenModal={() => setOpenDepartmentModal(true)}
  //   //         Name="Add Department"
  //   //       />
  //   //       <FormModal
  //   //         isModalVisible={openDepartmentModal}
  //   //         closeModal={() => setOpenDepartmentModal(false)}
  //   //         Name="Department"
  //   //       />
  //   //     </>
  //   //   ),
  //   // },
  //   // {
  //   //   id: 3,
  //   //   label: "Location",
  //   //   component: (
  //   //     <>
  //   //       {!viewOnly && (
  //   //         <div className="mb-3 d-flex justify-content-between">
  //   //           {locationList?.length > 0 && (
  //   //             <Checkbox
  //   //               className=""
  //   //               checked={selectAllLocationChecked}
  //   //               onChange={handleSelectAllLocationChange}
  //   //             >
  //   //               Select All
  //   //             </Checkbox>
  //   //           )}
  //   //         </div>
  //   //       )}

  //   //       <EditSelectTable
  //   //         columns={locatincolumns}
  //   //         tabledata={locationList}
  //   //         setOpenModal={() => setOpenLocationModal(true)}
  //   //         Name="Add Location"
  //   //       />
  //   //       <FormModal
  //   //         isModalVisible={openLocationModal}
  //   //         closeModal={() => setOpenLocationModal(false)}
  //   //         Name="Location"
  //   //       />
  //   //     </>
  //   //   ),
  //   // },
  //   {
  //     id: 4,
  //     label: "Permission",
  //     component: (
  //       <RolesAndPermissions
  //         setMenuList={setMenuList}
  //         menuList={menuList}
  //         viewOnly={viewOnly}
  //         setsubmit={setsubmit}
  //         selectedRoleId={RoleDetails?.roleId}
  //       />
  //     ),
  //   },
  // ];

  return (
    <>
      {isModalVisible && (
        <>
          <label
            className="bg-transparent border-0 mb-3 text-left styled-cursor"
            onClick={() => {
              closeModal(false);
            }}
          >
            Permission <ArrowForwardIosIcon className="px-1" />
          </label>{" "}
          <b className=" pb-0 pt-1">{data?.roleName}</b>
          {/* <TabsList items={items} /> */}
          <RolesAndPermissions
            setMenuList={setMenuList}
            menuList={menuList}
            viewOnly={viewOnly}
            setsubmit={setsubmit}
            selectedRoleId={RoleDetails?.roleId}
            paydisabled={!productInfo?.tymeplusPayStatus}
          />
          <div className="w-100 text-end">
            <ButtonFilled loading={loader} onClick={() => setsubmit(true)}>
              Save
            </ButtonFilled>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  locationList: getLocations(state),
  clientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  productInfo: getProductInfo(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProcessingModal: toggleProcessingModal,
      updateSuccessModal: toggleSuccessModal,
      saveAllDepartmentList: setAllDepartmentList,
      saveRoleList: setRoleList,
      saveMenuList: setMenuList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(EditRole);
