import React from "react";
import { Button, Input, Select, Table } from "antd";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
const Styleddiv = styled.div`
  .header-disciplinary {
    display: flex;
    justify-content: space-between;
  }
  .header-text {
    font-size: 20px;
    font-weight: 500;
  }
  .create-disciplinary {
    width: 170px;
    height: 40px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
  }
  .total-category {
    font-size: 16px;
    font-weight: 400;
  }
  .list-view {
    border: none;
    color: #2d53da;
    font-size: 16px;
    font-weight: 400;
  }
  .all-field-style {
    display: flex;
    gap: 10px;
  }
  .searchbar {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .searchicon {
    color: #636980;
  }
  .date-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
  .date-dropdown:hover .ant-select-selector {
    border-color: #e2e2e8 !important;
  }
  .date-dropdown.ant-select-focused .ant-select-selector {
    border-color: #e2e2e8 !important;
  }
  .action-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
  }
`;

const CategoryView = ({ onBack }) => {
  const history = useHistory();

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "No. of Employees",
      dataIndex: "no_of_employees",
      key: "no_of_employees",
    },
  ];

  const data = [
    {
      key: "1",
      category: "Criminal Offense",
      no_of_employees: "2",
    },
    {
      key: "2",
      category: "Criminal Offense",
      no_of_employees: "4",
    },
    {
      key: "3",
      category: "Criminal Offense",
      no_of_employees: "2",
    },
  ];
  return (
    <>
      <Styleddiv>
        <div>
          <div className="header-disciplinary">
            <div className="header-text">Disciplinary Management</div>
            <div>
              <Button
                type="primary"
                //   onClick={handleCreateDisciplinary}
                className="create-disciplinary"
              >
                Create Disciplinary
              </Button>
            </div>
          </div>
          <br />
          <div className="header-disciplinary">
            <div className="total-category">Total Category: 3</div>
            <Button
              className="list-view"
              onClick={() => {
                onBack(); // Navigate to the disciplinary page
              }}
            >
              List View
            </Button>
          </div>
          <br />
          <div className="all-field-style">
            <div>
              <Input
                className="searchbar"
                placeholder="Search"
                prefix={<SearchOutlined className="searchicon" />}
              />
            </div>
            <div>
              <Select placeholder="Date" className="date-dropdown">
                <Select.Option value="week">Last Week</Select.Option>
                <Select.Option value="month">Last Month</Select.Option>
                <Select.Option value="6months">Last 6 months</Select.Option>
                <Select.Option value="date">Custom Date range</Select.Option>
              </Select>
            </div>
            <div>
              <Select placeholder="Action" className="action-dropdown">
                <Select.Option value="verbal">Verbal Warning</Select.Option>
                <Select.Option value="first">First Warning</Select.Option>
                <Select.Option value="second">Second Warning</Select.Option>
                <Select.Option value="third">
                  Third & Final Warning
                </Select.Option>
                <Select.Option value="show">Show Cause</Select.Option>
                <Select.Option value="suspend">Suspend</Select.Option>
                <Select.Option value="demotion">Demotion</Select.Option>
                <Select.Option value="exoneration">Exoneration</Select.Option>
                <Select.Option value="clearance">Clearance</Select.Option>
                <Select.Option value="termination">Termination</Select.Option>
              </Select>
            </div>
          </div>
          <br />
          <div>
            <Table columns={columns} dataSource={data} />
          </div>
        </div>
      </Styleddiv>
    </>
  );
};

export default CategoryView;
