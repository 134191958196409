import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Button,
  Select,
  Upload,
} from "antd";
import styled from "@emotion/styled";
import { NON_EMPTY_REGEX, STATUS_CODE, VALIDATE_SYMBOL } from "common/Constants";
import { CALL_API } from "common/API";
import axios from 'axios';
import { useFetch } from "hooks/useFetch";
import { getClientid } from "redux/selectors";
import { connect } from "react-redux";

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    background: #f2f2f2 0% 0% no-repeat padding-box;
  }
  .ant-modal-title {
    font-weight: 500;
    font-size: 15px;
  }

  .ant-modal-footer {
    display: none;
  }
  .ant-form-item-label {
    font-weight: 500;
  }
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
  .ant-input {
    border-radius: 5px;
  }
  .ant-upload-select-picture-card {
    width: 100% !important;
    height: 40px !important;
  }
  .ant-upload-list-item-actions > a {
    display: none;
  }
`;

const StyledFooterDiv = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
`;

const { TextArea } = Input;
const { Option } = Select;

const AddAsset = ({
  isModalVisible,
  closeModal,
  isViewOnly,
  getAssetsData,
  assetsData,
  clientId
}) => {
  const [form] = Form.useForm();
  const [showLoader, toggleLoader] = useState(false);
  const [fileList, setFileList] = useState([]);

  const CategoryList = ["Laptop", "Monitor", "Hard Disk"];

  const onFinish = async (values) => {
    const id = generateRandom6DigitNumber();
    await postAssetData(id, values);
  };

  const handleUploadChange = ({ fileList }) => setFileList(fileList);

  const generateRandom6DigitNumber = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const postAssetData = async (id, values) => {
    // const name = ;
    // const category = ;
    // const description = form.getFieldValue("description");
    toggleLoader(true);
    const {code} = await CALL_API(`/assets`, "post", {
      assetName: values.name,
      category: values.category,
      description: values.description,
      assetStatus: "Available",
      assignedTo: "",
      assignedDate: "",
      imageName: fileList[0]?.name,
      uniqueId: id,
      image: {
        imageName: fileList[0]?.name,
        uniqueId: `${id}_${fileList[0]?.name}`,
      },
    });
    console.log("coede-->",code)
    if (code === "SUCCESS") {
      console.log("coede-->",code)
      postAssetFile(id);
    } else {
      message.error("Failed to add Asset");
    }
  };

  const postAssetFile = async (id) => {
    const {code} = await CALL_API(`upload-document/${clientId}`, "post", {
      name: fileList[0]?.name,
      type: "image/jpeg",
      content: fileList[0]?.thumbUrl,
      identity: "assets",
      uniqueId: id,
    });
    if (code === "SUCCESS") {
      getAssetsData();
      toggleLoader(false);
      closeModal(false);
      form.resetFields();
      setFileList([]);
      message.success("Asset updated successfully");
    } else {
      message.error("Failed to upload Asset image");
    }
  };


  return (
    <StyledModal
      title={"Add New Asset"}
      visible={isModalVisible}
      onCancel={() => {
        closeModal(false);
        form.resetFields();
        setFileList([]);
      }}
      width={500}
      footer={[]}
    >
      <Form
        form={form}
        name="AddLocation"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={20}>
          <Col xs={24} sm={24} md={24} xl={24}>
            <Form.Item
              label="Asset Name:"
              name="name"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Asset Name is required",
                },
                {
                  required: false,
                  pattern: VALIDATE_SYMBOL,
                  message: "Asset Name should not contain special characters",
                },
                {
                  validator: (_, value) => {
                    if (assetsData.some((asset) => asset.assetName === value)) {
                      return Promise.reject(
                        new Error("Asset Name already exists")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder="Enter" disabled={isViewOnly} maxLength={50} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24}>
            <Form.Item
              label="Category:"
              name="category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select placeholder="Enter" disabled={isViewOnly}>
                {CategoryList?.map((data) => (
                  <Option key={data} value={data}>
                    {data}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} xl={24}>
            <Form.Item
              label="Description:"
              name="description"
              rules={[
                {
                  required: true,
                  pattern: NON_EMPTY_REGEX,
                  message: "Asset description is required",
                },
              ]}
            >
              <TextArea
                placeholder="Enter"
                maxLength={500}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24} xl={24}>
            <Form.Item
              label="Image:"
              name="image"
              rules={[
                {
                  validator: (_, value) =>
                    fileList.length > 0
                      ? Promise.resolve()
                      : Promise.reject(new Error("Please upload an image")),
                },
              ]}
            >
              <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleUploadChange}
                beforeUpload={() => false}
                accept=".jpg,.jpeg,.png"
              >
                {fileList.length >= 1 ? null : (
                  <div>
                    <div>Attach File</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <StyledFooterDiv>
          <StyledButton loading={showLoader} type="primary" htmlType="submit">
            Add
          </StyledButton>
        </StyledFooterDiv>
      </Form>
    </StyledModal>
  );
};
const mapStateToProps = (state) => ({
  clientId: getClientid(state),
});

export default connect(mapStateToProps) (AddAsset);
