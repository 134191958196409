import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tabs,
  Upload,
  message,
  Switch,
} from "antd";
import {
  // CompetencyQC,
  // CompetencyRole,
  // Grade,
  // Industry,
  // IndustryRole,
  // SUPERVISOR,
  NON_EMPTY_REGEX,
  STATUS_CODE,
  // Sector,
  // ServiceLine,
  // SubServiceLine,
  countryList,
  AwardList,
  CountryData,
} from "common/Constants";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import styled from "@emotion/styled";
import {
  getBandList,
  getClientid,
  getLocations,
  getRoleList,
  getUserList,
  getAllDepartmentList,
  getUserInfo,
} from "redux/selectors";
import { connect } from "react-redux";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { CALL_API } from "common/API";
import { setRoleList, setUserList } from "redux/actions";
import { bindActionCreators } from "redux";
import { chain, flatMap, isEmpty, filter, find } from "lodash";
import TabPane from "antd/lib/tabs/TabPane";
import FormModal from "./FormModal";
import SelectTable from "../molecules/EditEmployeeRole";
import COLORS from "common/Colors";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import queryKeys from "common/queryKey";
import { useFetch } from "hooks/useFetch";

const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    /* .anticon svg {
      color: #397FF4;
  } */
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;
const EditProfileBox = styled.div`
  .ant-tabs-nav {
    padding-left: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: ${COLORS.TEXT.PRIMARY};
  }
  .ant-tabs-tab {
    font-size: 16px;
    &:hover {
      color: ${COLORS.TEXT.PRIMARY};
    }
  }
  .ant-tabs-ink-bar {
    background: ${COLORS.TAB};
    font-weight: bold;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.TAB};
    font-weight: 500;
    font-size: 16px;
  }
`;

function generateUniqueId() {
  // Generate a random number and convert it to base 36 (numbers + letters)
  const randomNumber = Math.random().toString(36).substr(2, 9);

  // Get the current timestamp
  const timestamp = Date.now();

  // Combine the random number with the timestamp
  const uniqueId = randomNumber + timestamp;

  return uniqueId;
}

function EditProfile({
  updateState,
  setUpdateState,
  EmployeeDetails,
  userList,
  roleList,
  ClientId,
  bandList,
  updateUserList,
  locationList,
  pageName,
  allDepartmentList,
  userInfo,
  saveRoleList,
}) {
  const containerRef = useRef(null);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});

  const [showLoader, toggleLoader] = useState(false);
  const [employeeStatusId, setEmployeeStatusId] = useState("1");

  const employeesList = userList?.map((user) => user.userid);
  const [activeTab, setActiveTab] = useState("0");
  const [selectedRoleId, setSelectedRoleId] = useState([]);
  const [baseLocation, setBaseLocation] = useState("");
  const [baseDepartment, setBaseDepartment] = useState("");
  const [selectAllRoleChecked, setSelectAllRoleChecked] = useState(false);

  const getMaxLengthByCountry = (countryName) => {
    const country = CountryData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    return country ? country.phone_length : null;
  };

  useEffect(() => {
    const {
      userid = "",
      startdate = "",
      dateofbirth = "",
      enddate = "",
      bandname = "",
      bandid = "",
      overtimestatus = "",
      salary = "",
      password = "",
      profileurl = "",
      gender = "",
      maritalstatus = "",
      nationality = "",
      roleid = "",
      nssf = "",
      national_id = "",
      address = "",
      phoneno = "",
      companyname = "",
      jobtitle = "",
      professionalsince = "",
      hireddate = "",
      probationstartdate = "",
      probationenddate = "",
      contractstartdate = "",
      contractenddate = "",
      relevant = "",
      employeesponser = "",
      statusname = "",
      jobdescription = "",
      emergencycontactname = "",
      emergencycontactnumber = "",
      employementtype = "",
      employeeid = "",
      initial,
      firstname,
      lastname,
      salutation,
      // competencyrole,
      // industry,
      // sector,
      // industryrole,
      // grade,
      educationdetails,
      workexperience,
      professionalcertifications,
      practicaltrainings,
      lastpromotionlevel,
      lastpromotiondate,
      reportingmanager,
      // supervisor,
    } = EmployeeDetails;

    setFormValues({
      user_id: userid,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      password: password,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandName: bandname,
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      employementtype: employementtype,
      profile_url: profileurl !== ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      phoneno: phoneno,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      employementtype: employementtype ? employementtype : "",
      probationstartdate: probationstartdate
        ? moment(probationstartdate, "YYYY-MM-DD")
        : null,
      probationenddate: probationenddate
        ? moment(probationenddate, "YYYY-MM-DD")
        : null,
      contractstartdate: contractstartdate
        ? moment(contractstartdate, "YYYY-MM-DD")
        : null,
      contractenddate: contractenddate
        ? moment(contractenddate, "YYYY-MM-DD")
        : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      status_id: employeeStatusId,
      description: jobdescription,
      emergencycontactname: emergencycontactname,
      emergencycontactnumber: emergencycontactnumber,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      lastpromotionlevel: lastpromotionlevel,
      reportingmanager: reportingmanager,
      lastpromotiondate: lastpromotiondate
        ? moment(startdate, "YYYY-MM-DD")
        : null,
      // competencyrole: competencyrole,
      // industry: industry,
      // sector: sector,
      // industryrole: industryrole,
      educationdetails:
        educationdetails?.length !== 0
          ? educationdetails
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? workexperience
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? professionalcertifications
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings.length !== 0
          ? practicaltrainings
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
    });
    const transformedDetails = (data) =>
      data?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate);
        const formattedToDate = moment(todate);
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
    form.setFieldsValue({
      user_id: userid,
      password: password,
      gender: gender,
      dateof_birth: dateofbirth ? moment(dateofbirth, "YYYY-MM-DD") : null,
      maritalstatus: maritalstatus,
      nationality: nationality,
      start_date: startdate ? moment(startdate, "YYYY-MM-DD") : null,
      end_date: enddate ? moment(enddate) : "",
      bandid: bandid,
      overtime: overtimestatus,
      salary: salary,
      profile_url: profileurl != ("" || null || undefined) ? profileurl : null,
      role_id: roleid,
      nssf: nssf,
      national_id: national_id,
      address: address,
      employementtype: employementtype,
      phoneno: phoneno,
      companyname: companyname,
      jobtitle: jobtitle,
      professionalsince: professionalsince
        ? moment(professionalsince, "YYYY-MM-DD")
        : null,
      hireddate: hireddate ? moment(hireddate, "YYYY-MM-DD") : null,
      probationstartdate: probationstartdate
        ? moment(probationstartdate, "YYYY-MM-DD")
        : null,
      probationenddate: probationenddate
        ? moment(probationenddate, "YYYY-MM-DD")
        : null,
      contractstartdate: contractstartdate
        ? moment(contractstartdate, "YYYY-MM-DD")
        : null,
      contractenddate: contractenddate
        ? moment(contractenddate, "YYYY-MM-DD")
        : null,
      relevent: relevant,
      employeesponser: employeesponser,
      employeestatus: statusname,
      description: jobdescription,
      emergencycontactnumber: emergencycontactnumber,
      emergencycontactname: emergencycontactname,
      employeeid: employeeid,
      initial: initial,
      firstname: firstname,
      lastname: lastname,
      salutation: salutation,
      lastpromotionlevel: lastpromotionlevel,
      lastpromotiondate: lastpromotiondate
        ? moment(startdate, "YYYY-MM-DD")
        : null,
      reportingmanager: reportingmanager,
      educationdetails:
        educationdetails?.length !== 0
          ? transformedDetails(educationdetails)
          : [
              {
                institutename: "",
                fromdate: "",
                todate: "",
                exambody: "",
                awarded: "",
              },
            ],
      workexperience:
        workexperience?.length !== 0
          ? transformedDetails(workexperience)
          : [
              {
                companyname: "",
                fromdate: "",
                todate: "",
                jobtitle: "",
                relevnt: "",
                jobdescription: "",
              },
            ],
      professionalcertifications:
        professionalcertifications?.length !== 0
          ? transformedDetails(professionalcertifications)
          : [
              {
                certificationtype: "",
                fromdate: "",
                todate: "",
                remarks: "",
              },
            ],
      practicaltrainings:
        practicaltrainings.length !== 0
          ? transformedDetails(practicaltrainings)
          : [
              {
                organisationname: "",
                fromdate: "",
                todate: "",
                positionheld: "",
                tasksperformed: "",
              },
            ],
    });
  }, [EmployeeDetails]);

  const getCountryCodeByName = (countryName) => {
    const country = CountryData.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    return country ? country.code : null;
  };

  const validateEmployeeId = (rule, value) => {
    if (EmployeeDetails?.userid === value) {
      return Promise.resolve();
    } else if (value && employeesList.includes(value)) {
      return Promise.reject("User ID already exist");
    }
    return Promise.resolve();
  };

  const onFinish = () => {
    const currentFormValues = form.getFieldValue();

    const updatedFormValues = {
      ...currentFormValues,
      selectedRoleId,
    };

    setFormValues(updatedFormValues);
  };

  const uploadFile = async (value) => {
    const { file } = value;
    try {
      if (!isEmpty(file)) {
        await CALL_API(`upload-userdocuments/${ClientId}`, "post", {
          userId: EmployeeDetails?.userid,
          name: file.name,
          type: file.type,
          uniqueId: file.response.uniqueId,
          content: file.response.base64Data,
        });
      }
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  const fetchMasterData = async (fetch_master_data) => {
    const { code, userList, roleList } = await CALL_API(
      `master-data/${ClientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      updateUserList(userList);
      saveRoleList(roleList);
    }
  };

  const { getPermissionById } = queryKeys;

  const { key, url } = getPermissionById(ClientId, EmployeeDetails?.roleid[0]);

  const { data } = useFetch(key, url);

  const permissionList = data?.response?.data[0]?.menuList;

  const handlepremissionsubmit = async () => {
    // setLoader(true);
    const { code } = await CALL_API(
      `owner-roles/${ClientId}/${EmployeeDetails?.roleid[0]}`,
      "patch",
      {
        rolename: `${formValues?.firstname} ${formValues?.lastname}`,
        // department: selectDepartmentId,
        location: [baseLocation],
        menuList: permissionList,
        rolestatus: "Enabled",
        department: [EmployeeDetails?.basedepartmentid],
        statusId: formValues?.status_id,
      }
    );
    if (STATUS_CODE.SUCCESS === code) {
      getRoleList();
    }
  };

  const handlesubmit = async () => {
    try {
      toggleLoader(true);
      handlepremissionsubmit();
      let userId = form.getFieldValue("user_id");
      let splitUserId = userId.split("@");
      let userIdWithoutDomain = splitUserId[0];
      const {
        user_id,
        start_date,
        password,
        gender,
        dateof_birth,
        nationality,
        maritalstatus,
        overtime,
        salary,
        nssf,
        national_id,
        address,
        phoneno,
        companyname,
        jobtitle,
        professionalsince,
        hireddate,
        relevent,
        employeesponser,
        employeestatus,
        description,
        emergencycontactname,
        emergencycontactnumber,
        employeeid,
        initial,
        firstname,
        lastname,
        salutation,
        lastpromotionlevel,
        lastpromotiondate,
        //   competencyrole,
        //   industry,
        //   sector,
        //   industryrole,
        //   grade,
        educationdetails,
        workexperience,
        professionalcertifications,
        employementtype,
        probationenddate,
        probationstartdate,
        contractstartdate,
        contractenddate,
        practicaltrainings,
        reportingmanager,
        //   supervisor,
      } = formValues;

      const workexperienceDetails = workexperience?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
        const formattedToDate = moment(todate).format("YYYY-MM-DD");
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const transformedEducationDetails = educationdetails?.map((item) => {
        const { attachment, fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
        const formattedToDate = moment(todate).format("YYYY-MM-DD");
        const updatedAttachment = attachment?.map((att) => ({
          name: att?.name,
          uniqueId: att?.response?.uniqueId
            ? att?.response?.uniqueId
            : att?.uniqueId,
        }));
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
          attachment: updatedAttachment,
        };
      });

      const practicaltrainingsDetails = practicaltrainings?.map((item) => {
        const { fromdate, todate, ...rest } = item;
        const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
        const formattedToDate = moment(todate).format("YYYY-MM-DD");
        return {
          ...rest,
          fromdate: formattedFromDate,
          todate: formattedToDate,
        };
      });
      const professionalcertificationsDetails = professionalcertifications?.map(
        (item) => {
          const { attachment, fromdate, todate, ...rest } = item;
          const formattedFromDate = moment(fromdate).format("YYYY-MM-DD");
          const formattedToDate = moment(todate).format("YYYY-MM-DD");
          const updatedAttachment = attachment?.map((att) => ({
            name: att?.name,
            uniqueId: att?.response?.uniqueId
              ? att?.response?.uniqueId
              : att?.uniqueId,
          }));
          return {
            ...rest,
            fromdate: formattedFromDate,
            todate: formattedToDate,
            attachment: updatedAttachment,
          };
        }
      );

      const roleNames = roleList
        .filter((item) => selectedRoleId?.includes(item.roleId))
        .map((item) => item.roleName);

      const dobDate = new Date(dateof_birth);
      const currentDate = new Date();
      const agecalculte = currentDate.getFullYear() - dobDate.getFullYear();

      const response = await CALL_API(`add-users/${ClientId}`, "patch", {
        user_id: user_id?.toLowerCase(),
        full_name: `${firstname} ${lastname}`,
        end_date: formValues?.start_date
          ? moment(formValues?.start_date?._d).format("YYYY-MM-DD")
          : "",
        start_date: start_date
          ? moment(start_date?._d).format("YYYY-MM-DD")
          : "",
        overtime_status: overtime,
        bandname: EmployeeDetails?.bandname,
        bandid: EmployeeDetails?.bandid,
        gender: gender,
        dateofbirth: dateof_birth
          ? moment(dateof_birth?._d).format("YYYY-MM-DD")
          : "",
        age: agecalculte,
        password: password,
        maritalstatus: maritalstatus,
        nationality: nationality,
        salary: salary,
        role_id: selectedRoleId,
        role_name: roleNames ? roleNames : "",
        nssf: nssf,
        national_id: national_id,
        phoneno: phoneno,
        address: address,
        permanentaddress: address,
        presentaddress: address,
        employementtype: employementtype,
        profile_url: EmployeeDetails?.profileurl,
        companyname: companyname,
        jobtitle: jobtitle,
        professionalsince: professionalsince
          ? moment(professionalsince?._d).format("YYYY-MM-DD")
          : "",
        hireddate: hireddate ? moment(hireddate?._d).format("YYYY-MM-DD") : "",
        employementtype: employementtype,
        probationstartdate: probationstartdate
          ? moment(probationstartdate?._d, "YYYY-MM-DD")
          : "",
        probationenddate: probationenddate
          ? moment(probationenddate?._d, "YYYY-MM-DD")
          : "",
        contractstartdate: contractstartdate
          ? moment(contractstartdate?._d, "YYYY-MM-DD")
          : "",
        contractenddate: contractenddate
          ? moment(contractenddate?._d, "YYYY-MM-DD")
          : "",
        relevant: relevent,
        employeesponser: employeesponser,
        statusname: employeestatus,
        status_id: employeeStatusId,
        jobdescription: description,
        emergencycontactname: emergencycontactname,
        emergencycontactnumber: emergencycontactnumber,
        baselocationid: baseLocation,
        basedepartmentid: baseDepartment,
        employeeid: employeeid,
        initial: initial,
        firstname: firstname,
        lastname: lastname,
        salutation: salutation,
        lastpromotionlevel: lastpromotionlevel,
        lastpromotiondate: lastpromotiondate ? moment(lastpromotiondate) : "",
        //   competencyrole: competencyrole,
        //   industry: industry,
        //   sector: sector,
        //   industryrole: industryrole,
        //   grade: grade,
        reportingmanager: reportingmanager,
        educationdetails:
          educationdetails[0]?.institutename !== ""
            ? transformedEducationDetails
            : [],
        workexperience:
          workexperience[0]?.companyname !== "" ? workexperienceDetails : [],
        professionalcertifications:
          professionalcertifications[0]?.certificationtype !== ""
            ? professionalcertificationsDetails
            : [],
        practicaltrainings:
          practicaltrainings[0]?.organisationname !== ""
            ? practicaltrainingsDetails
            : [],
        //   supervisor: supervisor,
      });

      toggleLoader(false);
      //   setFileName("");
      form.resetFields();
      //   closeModal(true);

      if (response.code === "SUCCESS") {
        if (response?.message === "Please Upgrade Your Plan") {
          message.error(response?.message);
        } else {
          message.success(response?.message);
          setUpdateState("");
        }
      }
      setUpdateState("");
      //   await getEmployeeList();
      await fetchMasterData(true);
    } catch (error) {
      console.error("[handlesubmit] error --->", error);
    }
  };

  // const [managingOfficeData, setManagingOfficeData] = useState([]);
  // const [organisationLevelData, setOrganisationLevelData] = useState([]);
  // const [competencyQCData, setCompetencyQCfetchData] = useState([]);
  // const [servicelineData, setServicelinefetchData] = useState([]);
  // const [subservicelineData, setsubservicelineData] = useState([]);
  // const [competencyroleData, setcompetencyroleData] = useState([]);

  // const fetchData = async () => {
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level1`, "get");
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response?.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setManagingOfficeData(result);
  //   }
  // };

  // const organisationLevelfetchData1= async () => {
  //   const filtervalue = managingOfficeData.filter((item)=>form.getFieldValue('managingoffice').includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setOrganisationLevelData(result);
  //   }
  // };

  // const competencyQCfetchData1 = async () => {
  //   const filtervalue = organisationLevelData.filter((item)=>form.getFieldValue('organisationlevel').includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setCompetencyQCfetchData(result);
  //   }
  // };

  // const servicelinefetchData1 = async () => {
  //   const filtervalue = competencyQCData.filter((item)=>form.getFieldValue('competencyQC')?.includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setServicelinefetchData(result);
  //   }
  // };

  // const subservicelinefetchData1 = async () => {
  //   const filtervalue = servicelineData?.filter((item)=>form.getFieldValue('serviceline')?.includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setsubservicelineData(result);
  //   }
  // };
  // const competencyrolefetchData1 = async () => {
  //   const filtervalue = subservicelineData.filter((item)=>form.getFieldValue('subserviceline')?.includes(item.value));
  //   const mapdata = filtervalue.map((item)=>item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level6&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response))  {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value })));
  //     setcompetencyroleData(result);
  //   }
  // };

  // const organisationLevelfetchData = async () => {
  //   const filtervalue = managingOfficeData.filter((item) => form.getFieldValue('managingoffice').includes(item.value));
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level2&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value, parentLevel: item.parentLevel })));
  //     setOrganisationLevelData(result);
  //     if (isEmpty(result)) {
  //       form.setFieldValue('organisationlevel', []);
  //       form.setFieldValue('competencyQC', []);
  //       form.setFieldValue('serviceline', []);
  //       form.setFieldValue('subserviceline', []);
  //     //   form.setFieldValue('competencyrole', []);
  //     } else {
  //       // organisationlevel
  //       const organisationlevelList = filter(result, item => form.getFieldValue('managingoffice').includes(item.parentLevel));
  //       const organisationlevel = filter(form.getFieldValue('organisationlevel'), value => find(organisationlevelList, { 'value': value }));
  //       form.setFieldValue('organisationlevel', organisationlevel);
  //      // competencyQC

  //      const competencyQClevelList = filter(competencyQCData, item => form.getFieldValue('organisationlevel').includes(item.parentLevel))
  //      setCompetencyQCfetchData(competencyQClevelList)
  //      const competencyQClevel = filter(form.getFieldValue('competencyQC'), value => find(competencyQClevelList, { 'value': value }));
  //      form.setFieldValue('competencyQC', competencyQClevel);
  //      // serviceline
  //      const servicelinelevelList = filter(servicelineData, item => form.getFieldValue('competencyQC').includes(item.parentLevel))
  //      setServicelinefetchData(servicelinelevelList)
  //      const serviceline = filter(form.getFieldValue('serviceline'), value => find(servicelinelevelList, { 'value': value }));
  //      form.setFieldValue('serviceline', serviceline);
  //      // subserviceline
  //      const subservicelinelevelList = filter(subservicelineData, item => form.getFieldValue('serviceline').includes(item.parentLevel))
  //      setsubservicelineData(subservicelinelevelList)
  //      const subserviceline = filter(form.getFieldValue('subserviceline'), value => find(subservicelinelevelList, { 'value': value }));
  //      form.setFieldValue('subserviceline', subserviceline);
  //     //  // competencyrole
  //     //  const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //     //  setcompetencyroleData(competencyrolelevelList)
  //     //  const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //     //  form.setFieldValue('competencyrole', competencyrole);

  //     }
  //   }
  // };

  // const competencyQCfetchData = async () => {
  //   const filtervalue = organisationLevelData.filter((item) => form.getFieldValue('organisationlevel').includes(item.value));
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level3&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value, parentLevel: item.parentLevel })));
  //     setCompetencyQCfetchData(result)
  //     if (isEmpty(result)) {
  //       form.setFieldValue('competencyQC', []);
  //       form.setFieldValue('serviceline', []);
  //       form.setFieldValue('subserviceline', []);
  //     //   form.setFieldValue('competencyrole', []);
  //     } else {
  //       // competencyQC

  //       const competencyQClevelList = filter(result, item => form.getFieldValue('organisationlevel').includes(item.parentLevel))
  //       const competencyQClevel = filter(form.getFieldValue('competencyQC'), value => find(competencyQClevelList, { 'value': value }));
  //       form.setFieldValue('competencyQC', competencyQClevel);
  //       // serviceline
  //       const servicelinelevelList = filter(servicelineData, item => form.getFieldValue('competencyQC').includes(item.parentLevel))
  //       setServicelinefetchData(servicelinelevelList)
  //       const serviceline = filter(form.getFieldValue('serviceline'), value => find(servicelinelevelList, { 'value': value }));
  //       form.setFieldValue('serviceline', serviceline);
  //       // subserviceline
  //       const subservicelinelevelList = filter(subservicelineData, item => form.getFieldValue('serviceline').includes(item.parentLevel))
  //       setsubservicelineData(subservicelinelevelList)
  //       const subserviceline = filter(form.getFieldValue('subserviceline'), value => find(subservicelinelevelList, { 'value': value }));
  //       form.setFieldValue('subserviceline', subserviceline);
  //       // competencyrole
  //       // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //       // setcompetencyroleData(competencyrolelevelList)
  //       // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //       // form.setFieldValue('competencyrole', competencyrole);
  //     }
  //   }
  // };

  // const servicelinefetchData = async () => {
  //   const filtervalue = competencyQCData.filter((item) => form.getFieldValue('competencyQC')?.includes(item.value));
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level4&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value, parentLevel: item.parentLevel })));
  //     setServicelinefetchData(result)
  //     if (isEmpty(result)) {
  //       form.setFieldValue('serviceline', []);
  //       form.setFieldValue('subserviceline', []);
  //     //   form.setFieldValue('competencyrole', []);
  //     } else {
  //       // serviceline
  //       const servicelinelevelList = filter(result, item => form.getFieldValue('competencyQC').includes(item.parentLevel))
  //       const serviceline = filter(form.getFieldValue('serviceline'), value => find(servicelinelevelList, { 'value': value }));
  //       form.setFieldValue('serviceline', serviceline);
  //       // subserviceline
  //       const subservicelinelevelList = filter(subservicelineData, item => form.getFieldValue('serviceline').includes(item.parentLevel))
  //       setsubservicelineData(subservicelinelevelList)
  //       const subserviceline = filter(form.getFieldValue('subserviceline'), value => find(subservicelinelevelList, { 'value': value }));
  //       form.setFieldValue('subserviceline', subserviceline);
  //       // // competencyrole
  //       // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //       // setcompetencyroleData(competencyrolelevelList)
  //       // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //       // form.setFieldValue('competencyrole', competencyrole);
  //     }
  //   }
  // };

  // const subservicelinefetchData = async () => {
  //   const filtervalue = servicelineData?.filter((item) => form.getFieldValue('serviceline')?.includes(item.value));
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level5&parentLevel=[${mapdata}]`, "get");
  //   if (!isEmpty(response)) {
  //     const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value, parentLevel: item.parentLevel })));
  //     setsubservicelineData(result);
  //     if (isEmpty(result)) {
  //       form.setFieldValue('subserviceline', []);
  //     //   form.setFieldValue('competencyrole', []);
  //     } else {
  //       // subserviceline
  //       const subservicelinelevelList = filter(result, item => form.getFieldValue('serviceline').includes(item.parentLevel))
  //       const subserviceline = filter(form.getFieldValue('subserviceline'), value => find(subservicelinelevelList, { 'value': value }));

  //       form.setFieldValue('subserviceline', subserviceline);
  //       // // competencyrole
  //       // const competencyrolelevelList = filter(subservicelineData, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //       // setcompetencyroleData(competencyrolelevelList)
  //       // const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //       // form.setFieldValue('competencyrole', competencyrole);
  //     }
  //   }
  // };
  // const competencyrolefetchData = async () => {
  //   const filtervalue = subservicelineData.filter((item) => form.getFieldValue('subserviceline')?.includes(item.value));
  //   const mapdata = filtervalue.map((item) => item?.name);
  //   const response = await CALL_FEATCH_API(`insyts/level-data?levelName=Level6&parentLevel=[${mapdata}]`, "get");
  //   // if (!isEmpty(response)) {
  //   //   const result = flatMap(response.data, item => item.value.map(value => ({ name: item.name, value, parentLevel: item.parentLevel })));
  //   //   setcompetencyroleData(result)
  //   //   if (isEmpty(result)) {
  //   //     form.setFieldValue('competencyrole', []);
  //   //   } else {
  //   //     // competencyrole
  //   //     const competencyrolelevelList = filter(result, item => form.getFieldValue('subserviceline').includes(item.parentLevel))
  //   //     const competencyrole = filter(form.getFieldValue('competencyrole'), value => find(competencyrolelevelList, { 'value': value }));
  //   //     form.setFieldValue('competencyrole', competencyrole);
  //   //   }
  //   // }
  // };

  // useEffect(() => {
  //   if (updateState === "basic") {
  //     fetchData();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (organisationLevelData?.length == 0 && updateState === "basic") {
  //     organisationLevelfetchData1();
  //   }
  // }, [managingOfficeData]);

  // useEffect(() => {
  //   if (competencyQCData?.length == 0 && updateState === "basic") {
  //     competencyQCfetchData1();
  //   }
  // }, [organisationLevelData]);

  // useEffect(() => {
  //   if (servicelineData?.length == 0 && updateState === "basic") {
  //     servicelinefetchData1();
  //   }
  // }, [competencyQCData]);

  // useEffect(() => {
  //   if (subservicelineData?.length == 0 && updateState === "basic") {
  //     subservicelinefetchData1();
  //   }
  // }, [servicelineData]);

  // useEffect(() => {
  //   if (competencyroleData?.length == 0 && updateState === "basic") {
  //     competencyrolefetchData1();
  //   }
  // }, [subservicelineData]);

  const tabTitles = ["Basic", "Permission", "Location"];

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const rolecolumns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      width: "10%",
    },
    {
      title: "Access Level",
      dataIndex: "roleId",
      key: "roleId",
      width: "10%",
      align: "center",
      render: (roleId) => {
        const check = selectedRoleId?.includes(roleId) ? true : false;
        return (
          <Switch
            size="small"
            defaultChecked
            checked={check}
            onChange={(checked) => {
              getRoleId(roleId, checked);
            }}
          />
        );
      },
    },
  ];

  const baseLocationColumn = [
    {
      title: "Select Base Location",
      dataIndex: "location_name",
      key: "location_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "location_id",
      key: "location_id",
      width: "10%",
      align: "center",
      render: (location_id) => {
        return (
          <Switch
            size="small"
            checked={baseLocation === location_id}
            onChange={(checked) => {
              setBaseLocation(checked ? location_id : "");
              onFinish();
            }}
          />
        );
      },
    },
  ];

  const baseDepartmentColumn = [
    {
      title: "Select Base Department",
      dataIndex: "department_name",
      key: "department_name",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "department_id",
      key: "department_id",
      width: "10%",
      align: "center",
      render: (department_id) => {
        return (
          <Switch
            size="small"
            checked={baseDepartment === department_id}
            onChange={(checked) => {
              setBaseDepartment(checked ? department_id : "");
              onFinish();
            }}
          />
        );
      },
    },
  ];

  const getRoleId = (role_id, checked) => {
    const newRoleId = checked
      ? [...selectedRoleId, role_id]
      : selectedRoleId.filter((item) => item !== role_id);
    setSelectedRoleId(newRoleId);
    onFinish();
  };

  const handleSelectAllRole = (e) => {
    const checked = e.target.checked;
    setSelectAllRoleChecked(checked);
    const allRoleIds = roleList.map((item) => item.roleId);
    setSelectedRoleId(checked ? allRoleIds : []);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  const handleResetAllRole = (e) => {
    setSelectedRoleId([]);
    setFormValues((prev) => ({
      ...prev,
      selectedRoleId: selectedRoleId,
    }));
    onFinish();
  };

  useEffect(() => {
    setSelectedRoleId(EmployeeDetails.roleid);
    setBaseLocation(
      EmployeeDetails?.baselocationid ? EmployeeDetails?.baselocationid : ""
    );
    setBaseDepartment(
      EmployeeDetails?.basedepartmentid ? EmployeeDetails?.basedepartmentid : ""
    );
  }, [EmployeeDetails]);

  const locationId = chain(roleList)
    .filter((role) => selectedRoleId?.includes(role.roleId))
    .flatMap((role) => role.locationId)
    .uniq()
    .value();

  const locationBasedOnRole = locationList?.filter((val) =>
    locationId.includes(val?.location_id)
  );
  const filterLocationList = locationBasedOnRole?.filter(
    (item) => item?.login_from_anywhere !== 1
  );

  const departmentId = chain(roleList)
    .filter((role) => selectedRoleId.includes(role.roleId))
    .flatMap((role) => role.department)
    .uniq()
    .value();

  const departmentBasedOnRole = allDepartmentList?.filter((val) =>
    departmentId.includes(val?.department_id)
  );

  const iconsize = { fontSize: "14px" };

  const cursorstyle = { cursor: "pointer" };

  return (
    <EditProfileBox>
      {updateState === "personalinformation" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Personal Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeform"
            layout="vertical"
            initialValues={{
              user_id: "",
              department_id: "",
              gender: "",
              dateof_birth: "",
              maritalstatus: "",
              nationality: "",
              nssf: "",
              national_id: "",
              address: "",
              phoneno: "",
              overtime: "",
              start_date: "",
              emergencycontactname: "",
              emergencycontactnumber: "",
              employementtype: "",
              initial: "",
              first_name: "",
              last_name: "",
              salutation: "",
              dependent: "",
            }}
            onFinish={() => {
              handlesubmit();
            }}
          >
            <StyleRow className="m-1 mt-2" gutter={20}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="salutation"
                  label="Salutation"
                  placeholder="Select Salutation"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Salutation is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Salutation"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="Mr.">Mr.</Option>
                    <Option value="Dr.">Dr.</Option>
                    <Option value="Prof.">Prof.</Option>
                    <Option value="Ms.">Ms.</Option>
                    <Option value="Mrs.">Mrs.</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="initial"
                  label="Initial"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Initial is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Initial"
                    maxLength={5}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "First name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="First name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Last name"
                    maxLength={50}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="employeeid"
                  label="Employee ID"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Employee ID is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Employee ID"
                    maxLength={15}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z0-9\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="user_id"
                  label="Email ID"
                  rules={[
                    {
                      required: true,
                      message: "Email ID is required",
                    },
                    { validator: validateEmployeeId },
                  ]}
                >
                  <Input
                    placeholder="Email ID"
                    disabled={true}
                    onChange={onFinish}
                    maxLength={60}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Gender is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select gender"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="They/ Them">They/ Them</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Date of Birth"
                  name="dateof_birth"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Date of Birth is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={() => {
                      onFinish();
                    }}
                    placeholder="Select Date of Birth"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="maritalstatus"
                  label="Marital Status"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="single">Single</Option>
                    <Option value="married">Married</Option>
                    <Option value="widow">Widow</Option>
                    <Option value="divorced">Divorced</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="nationality"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    onChange={() => {
                      onFinish();
                      form.setFieldValue("phone", "");
                      form.setFieldValue("emergencycontactnumber", "");
                    }}
                    getPopupContainer={() => containerRef.current}
                  >
                    {countryList?.map((country, index) => (
                      <Select.Option
                        key={index}
                        value={country}
                        label={country}
                      >
                        {country}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item name="phoneno" label="Phone No">
                  <PhoneInput
                    type="text"
                    defaultCountry={
                      form.getFieldValue("nationality")
                        ? getCountryCodeByName(
                            form.getFieldValue("nationality")
                          )
                        : ""
                    }
                    placeholder="Phone No"
                    maxLength={
                      form.getFieldValue("nationality")
                        ? Number(
                            getMaxLengthByCountry(
                              form.getFieldValue("nationality")
                            )
                          ) + 2
                        : 15
                    }
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactname"
                  label="Emergency Contact Name"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Emergency Contact Name"
                    maxLength={15}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="emergencycontactnumber"
                  label="Emergency Contact Number"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Emergency Contact Number is required",
                    },
                  ]}
                >
                  <PhoneInput
                    placeholder="Emergency Contact Number"
                    defaultCountry={
                      form.getFieldValue("nationality")
                        ? getCountryCodeByName(
                            form.getFieldValue("nationality")
                          )
                        : ""
                    }
                    maxLength={
                      form.getFieldValue("nationality")
                        ? Number(
                            getMaxLengthByCountry(
                              form.getFieldValue("nationality")
                            )
                          ) + 2
                        : 15
                    }
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const value = /^[0-9+]$/?.test(e.key);
                      if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="address"
                  label="Address"
                  rules={[
                    {
                      required: true,
                      message: "Address is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Address"
                    onChange={onFinish}
                    maxLength={60}
                    onKeyDown={(e) => {
                      const value =
                        /^[0-9,/]$/.test(e.key) ||
                        /^[a-zA-Z\s,/]+$/.test(e.key);
                      if (!value && e.key !== "Backspace") {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "workinformation" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Work Information:</b>
          <Form
            className=""
            form={form}
            name="addemployeeidentityform"
            layout="vertical"
            initialValues={{
              company_Name: "",
              // industry: [],
              // sector: [],
              // industryrole: "",
              // grade: "",
              jobtitle: "",
              professionalsince: "",
              hireddate: "",
              employeesponser: "",
              relevent: "",
              maritalstatus: "",
              description: "",
              lastpromotionlevel: "",
              overtime: "",
              lastpromotiondate: "",
              employementtype: "",
              probationstartdate: "",
              probationenddate: "",
              contractstartdate: "",
              contractenddate: "",
            }}
            onFinish={() => handlesubmit()}
          >
            <StyleRow className="m-1 mt-2" gutter={20}>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="overtime"
                  label="Overtime Eligible"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "This field is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="companyname"
                  label="Company Name"
                  rules={[
                    {
                      required: false,
                      message: "Company name is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Company name"
                    maxLength={50}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                      {
                        required: false,
                        message: "Industry is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      mode="multiple"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {Industry?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="sector"
                    label="Sector"
                    rules={[
                      {
                        required: false,
                        message: "Sector is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      mode="multiple"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {Sector?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="industryrole"
                    label="Industry Role"
                    rules={[
                      {
                        required: false,
                        message: "Industry Role is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {IndustryRole?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
              {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="expertise"
                    label="Expertise"
                    rules={[
                      {
                        required: false,
                        message: "Expertise is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Expertise"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col>
  
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="designation"
                    label="Designation"
                    rules={[
                      {
                        required: false,
                        message: "Designation is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Designation"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col>
  
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="immediatesupervisor"
                    label="Immediate Supervisor"
                    rules={[
                      {
                        required: false,
                        message: "Immediate Supervisor is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Immediate Supervisor"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

              {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="grade"
                    label="Grade"
                    rules={[
                      {
                        required: false,
                        message: "Grade is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {Grade?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

              {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="targetchargeability"
                    label="Target Chargeability %"
                    rules={[
                      {
                        required: false,
                        message: "Target Chargeability % is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Target Chargeability %"
                      maxLength={50}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col>
  
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="chargeoutrate"
                    label="Charge Out Rate"
                    rules={[
                      {
                        required: false,
                        message: "Charge Out Rate is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Charge Out Rate"
                      maxLength={50}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Last Promotion Date"
                  name="lastpromotiondate"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Last Promotion Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    placeholder="Select Last Promotion Date"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="lastpromotionlevel"
                  label="Last Promotion Level"
                  rules={[
                    {
                      required: false,
                      message: "Last Promotion is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Last Promotion"
                    maxLength={100}
                    onChange={onFinish}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="jobtitle"
                  label="Job Title"
                  rules={[
                    {
                      required: true,
                      message: "Job title is required",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    placeholder="Job Title"
                    maxLength={50}
                    onChange={onFinish}
                    onKeyDown={(e) => {
                      const allowedChars = /^[a-zA-Z\s]+$/;
                      if (!allowedChars.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Professional Since"
                  name="professionalsince"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Professional Since is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={onFinish}
                    placeholder="Professional Since"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  label="Hired Date"
                  name="hireddate"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Hired Date is required",
                    },
                  ]}
                >
                  <DatePicker
                    className="w-100"
                    format="DD-MM-YYYY"
                    onChange={onFinish}
                    placeholder="Select Hired Date"
                    getPopupContainer={() => containerRef.current}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="employeesponser"
                  label="Employee Sponsor"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Employee Sponsor is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="employeestatus"
                  label="Status"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Status is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={(value) => {
                      setEmployeeStatusId(value === "active" ? "1" : "0");
                      onFinish();
                    }}
                  >
                    <Option value="active">Active</Option>
                    <Option value="in active">In active</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="relevent"
                  label="Relevant"
                  rules={[
                    {
                      required: false,
                      pattern: NON_EMPTY_REGEX,
                      message: "Relevant is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={onFinish}
                  >
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} ref={containerRef}>
                <Form.Item
                  name="employementtype"
                  label="Employment Type"
                  rules={[
                    {
                      required: true,
                      pattern: NON_EMPTY_REGEX,
                      message: "Employment Type is required",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    getPopupContainer={() => containerRef.current}
                    onChange={(value) => {
                      onFinish();
                      if (value !== "Probation") {
                        form.setFieldValue("probationstartdate", "");
                        form.setFieldValue("probationenddate", "");
                      }
                      if (value !== "Contract") {
                        form.setFieldValue("contractstartdate", "");
                        form.setFieldValue("contractenddate", "");
                      }
                    }}
                  >
                    <Option value="Casual">Casual</Option>
                    <Option value="Contract">Contract</Option>
                    <Option value="Full time">Full time</Option>
                    <Option value="Paid Internship">Paid Internship</Option>
                    <Option value="Part time">Part time</Option>
                    <Option value="Training">Training</Option>
                    <Option value="Unpaid Internship">Unpaid Internship</Option>
                    <Option value="Probation">Probation</Option>
                  </Select>
                </Form.Item>
              </Col>

              {form.getFieldValue("employementtype") === "Probation" && (
                <>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Probation Start Date"
                      name="probationstartdate"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Probation Start Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        placeholder="Select Probation start date"
                        getPopupContainer={() => containerRef.current}
                        onChange={onFinish}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Probation End Date"
                      name="probationenddate"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Probation End Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        placeholder="Select Probation End date"
                        getPopupContainer={() => containerRef.current}
                        onChange={onFinish}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
              {form.getFieldValue("employementtype") === "Contract" && (
                <>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Contract Start Date"
                      name="contractstartdate"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Contract Start Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        placeholder="Select Contract start date"
                        getPopupContainer={() => containerRef.current}
                        onChange={onFinish}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item
                      label="Contract End Date"
                      name="contractenddate"
                      rules={[
                        {
                          required: true,
                          pattern: NON_EMPTY_REGEX,
                          message: "Contract End Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        className="w-100"
                        format="DD-MM-YYYY"
                        placeholder="Select Contract End date"
                        getPopupContainer={() => containerRef.current}
                        onChange={onFinish}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col xs={24} sm={24} md={24}>
                <Form.Item name="description" label="Description">
                  <Input.TextArea
                    onChange={onFinish}
                    placeholder="description"
                  />
                </Form.Item>
              </Col>
            </StyleRow>
            <div className="text-end mt-3">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "basic" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {" "}
            {pageName} /
          </button>{" "}
          <b>Edit Employee</b>
          <Tabs
            defaultActiveKey={activeTab}
            className="mt-3"
            onChange={handleTabChange}
          >
            {tabTitles.map((title, index) => (
              <TabPane tab={title} key={index.toString()}>
                {activeTab === "0" && (
                  <>
                    <Form
                      className=""
                      form={form}
                      name="addemployeeform"
                      layout="vertical"
                      initialValues={{
                        user_id: "",
                        department_id: "",
                        gender: "",
                        dateof_birth: "",
                        maritalstatus: "",
                        nationality: "",
                        nssf: "",
                        national_id: "",
                        address: "",
                        phoneno: "",
                        overtime: "",
                        start_date: "",
                        emergencycontactname: "",
                        emergencycontactnumber: "",
                        employementtype: "",
                        initial: "",
                        first_name: "",
                        last_name: "",
                        salutation: "",
                        // dependent: "",
                        // company_Name: "",
                        // industry: [],
                        // sector: [],
                        // industryrole: "",
                        // expertise: "",
                        // designation: "",
                        // immediatesupervisor: "",
                        // grade: "",
                        // targetchargeability: "",
                        // chargeoutrate: "",
                        jobtitle: "",
                        professionalsince: "",
                        hireddate: "",
                        employeesponser: "",
                        relevent: "",
                        maritalstatus: "",
                        description: "",
                        lastpromotionlevel: "",
                        lastpromotiondate: "",
                        // competencyQC: [],
                        // serviceline: [],
                        // subserviceline: [],
                        // competencyrole: [],
                        // managingoffice: [],
                        // organisationlevel: [],
                        // lastpromotion: ""
                        probationstartdate: "",
                        probationenddate: "",
                        contractstartdate: "",
                        contractenddate: "",
                        reportingmanager: "",
                      }}
                      onFinish={() => {
                        handlesubmit();
                      }}
                    >
                      <b className="my-2">Edit Work Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="overtime"
                            label="Overtime Eligible"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="companyname"
                            label="Company Name"
                            rules={[
                              {
                                required: false,
                                message: "Company name is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Company name"
                              maxLength={50}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                      {
                        required: false,
                        message: "Industry is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      mode="multiple"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {Industry?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                        {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="sector"
                    label="Sector"
                    rules={[
                      {
                        required: false,
                        message: "Sector is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      mode="multiple"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {Sector?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                        {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="industryrole"
                    label="Industry Role"
                    rules={[
                      {
                        required: false,
                        message: "Industry Role is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {IndustryRole?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}
                        {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="expertise"
                    label="Expertise"
                    rules={[
                      {
                        required: false,
                        message: "Expertise is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Expertise"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

                        {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="designation"
                    label="Designation"
                    rules={[
                      {
                        required: false,
                        message: "Designation is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Designation"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

                        {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="immediatesupervisor"
                    label="Immediate Supervisor"
                    rules={[
                      {
                        required: false,
                        message: "Immediate Supervisor is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Immediate Supervisor"
                      maxLength={50}
                      onKeyDown={(e) => {
                        const allowedChars = /^[a-zA-Z\s]+$/;
                        if (!allowedChars.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

                        {/* <Col xs={24} sm={24} md={12} ref={containerRef}>
                  <Form.Item
                    name="grade"
                    label="Grade"
                    rules={[
                      {
                        required: false,
                        message: "Grade is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={onFinish}
                      getPopupContainer={() => containerRef.current}
                    >
                      {Grade?.map((value, index) => (
                        <Select.Option key={index} value={value} label={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col> */}

                        {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="targetchargeability"
                    label="Target Chargeability %"
                    rules={[
                      {
                        required: false,
                        message: "Target Chargeability % is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Target Chargeability %"
                      maxLength={50}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

                        {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="chargeoutrate"
                    label="Charge Out Rate"
                    rules={[
                      {
                        required: false,
                        message: "Charge Out Rate is required",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Charge Out Rate"
                      maxLength={50}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Last Promotion Date"
                            name="lastpromotiondate"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Last Promotion Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              placeholder="Select Last Promotion Date"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="lastpromotionlevel"
                            label="Last Promotional Level"
                            rules={[
                              {
                                required: false,
                                message: "Last Promotion is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Last Promotion"
                              maxLength={100}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="jobtitle"
                            label="Job Title"
                            rules={[
                              {
                                required: true,
                                message: "Job title is required",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              placeholder="Job Title"
                              maxLength={50}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Professional Since"
                            name="professionalsince"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Professional Since is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={onFinish}
                              placeholder="Professional Since"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Hired Date"
                            name="hireddate"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Hired Date is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={onFinish}
                              placeholder="Select Hired Date"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employeesponser"
                            label="Employee Sponsor"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Employee Sponsor is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employeestatus"
                            label="Status"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Status is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={(value) => {
                                setEmployeeStatusId(
                                  value === "active" ? "1" : "0"
                                );
                                onFinish();
                              }}
                            >
                              <Option value="active">Active</Option>
                              <Option value="in active">In active</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="relevent"
                            label="Relevant"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Relevant is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="employementtype"
                            label="Employment Type"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Employment Type is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={(value) => {
                                onFinish();
                                if (value !== "Probation") {
                                  form.setFieldValue("probationstartdate", "");
                                  form.setFieldValue("probationenddate", "");
                                }
                                if (value !== "Contract") {
                                  form.setFieldValue("contractstartdate", "");
                                  form.setFieldValue("contractenddate", "");
                                }
                              }}
                            >
                              <Option value="Casual">Casual</Option>
                              <Option value="Contract">Contract</Option>
                              <Option value="Full time">Full time</Option>
                              <Option value="Paid Internship">
                                Paid Internship
                              </Option>
                              <Option value="Part time">Part time</Option>
                              <Option value="Training">Training</Option>
                              <Option value="Unpaid Internship">
                                Unpaid Internship
                              </Option>
                              <Option value="Probation">Probation</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {form.getFieldValue("employementtype") ===
                          "Probation" && (
                          <>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                label="Probation Start Date"
                                name="probationstartdate"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Probation Start Date is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  format="DD-MM-YYYY"
                                  placeholder="Select Probation start date"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                              <Form.Item
                                label="Probation End Date"
                                name="probationenddate"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Probation End Date is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  format="DD-MM-YYYY"
                                  placeholder="Select Probation End date"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        {form?.getFieldValue("employementtype") ===
                          "Contract" && (
                          <>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Contract Start Date"
                                name="contractstartdate"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Contract Start Date is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  format="DD-MM-YYYY"
                                  placeholder="Select Contract start date"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} ref={containerRef}>
                              <Form.Item
                                label="Contract End Date"
                                name="contractenddate"
                                rules={[
                                  {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Contract End Date is required",
                                  },
                                ]}
                              >
                                <DatePicker
                                  className="w-100"
                                  format="DD-MM-YYYY"
                                  placeholder="Select Contract End date"
                                  getPopupContainer={() => containerRef.current}
                                  onChange={onFinish}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        <Col xs={24} sm={24} md={24}>
                          <Form.Item name="description" label="Description">
                            <Input.TextArea
                              onChange={onFinish}
                              placeholder="description"
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>
                      <b className="my-2">Edit Personal Information:</b>
                      <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="salutation"
                            label="Salutation"
                            placeholder="Select Salutation"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Salutation is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select Salutation"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="Mr.">Mr.</Option>
                              <Option value="Dr.">Dr.</Option>
                              <Option value="Prof.">Prof.</Option>
                              <Option value="Ms.">Ms.</Option>
                              <Option value="Mrs.">Mrs.</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="initial"
                            label="Initial"
                            rules={[
                              {
                                required: false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Initial is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Initial"
                              maxLength={5}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="firstname"
                            label="First Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "First name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="First name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="lastname"
                            label="Last Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Last name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Last name"
                              maxLength={50}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={onFinish}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="employeeid"
                            label="Employee ID"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Employee ID is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Employee ID"
                              maxLength={15}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z0-9\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="user_id"
                            label="Email ID"
                            rules={[
                              {
                                required: true,
                                message: "Email ID is required",
                              },
                              { validator: validateEmployeeId },
                            ]}
                          >
                            <Input
                              placeholder="Email ID"
                              disabled={true}
                              onChange={onFinish}
                              maxLength={60}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Gender is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select gender"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="male">Male</Option>
                              <Option value="female">Female</Option>
                              <Option value="They/ Them">They/ Them</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {/* <Col xs={24} sm={24} md={12}>
                  <Form.Item name="dependent" label="No. of Dependents">
                    <Input
                      placeholder="No. of Dependents"
                      maxLength={10}
                      onKeyDown={(e) => {
                        const value = /^[0-9+]$/?.test(e.key);
                        if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                          e.preventDefault();
                        }
                      }}
                      onChange={onFinish}
                    />
                  </Form.Item>
                </Col> */}

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            label="Date of Birth"
                            name="dateof_birth"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Date of Birth is required",
                              },
                            ]}
                          >
                            <DatePicker
                              className="w-100"
                              format="DD-MM-YYYY"
                              onChange={() => {
                                onFinish();
                              }}
                              placeholder="Select Date of Birth"
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="maritalstatus"
                            label="Marital Status"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "This field is required",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Select"
                              getPopupContainer={() => containerRef.current}
                              onChange={onFinish}
                            >
                              <Option value="single">Single</Option>
                              <Option value="married">Married</Option>
                              <Option value="widow">Widow</Option>
                              <Option value="divorced">Divorced</Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="reportingmanager"
                            label="Reporting Manager"
                            rules={[
                              {
                                required: userList?.length !== 0 ? true : false,
                                pattern: NON_EMPTY_REGEX,
                                message: "Reporting Manager is required",
                              },
                            ]}
                          >
                            <Select
                              className="selectbox mb-2"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.name ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.name ?? "")
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.name ?? "").toLowerCase()
                                  )
                              }
                              placeholder="Select Manager"
                              options={userList?.filter(
                                (item) => item?.statusid === "1"
                              )}
                              fieldNames={{
                                label: "fullname",
                                value: "userid",
                              }}
                              onChange={onFinish}
                              getPopupContainer={() => containerRef.current}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} ref={containerRef}>
                          <Form.Item
                            name="nationality"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Country is required",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onChange={() => {
                                onFinish();
                                form.setFieldValue("phone", "");
                                form.setFieldValue(
                                  "emergencycontactnumber",
                                  ""
                                );
                              }}
                              getPopupContainer={() => containerRef.current}
                            >
                              {countryList?.map((country, index) => (
                                <Select.Option
                                  key={index}
                                  value={country}
                                  label={country}
                                >
                                  {country}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item name="phoneno" label="Phone No">
                            <PhoneInput
                              type="text"
                              defaultCountry={
                                form.getFieldValue("nationality")
                                  ? getCountryCodeByName(
                                      form.getFieldValue("nationality")
                                    )
                                  : ""
                              }
                              placeholder="Phone No"
                              maxLength={
                                form.getFieldValue("nationality")
                                  ? Number(
                                      getMaxLengthByCountry(
                                        form.getFieldValue("nationality")
                                      )
                                    ) + 2
                                  : 15
                              }
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="emergencycontactname"
                            label="Emergency Contact Name"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Emergency Contact Name is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Emergency Contact Name"
                              maxLength={15}
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const allowedChars = /^[a-zA-Z\s]+$/;
                                if (!allowedChars.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="emergencycontactnumber"
                            label="Emergency Contact Number"
                            rules={[
                              {
                                required: true,
                                pattern: NON_EMPTY_REGEX,
                                message: "Emergency Contact Number is required",
                              },
                            ]}
                          >
                            <PhoneInput
                              placeholder="Emergency Contact Number"
                              defaultCountry={
                                form.getFieldValue("nationality")
                                  ? getCountryCodeByName(
                                      form.getFieldValue("nationality")
                                    )
                                  : ""
                              }
                              maxLength={
                                form.getFieldValue("nationality")
                                  ? Number(
                                      getMaxLengthByCountry(
                                        form.getFieldValue("nationality")
                                      )
                                    ) + 2
                                  : 15
                              }
                              onChange={onFinish}
                              onKeyDown={(e) => {
                                const value = /^[0-9+]$/?.test(e.key);
                                if (
                                  !value &&
                                  e.key !== "Backspace" &&
                                  /^[0-9+]$/
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                          <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                              {
                                required: true,
                                message: "Address is required",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Address"
                              onChange={onFinish}
                              maxLength={60}
                              onKeyDown={(e) => {
                                const value =
                                  /^[0-9,/]$/.test(e.key) ||
                                  /^[a-zA-Z\s,/]+$/.test(e.key);
                                if (!value && e.key !== "Backspace") {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </StyleRow>

                      <div className="text-end mt-3">
                        <StyledButton
                          loading={showLoader}
                          type="primary"
                          htmlType="submit"
                        >
                          {showLoader ? "Update" : "Update"}
                        </StyledButton>
                      </div>
                    </Form>
                  </>
                )}
                {activeTab === "1" && (
                  <>
                    <div className="styled-card mb-5">
                      {roleList.length === 0 && (
                        <StyledButton
                          className="mt-2"
                          // onClick={() => setOpenModal(true)}
                        >
                          Add Role
                        </StyledButton>
                      )}
                      {roleList?.length > 0 && (
                        <Checkbox
                          className="mt-3"
                          checked={selectAllRoleChecked}
                          onChange={handleSelectAllRole}
                        >
                          Select All
                        </Checkbox>
                      )}
                      <SelectTable
                        // step={step}
                        // setStep={setStep}
                        columns={rolecolumns}
                        tabledata={roleList}
                        handleClickRole={handlesubmit}
                        handleReset={handleResetAllRole}
                        selectedRoleId={selectedRoleId}
                        showLoader={showLoader}
                      />
                      {/* <FormModal
                        isModalVisible={openModal}
                        closeModal={() => setOpenModal(false)}
                        Name="Role"
                      /> */}
                    </div>
                  </>
                )}

                {activeTab === "2" && (
                  <>
                    {filterLocationList?.length === 0 ? (
                      <div className="free-trial-banner">
                        <span className="content">
                          Kindly assign the location for the selected role or
                          select another role
                          {/* <CloseOutlined
                      style={{
                        cursor: "pointer",
                      }}
                      fontSize="small"
                    /> */}
                        </span>
                      </div>
                    ) : (
                      <div className="styled-card mb-5">
                        <SelectTable
                          // step={step}
                          // setStep={setStep}
                          columns={baseLocationColumn}
                          tabledata={filterLocationList}
                          editRole="edit"
                          handleClickRole={handlesubmit}
                          selectedBaseLocation={baseLocation}
                          showLoader={showLoader}
                        />
                      </div>
                    )}
                  </>
                )}

                {activeTab === "3" && (
                  <>
                    {departmentBasedOnRole?.length === 0 ? (
                      <div className="free-trial-banner">
                        <span className="content">
                          Kindly assign the department for the selected role or
                          select another role
                        </span>
                      </div>
                    ) : (
                      <div className="styled-card mb-5">
                        <SelectTable
                          // step={step}
                          // setStep={setStep}
                          columns={baseDepartmentColumn}
                          tabledata={departmentBasedOnRole}
                          editRole="edit"
                          handleClickRole={handlesubmit}
                          selectedBaseDepartment={baseDepartment}
                          showLoader={showLoader}
                        />
                      </div>
                    )}
                  </>
                )}
              </TabPane>
            ))}
          </Tabs>
        </>
      )}
      {updateState === "educationdetails" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Education Details:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              educationdetails: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="educationdetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Institute Name"
                          name={[name, "institutename"]}
                          rules={[
                            {
                              required: true,
                              message: "Institute Name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Institute Name"
                            onChange={onFinish}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Exam Body:"
                          name={[name, "exambody"]}
                          rules={[
                            { required: true, message: "Exam Body required" },
                          ]}
                        >
                          <Input placeholder="Exam Body" onChange={onFinish} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Awarded:"
                          name={[name, "awarded"]}
                          rules={[
                            { required: true, message: "Awarded required" },
                          ]}
                        >
                          {/* <Input placeholder="Awarded" onChange={onFinish}/> */}
                          <Select
                            placeholder="Select"
                            getPopupContainer={() => containerRef.current}
                            onChange={onFinish}
                          >
                            <Option value="Doctorate">Doctorate</Option>
                            <Option value="Master">Master</Option>
                            <Option value="Bachelor">Bachelor</Option>
                            <Option value="Diploma">Diploma</Option>
                            <Option value="Certificate">Certificate</Option>
                          </Select>
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Attachment"
                          name={[name, "attachment"]}
                          valuePropName="fileList"
                          getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                              return e;
                            }
                            return e && e.fileList;
                          }}
                        >
                          <Upload
                            customRequest={({ file, onSuccess }) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                const base64Data = reader.result.split(",")[1];
                                // Pass both the file and base64Data to onSuccess
                                const uniqueId = generateUniqueId();
                                onSuccess({ file, base64Data, uniqueId });
                              };

                              reader.onerror = (error) => {
                                console.error("File read error:", error);
                              };
                            }}
                            name="logo"
                            listType="picture"
                            style={{ width: "100%" }}
                            onChange={(value) => {
                              onFinish();
                              uploadFile(value);
                            }}
                            maxCount={1}
                          >
                            <div style={{ width: "100%" }}>
                              <Button className="w-100 text-start" size="large">
                                Upload File
                              </Button>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "practicaltrainings" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Practical Training:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              practicaltrainings: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="practicaltrainings">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Organisation Name"
                          name={[name, "organisationname"]}
                          rules={[
                            {
                              required: true,
                              message: "Organisation Name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Organisation Name"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Position Held"
                          name={[name, "positionheld"]}
                          rules={[
                            {
                              required: true,
                              message: "Position Held required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Position Held"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Nature of work & Tasks performed or completed"
                          name={[name, "tasksperformed"]}
                          rules={[
                            {
                              required: true,
                              message:
                                "Nature of work & Tasks performed or completed required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Nature of work & Tasks performed or completed"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "workexperience" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Work Experience:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              workexperience: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="workexperience">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Company name"
                          name={[name, "companyname"]}
                          rules={[
                            {
                              required: true,
                              message: "Company name required",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Company name"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12}>
                        <Form.Item
                          {...restField}
                          label="Job Title"
                          name={[name, "jobtitle"]}
                          rules={[
                            { required: true, message: "Job Title required" },
                          ]}
                        >
                          <Input
                            placeholder="Job Title"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Relevant"
                          name={[name, "relevant"]}
                          rules={[
                            { required: true, message: "Relevnt required" },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            getPopupContainer={() => containerRef.current}
                            onChange={onFinish}
                          >
                            <Option value="yes">Yes</Option>
                            <Option value="no">No</Option>
                          </Select>
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Job Description"
                          name={[name, "jobdescription"]}
                          rules={[
                            {
                              required: true,
                              message: "Job Description required",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Job Description"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                        {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {updateState === "professionalcertifications" && (
        <>
          <button
            className="bg-transparent border-0"
            onClick={() => setUpdateState("")}
          >
            {pageName} /{" "}
          </button>
          <b>Edit Professional Certification:</b>
          <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={(values) => {
              handlesubmit();
            }}
            autoComplete="off"
            initialValues={{
              professionalcertifications: [],
            }}
            labelCol={{ xs: 24, sm: 24, md: 24 }}
            style={{ textAlign: "left" }}
          >
            <Form.List name="professionalcertifications">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <StyleRow gutter={16} className="mt-2">
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Certification Type"
                          name={[name, "certificationtype"]}
                          rules={[
                            {
                              required: true,
                              message: "Certification Type required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Select"
                            optionFilterProp="children"
                            getPopupContainer={() => containerRef.current}
                            defaultValue={AwardList[0]}
                            // onChange={onFinish}
                            onChange={() => {
                              onFinish();
                            }}
                          >
                            {AwardList?.map((value, index) => (
                              <Select.Option
                                key={index}
                                value={value}
                                label={value}
                              >
                                {value}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="From:"
                          name={[name, "fromdate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="From"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                          {...restField}
                          label="To:"
                          name={[name, "todate"]}
                          rules={[{ required: true, message: "Date required" }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD-MM-YYYY"
                            onChange={() => {
                              onFinish();
                            }}
                            placeholder="To"
                            getPopupContainer={() => containerRef.current}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Remarks"
                          name={[name, "remarks"]}
                          rules={[
                            { required: true, message: "Remarks required" },
                          ]}
                        >
                          <Input
                            placeholder="Remarks"
                            onChange={() => {
                              onFinish();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24}>
                        <Form.Item
                          {...restField}
                          label="Attachment"
                          name={[name, "attachment"]}
                          valuePropName="fileList"
                          getValueFromEvent={(e) => {
                            if (Array.isArray(e)) {
                              return e;
                            }
                            return e && e.fileList;
                          }}
                        >
                          <Upload
                            customRequest={({ file, onSuccess }) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(file);

                              reader.onload = () => {
                                const base64Data = reader.result.split(",")[1];
                                const uniqueId = generateUniqueId();
                                onSuccess({ file, base64Data, uniqueId });
                              };

                              reader.onerror = (error) => {
                                console.error("File read error:", error);
                              };
                            }}
                            name="logo"
                            // action="/upload.do"
                            onChange={(value) => {
                              onFinish();
                              uploadFile(value);
                            }}
                            listType="picture"
                            style={{ width: "100%" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Button className="w-100 text-start" size="large">
                                Upload File
                              </Button>
                            </div>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </StyleRow>
                  ))}
                  <Form.Item>
                    <u
                      className="bg-transparent border-0 text-start"
                      type="dashed"
                      onClick={() => add()}
                      block
                      style={cursorstyle}
                    >
                      +Add Another
                    </u>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <div className="text-end">
              <StyledButton
                loading={showLoader}
                type="primary"
                htmlType="submit"
              >
                {showLoader ? "Update" : "Update"}
              </StyledButton>
            </div>
          </Form>
        </>
      )}
      {/* <button onClick={() => setUpdateState("")}>back</button> */}
    </EditProfileBox>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserList: setUserList,
      saveRoleList: setRoleList,
    },
    dispatch
  );

const mapStateToProps = (state) => ({
  userList: getUserList(state),
  roleList: getRoleList(state),
  ClientId: getClientid(state),
  bandList: getBandList(state),
  locationList: getLocations(state),
  allDepartmentList: getAllDepartmentList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, mapDispatchToProps, null)(EditProfile);
