import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Avatar,
  Modal,
  Skeleton,
  Tooltip,
  Popover,
  Space,
  Dropdown,
} from "antd";
import { Link } from "react-router-dom";
import logo from "assets/sidemenulogo.svg";
import userlogo from "assets/sidemenulogo.svg";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import OutlinedInput from "@mui/material/OutlinedInput";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import {
  Select as MUISelect,
  Chip,
  Box,
  createTheme,
  IconButton,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useMedia } from "react-use";
import { startCase, get } from "lodash";
import { connect, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import {
  getUserName,
  getSelectedDepartmentId,
  getDepartmentList,
  getRoleName,
  getDepartmentName,
  getLoadingDataFirstTime,
  getUserRoleId,
  getRoleList,
  getUserRoleList,
  getNotificationList,
  getUserInfo,
  getProfilePic,
  getAllDepartmentList,
  getClientid,
  getMenuList,
  getTrailPeriod,
  getCurrentPlan,
  getProductInfo,
} from "redux/selectors";
import { bindActionCreators } from "redux";
import {
  setDepartmentId,
  setNotificationList,
  clearStore,
} from "redux/actions";
import styled from "@emotion/styled";
import COLORS from "common/Colors";

// Components
import CustomIcon from "atoms/Icon";
import SwitchUserModal from "organisms/SwitchUserModal";
import { DEVICE } from "common/Device";
import DashboardTop from "./DashboardTop";
import LeaveForm from "organisms/LeaveForm";
import Subscription from "./Subscription";
import { Products } from "common/Constants";
import { ButtonOutlined } from "reusableComponent/UIButtons";
import { CloseOutlined } from "@mui/icons-material";
import moment from "moment";
import Icon from "atoms/Icon";
import { jwtDecode } from "jwt-decode";
import FloatingButton from "reusableComponent/FloatingButton";
import AddIcon from "@mui/icons-material/Add";
import ReportIncident from "pages/IncidentManagement/ReportIncident";

const AntHeader = Layout.Header;
const StyledCol = styled(Col)`
  /* text-align: right; */
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${DEVICE.mobileL} {
    margin-left: 0px;
  }
  @media ${DEVICE.mobileM} {
    margin-left: 0px;
    text-align: none;
  }
`;

const StyleSubmitButton = styled(ButtonOutlined)`
  margin-right: 0.7rem;
  padding: 5px 10px !important;

  @media (min-width: 375px) and (max-width: 424px) {
    padding: 3px;
    font-size: 12px !important;
  }
  @media (max-width: 325px) {
    padding: 3px;
    font-size: 12px !important;
  }
`;

const StyledIconContainer = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  white-space: pre;
  @media ${DEVICE.tablet} {
    /* text-align: right; */
  }
  @media ${DEVICE.mobileL} {
    .apply-leave-sm {
      font-size: 12px;
      padding-left: 13px;
      padding-right: 3px;
    }
    .switch-role-sm {
      font-size: 12px;
      padding-left: 3px;
      padding-right: 3px;
    }
    .select {
      /* margin-top: 65px; */
      text-align: right;
    }
  }
`;

const StyledAntHeader = styled(AntHeader)`
  background: #ffffff;
  z-index: 9;
  height: auto;
  line-height: 1.2;
  padding: 15px 0px 15px 10px;
  border-bottom: 1px solid #f0f0f0;
  // padding: 15px;

  @media ${DEVICE.tablet} {
    padding: 0.5rem 0px;
  }
  .left-header-skeleton {
    margin: 10px;
  }
  .right-header-skeleton {
    margin: 10px;
  }
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 0.5rem;
  width: 30px;
  height: 30px;
  // margin-bottom: 5px;
  background-color: ${COLORS.PRIMARY};
  vertical-align: middle;
`;

const StyleSuperHODDiv = styled.div`
  display: flex;
  align-items: center;
  .hide {
    display: inline;
  }
  .dept_info {
    min-width: 300px;
    max-width: 300px;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      min-width: 200px;
      margin-top: 13px;
    }
  }
  span {
    /* line-height: 20px; */
    display: block;
  }
  img {
    /* display: none; */
    padding: 0px 10px;
    height: 30px;
  }
  @media ${DEVICE.tablet} {
    .hide {
      display: none;
    }
    span {
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      display: inline;
      align-self: flex-start;
    }
  }
  @media (max-width: 1250px) {
    span {
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  @media (max-width: 320px) {
    span {
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    img {
      width: 130px;
    }
  }
`;

const menuIconStyle = `
  font-size: 18px;
  // line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
`;

const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  ${menuIconStyle}
`;
const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
  ${menuIconStyle}
`;

const StyledMenuToggle = styled.div`
  display: none;
  @media ${DEVICE.tablet} {
    display: inline-flex;
    z-index: 100;
    position: relative;
    align-items: center;
    float: left;
    margin-left: 3px;
    padding: 10px;
    .anticon-menu-fold,
    .anticon-menu-unfold {
      padding: 2px 0px 0px 0px;
    }
  }

  ${({ collapsed }) => collapsed && `margin-left: -10px`}
`;

const StyledMenuToggle1 = styled.div`
  display: inline;
  float: left;
  padding: 10px;
  .anticon-menu-fold,
  .anticon-menu-unfold {
    padding: 2px 0px 0px 0px;
  }

  ${({ collapsed }) => collapsed && `margin-left: -10px`}
  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const StyledHeaderIcons = styled.div`
  display: inline-block;
  /* @media ${DEVICE.tablet} {
    margin-top: -30px;
    position: absolute;
    top: -54px;
    right: 0px;
  } */

  .ant-popover-inner {
    margin: 0 10px !important;
  }
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      fontFamily: "Segoe UI Symbol",
      fontSize: "14px",
      overflow: "auto",
    },
  },
};

const customizeMenu = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0px",
          whiteSpace: "unset",
        },
      },
    },
  },
});
const ontlineInput = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Segoe UI Symbol",
          borderWidth: "1px",
          maxHeight: "35px",
          "&:focus": {
            borderWidth: "1px",
          },
        },
      },
    },
  },
});
const theme = createTheme({
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontFamily: "Segoe UI Symbol",
          color: "#0000000",
          fontWeight: 400,
          padding: "5px",
        },
      },
    },
  },
});

const Header = ({
  setPageTitle,
  selectedDepartmentId,
  departmentName,
  updateDepartmentId,
  userName,
  collapsed,
  toggleCollapse,
  isLoadingDataFirstTime,
  userRoleId,
  roleList,
  showDrawer,
  userInfo,
  profilePic,
  allDepartmentList,
  clientId,
  setshowplan,
  showplan,
  isLoggedOut,
  menuList,
  setShowSubscribeFormModal,
  productInfo,
}) => {
  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  const [userRoleModal, setUserRoleModal] = useState(false);
  const [deptFullList, setDeptFullList] = useState([]);
  const [displayLeaveModal, toggleLeaveModal] = useState(false);
  const [displayIncidentModal, toggleIncidentModal] = useState(false);

  const token = window?.localStorage?.getItem("token");
  let restoken = "";
  if (typeof token === "string" && token.trim() !== "") {
    try {
      restoken = jwtDecode(token);
      // Now you can safely use restoken
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  } else {
    console.error("Token is missing or invalid.");
  }

  const trailEndDate = useSelector(getTrailPeriod);

  const filterUserRoleList =
    roleList?.filter((item) => userInfo?.role_id?.includes(item.roleId)) || [];

  const filterRoleList = roleList?.filter(
    (item) => item?.roleId === userRoleId
  );

  const filterDepartmentId = filterRoleList[0]?.department;
  const filterDepartment = allDepartmentList?.filter((item) =>
    filterDepartmentId?.includes(item?.department_id)
  );

  const logoutUser = () => {
    Modal.confirm({
      icon: "",
      content: "Are you sure you want to logout?",
      okText: "Logout",
      cancelText: "Cancel",
      cancelButtonProps: {
        style: { borderColor: "#2D53DA", color: "#2D53DA" },
      },
      onOk: () => {
        setTimeout(() => {
          window.localStorage.removeItem("token");
          isLoggedOut();
          window.location.href = "https://www.dev.website.tymeplushr.com/";
        }, 500);
      },
    });
  };

  useEffect(() => {
    intialFunction();
  }, [departmentName]);

  const intialFunction = async () => {
    const newArr = [];
    await filterDepartment?.forEach((element) => {
      selectedDepartmentId.forEach((e) => {
        if (element.department_id === e) {
          newArr.push(element.department_name);
        }
      });
    });
    setDeptFullList(newArr);
  };

  const handleCheckBoxChange = (event) => {
    let a = event.target.value;
    const newArr = [];
    filterDepartment.forEach((element) => {
      a.forEach((e) => {
        if (element.department_name === e) {
          newArr.push(element.department_id);
        }
      });
      updateDepartmentId(newArr);
    });
    setDeptFullList(typeof a === "string" ? a.split(",") : a);
  };

  const getNotificationList = async () => {
    if (clientId) {
    }
  };

  useEffect(() => {
    getNotificationList();
    const timer = setInterval(() => {
      getNotificationList();
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const headerSkeleton = (classname) => (
    <Skeleton.Input className={classname} active={true} size="small" />
  );

  const ExtramediumDevice = useMedia("(min-width: 1200px)");

  const mediumDevice = useMedia("(min-width: 900px)");

  const imgStyle = { height: "40px" };

  const customIconStyle = {
    height: "26px",
    cursor: "pointer",
    border: "1px solid #ccc",
    padding: "2px 5px",
    borderRadius: "5px",
  };

  const [showSwitchApp, setShowSwitchApp] = useState(false);

  const id = showSwitchApp ? "simple-popper" : undefined;

  const currentPlan = useSelector(getCurrentPlan);

  const isOnTrialPeriod =
    currentPlan?.planType === "trail" || currentPlan?.planType === "trial";

  // const subScriptionBanner = trailEndDate &&
  //   isOnTrialPeriod &&
  //   userRoleId == 1 && (
  //     <SubscriptionBanner
  //       trailEndDate={trailEndDate}
  //       setShowSubscribeFormModal={setShowSubscribeFormModal}
  //     />
  //   );

  const upgradePlanButton = !isOnTrialPeriod && userRoleId == 1 && (
    <StyleSubmitButton
      className="switch-role-sm mx-2"
      onClick={() => setShowSubscribeFormModal(true)}
    >
      Upgrade Plan
    </StyleSubmitButton>
  );

  const switchRoleButton = userRoleId !== "1" &&
    filterUserRoleList?.length > 1 && (
      <StyleSubmitButton
        className="switch-role-sm mx-2"
        onClick={() => setUserRoleModal(true)}
      >
        Switch Role
      </StyleSubmitButton>
    );

  const applyForLeaveButton = userRoleId !== "1" &&
    Menulist?.find(
      (item) => item?.key === "mycalendar" && item?.permission?.create
    ) && (
      <>
        <ButtonOutlined
          className="apply-leave-sm border-0"
          onClick={() => toggleIncidentModal(true)}
        >
          Report Incident
        </ButtonOutlined>{" "}
        <ButtonOutlined
          className="apply-leave-sm"
          onClick={() => toggleLeaveModal(true)}
        >
          Apply for Leave
        </ButtonOutlined>
      </>
    );

  const departmentSelect = deptFullList?.length != 0 && (
    <FormControl
      className="select"
      sx={{
        m: 1,
        width: ExtramediumDevice ? "120px" : mediumDevice ? "60px" : "100px",
        marginTop: 2,
        fontFamily: "Segoe UI Symbol",
      }}
    >
      <MUISelect
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={deptFullList}
        onChange={handleCheckBoxChange}
        input={<OutlinedInput theme={ontlineInput} sx={{ height: 50 }} />}
        defaultValue={selectedDepartmentId}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.1,
              maxHeight: 30,
              overflow: "hidden",
            }}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                sx={{
                  borderRadius: "4px",
                  width: "65px",
                  height: "29px",
                }}
                className="text-truncate"
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {filterDepartment?.map((name) => (
          <MenuItem
            disableGutters={true}
            key={name?.department_name}
            value={name?.department_name}
            theme={customizeMenu}
            selected={false}
            disableTouchRipple
            disabled={
              deptFullList?.length === 1 &&
              deptFullList[0] === name.department_name
            }
          >
            <Checkbox
              checked={deptFullList?.indexOf(name.department_name) > -1}
            />
            <ListItemText
              disableTypography={true}
              theme={theme}
              primary={name?.department_name}
            />
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );

  return (
    <StyledAntHeader>
      <Row>
        <Col xs={12} sm={8} md={8} lg={8} xl={8} className="d-flex">
          {isLoadingDataFirstTime && headerSkeleton("left-header-skeleton")}
          {!isLoadingDataFirstTime && (
            <StyleSuperHODDiv>
              <Link
                to={userRoleId === "1" ? `ownerdashboard` : `dashboard`}
                onClick={() => setPageTitle("")}
              >
                <img
                  style={imgStyle}
                  src={userRoleId === "1" ? logo : userlogo}
                  alt="Tymeplus"
                />
              </Link>
            </StyleSuperHODDiv>
          )}
          {/* <DashboardTop /> */}
        </Col>

        <Col xs={12} sm={8} md={8} lg={8} xl={8}>
          <DashboardTop />
        </Col>

        <StyledCol xs={24} sm={24} md={8} lg={8} xl={8}>
          {userRoleId !== "1" && (
            <>
              {" "}
              {ExtramediumDevice
                ? null
                : !isLoadingDataFirstTime && (
                    <Dropdown
                      menu={{
                        mode: "vertical",
                        items: [
                          upgradePlanButton && {
                            key: "upgrade",
                            label: upgradePlanButton,
                          },
                          applyForLeaveButton && {
                            key: "applyForLeaveButton",
                            label: applyForLeaveButton,
                          },
                          // departmentSelect && {
                          //   key: "departmentSelect",
                          //   label: departmentSelect,
                          // },
                        ],
                      }}
                    >
                      <div>
                        <Space>
                          <IconButton>
                            <MenuIcon />
                          </IconButton>
                        </Space>
                      </div>
                    </Dropdown>
                  )}
            </>
          )}

          <StyledIconContainer>
            {isLoadingDataFirstTime && (
              <>
                {headerSkeleton("right-header-skeleton")}
                {headerSkeleton("right-header-skeleton")}
              </>
            )}
            {!isLoadingDataFirstTime && (
              <>
                {!ExtramediumDevice ? null : (
                  <>
                    {upgradePlanButton}
                    {/* {switchRoleButton} */}
                    {applyForLeaveButton}
                    {/* {departmentSelect} */}
                  </>
                )}

                <StyledHeaderIcons>
                  <Link
                    className=""
                    to={`/my-profile`}
                    onClick={() => setPageTitle("Notification")}
                  >
                    <Tooltip placement="bottom" title={userName}>
                      {profilePic === null ||
                      Array.isArray(profilePic) ||
                      profilePic === "" ? (
                        <>
                          <StyledAvatar size="default">
                            {startCase(get(userInfo, "name", "").charAt(0))}
                          </StyledAvatar>
                        </>
                      ) : (
                        <>
                          <StyledAvatar
                            src={profilePic}
                            size="default"
                          ></StyledAvatar>
                        </>
                      )}
                    </Tooltip>
                  </Link>

                  {/* // Note : need to add release Notes */}
                  {/* <Link
                  className="mx-2"
                  to={`released-notes`}
                  onClick={() => setPageTitle("Release Notes")}
                >
                  <Tooltip placement="bottom" title={"Release Notes"}>
                    <InfoIcon className="icon" />
                  </Tooltip>
                </Link> */}
                  <Popover
                    placement="bottomRight"
                    content={
                      <div className="p-4">
                        <Row>
                          {Products?.map((val, index) => {
                            if (val?.access) {
                              return (
                                <Col
                                  md={8}
                                  key={index}
                                  className="items"
                                  onClick={() => {
                                    val?.url && window.open(val.url, "_self");
                                  }}
                                >
                                  <div className="logo d-flex justify-content-center align-items-center">
                                    <img
                                      width={60}
                                      height={60}
                                      src={val?.logo}
                                      alt={`${val?.name} Logo`}
                                    />
                                  </div>
                                  <p className="d-flex justify-content-center align-items-center text-center fw-medium">
                                    {val?.name}
                                  </p>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      </div>
                    }
                    trigger="click"
                    open={showSwitchApp}
                  >
                    {/* This code will need in future not now, so I have commented */}
                    {/* <IconButton
                      className="icon-btn"
                      onClick={(event) => {
                        setShowSwitchApp((prev) => !prev);
                      }}
                      aria-describedby={id}
                    >
                      <AddIcon />
                    </IconButton> */}
                  </Popover>
                  {!userRoleId == 1 && (
                    <Link
                      className="mx-2"
                      to={`close-account`}
                      onClick={() => setPageTitle("Close Account")}
                    >
                      <Tooltip placement="bottom" title={"close account"}>
                        <SettingsIcon className="icon" />
                      </Tooltip>
                    </Link>
                  )}

                  {/* {restoken?.signInTo?.tymeplusPay && (
                    <Icon
                      className="mx-2 styled-cursor"
                      name="approvals"
                      onClick={() => {
                        window.location.href = `http://localhost:5173/?token=${window?.localStorage?.getItem(
                          "token"
                        )}`;
                      }}
                    />
                  )} */}
                </StyledHeaderIcons>
              </>
            )}
            {/* <FloatingButton menuList={Menulist} productInfo={productInfo} /> */}
            <CustomIcon
              className="m-2"
              name="logout"
              onClick={logoutUser}
              style={customIconStyle}
            />
          </StyledIconContainer>
        </StyledCol>
      </Row>

      {/* <SwitchUserModal
        onDashboardPg={true}
        userRoleModal={userRoleModal}
        closeModal={() => setUserRoleModal(false)}
      /> */}

      <LeaveForm
        isModalVisible={displayLeaveModal}
        closeModal={() => toggleLeaveModal(false)}
        leaveDetails={{}}
      />
      <ReportIncident
        isModalVisible={displayIncidentModal}
        closeModal={() => toggleIncidentModal(false)}
        leaveDetails={{}}
      />
    </StyledAntHeader>
  );
};

const mapStateToProps = (state) => ({
  userName: getUserName(state),
  selectedDepartmentId: getSelectedDepartmentId(state),
  departmentList: getDepartmentList(state),
  roleName: getRoleName(state),
  departmentName: getDepartmentName(state),
  isLoadingDataFirstTime: getLoadingDataFirstTime(state),
  userRoleId: getUserRoleId(state),
  roleList: getRoleList(state),
  userRoleList: getUserRoleList(state),
  notificationList: getNotificationList(state),
  userInfo: getUserInfo(state),
  profilePic: getProfilePic(state),
  allDepartmentList: getAllDepartmentList(state),
  clientId: getClientid(state),
  menuList: getMenuList(state),
  productInfo: getProductInfo(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateDepartmentId: setDepartmentId,
      saveNotificationList: setNotificationList,
      removeStoreData: clearStore,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);

const SubscriptionBanner = ({ trailEndDate, setShowSubscribeFormModal }) => {
  const [showBanner, setShowBanner] = useState(true);

  const trialPeriodEndsIn = moment(trailEndDate, "DD/MM/YYYY").diff(
    moment(),
    "days"
  );

  const isFreeTrialEnded = trialPeriodEndsIn < 0;

  if (!showBanner) {
    return null;
  }

  return (
    <FreeTrailWrap
      onClick={() => {
        setShowSubscribeFormModal(true);
      }}
    >
      <div className="free-trial-banner">
        <span className="content">
          {isFreeTrialEnded
            ? "Free Trial Ended"
            : `Free Trial Ends in ${trialPeriodEndsIn} days`}
          <CloseOutlined
            onClick={(e) => {
              e.stopPropagation();
              setShowBanner(false);
            }}
            style={{
              cursor: "pointer",
            }}
            fontSize="small"
          />
        </span>
      </div>
    </FreeTrailWrap>
  );
};

const FreeTrailWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  cursor: pointer;

  .free-trial-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 4px 16px;
    background-color: rgba(255, 148, 59, 0.12);
    color: rgba(255, 148, 59, 1);
    height: 40px;
    border-radius: 24px;

    .content {
      white-space: nowrap;
    }
  }
`;
