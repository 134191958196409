import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Spin, Form, Select } from "antd";
// import { Column } from '@ant-design/plots';
import styled from "@emotion/styled";
import { connect } from "react-redux";
import UserMonthlyReportDonut from "../organisms/UserMonthlyReportDonut";
import { LEAVE_ICON, USER_REPORT } from "common/Constants";
import { CALL_API } from "common/API";
import COLORS from "common/Colors";
import { get, find, includes } from "lodash";
import {
  dateInDetail,
  getMonthDateRange,
  getKenyanDateTime,
  getMySqlDate,
} from "utils/Date";
import { getClientid, getIsCheckedIn } from "redux/selectors";
import { MONTH_LIST, STATUS_CODE } from "common/Constants";
import moment from "moment";

const StyledTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #141414;
  margin: 10px;
  @media (min-width: 320px) and (max-width: 420px) {
    .monthstyle {
      display: flex;
      flex-direction: column !important;
    }
  }
`;
const StyledRow = styled(Row)`
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 5px;
  height: 100%;
  margin-bottom: 25px;
`;
const StyledForm = styled(Form)`
  display: flex;
  justify-content: flex-end;
  .select-width {
    width: 150px;
  }

  @media (max-width: 600px) {
    .select-width {
      width: 100%;
    }
  }
`;
const StyledMonthStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 320px) and (max-width: 420px) {
    flex-direction: column !important;
  }
`;

function UserMonthlyReport({ ClientId, isCheckedIn }) {
  const [loading, setLoading] = useState(false);
  const [totalWorking, setTotalWorking] = useState("");
  const [userReport, setUserReport] = useState([
    {
      type: USER_REPORT.ONTIME,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.ONTIME,
    },
    { type: USER_REPORT.LATE, value: 0, color: COLORS.USER_REPORT_COLOR.LATE },
    {
      type: USER_REPORT.LEAVE,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.LEAVE,
    },
    {
      type: USER_REPORT.ABSENT,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.ABSENT,
    },
    {
      type: USER_REPORT.EARLYEXIT,
      value: 0,
      color: COLORS.USER_REPORT_COLOR.EARLYEXIT,
    },
  ]);
  const [monthList, setMonthList] = useState([]);
  const [form] = Form.useForm();

  const curr_month = moment().month() + 1;

  const list = () => {
    let month_list = [];
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i]);
      // console.log(MONTH_LIST[i])
    }
    setMonthList(month_list);
  };
  const containerRef = useRef(null);

  const getCheckinData = async () => {
    const { month } = form.getFieldsValue();
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value: month }), "index", 0)
    );
    setLoading(true);
    if (ClientId) {
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await delay(100);
      const { usersWiseAttendenceDetails, code } = await CALL_API(
        `attendance-report-per-user/${ClientId}`,
        "post",
        {
          startDate: getMySqlDate(startDateOfMonth),
          endDate: getMySqlDate(lastDateOfMonth),
        }
      );
      if (includes(STATUS_CODE.SUCCESS, code)) {
        let dupeLeaveData = Array.from(userReport);
        dupeLeaveData[0].value = Number(
          get(usersWiseAttendenceDetails[0], "onTimeCount", 0)
        );
        dupeLeaveData[1].value = Number(
          get(usersWiseAttendenceDetails[0], "lateCount", 0)
        );
        dupeLeaveData[2].value = Number(
          get(usersWiseAttendenceDetails[0], "leaveCount", 0)
        );
        dupeLeaveData[3].value = Number(
          get(usersWiseAttendenceDetails[0], "absentCount", 0)
        );
        dupeLeaveData[4].value = Number(
          get(usersWiseAttendenceDetails[0], "earlyCheckOutCount", 0)
        );
        setTotalWorking(get(usersWiseAttendenceDetails[0], "workingDays", 0));
        setUserReport(dupeLeaveData);
      }

      setLoading(false);
    }
  };
  useEffect(() => {
    getCheckinData();
    list();
  }, [form.getFieldValue("month"), ClientId, isCheckedIn]);

  return (
    <>
      <StyledRow>
        <Col md={24} sm={24} xs={24} xl={24} ref={containerRef}>
          <StyledMonthStyle>
            <StyledTitle>MONTHLY REPORT</StyledTitle>
            <StyledForm
              form={form}
              name="approval_form"
              layout="inline"
              initialValues={{
                month: dateInDetail(getKenyanDateTime()).monthName,
              }}
            >
              <Form.Item
                name="month"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Select
                  className="select-width"
                  placeholder="Select..."
                  options={monthList}
                  onChange={getCheckinData}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>
            </StyledForm>
          </StyledMonthStyle>

          <Spin spinning={loading}>
            <UserMonthlyReportDonut
              title="MONTHLY REPORT"
              data={userReport}
              workingData={totalWorking}
            />
          </Spin>
        </Col>
      </StyledRow>
    </>
  );
}
const mapStateToProps = (state) => ({
  ClientId: getClientid(state),
  isCheckedIn: getIsCheckedIn(state),
});

export default connect(mapStateToProps, null)(UserMonthlyReport);
