import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Spin, Modal, message, Menu, Dropdown } from "antd";
import Button from "atoms/Button";
import styled from "@emotion/styled";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserRoleId,
  getSelectedDepartmentId,
  getStatusListFromID,
  getClientid,
  getAllDepartmentList,
  getLocations,
  getMenuList,
} from "redux/selectors";
import { setRoleList } from "redux/actions";
import COLORS from "common/Colors";
import { CALL_API } from "common/API";
import { STATUS_CODE } from "common/Constants";
import { CSVLink } from "react-csv";
import AddRole from "organisms/AddRole";
import EditRole from "organisms/EditRole";
import { reduce } from "lodash";
import moment from "moment";
import Table from "atoms/Table";
import { ButtonFilled, ButtonOutlined } from "reusableComponent/UIButtons";
import DetailsPopup from "organisms/DetailsPopup";
import ConformationModal from "organisms/ConformationModal";
import MovingPeopleModal from "organisms/MovingPeopleModal";
import Icon from "atoms/Icon";
import { fontSize, height, padding } from "@mui/system";
import { MoreOutlined } from "@ant-design/icons";

const StyledViewMenu = styled.div`
  width: 100px;
  cursor: pointer;
`;
const StyledButtonoutlined = styled(ButtonOutlined)`
  @media (max-width: 575px) {
    margin-left: 150px !important;
  }
  @media (max-width: 564px) {
    margin-left: -160px !important;
    position: absolute;
    top: 30px;
    bottom: 30px;
  }
`;
const StyledTable = styled(Table)`
  padding-top: 20px;
  table {
    table-layout: auto !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
  .ant-table-cell {
    padding: 10px;
    height: 0px !important;
    border: none;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background: none;
  }

  .ant-table-thead .ant-table-cell {
    font-weight: bold;
    color: ${COLORS.SECONDARY_BLACK};
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .non-white {
    background: #f9f9f9;
  }
  .action-content {
    display: none;
  }

  /* Show action content on row hover */
  .hover-row:hover .action-content {
    display: inline;
  }
`;

const StyleddeactivateModal = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    /* padding: 35px 0px 20px 0px; */
  }
  .ant-modal-content {
    box-shadow: 0px 0px 8px #00000029;
    border-radius: 5px;
  }
  .ant-modal-title {
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-body {
    /* padding: 0px 0px 35px 0px; */
  }
  .ant-modal-close-x {
    display: none;
  }
  .page-title-head {
    justify-content: center;
  }
  .ant-modal-footer {
    display: none;
  }
`;
const StyledRow = styled(Row)`
  @media (max-width: 564px) {
    margin-top: -30px !important;
    margin-bottom: 40px !important;
    .page-title {
      margin-top: -80px !important;
    }
  }
`;
const StyledButton = styled(Button)`
  width: 125px;
  color: white;
  background-color: #b7b7b7;
  border-radius: 5px;
  margin-left: 5%;
`;

function Roles({
  clientId,
  AllDepartmentList,
  locationList,
  menuList,
  userRoleId,
  saveRoleList,
}) {
  const [addRole, toggleAddRole] = useState(false);
  const [editRole, toggleEditRole] = useState(false);
  const [RoleDetails, setRoleDetails] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [deleteRole, setDeleteRole] = useState(false);
  const [roleid, setRoleid] = useState("");
  const [displayLocationLoader, setDisplayLocationLoader] = useState(false);
  const [roleCSVlist, setroleCSVlist] = useState([]);
  const [btnLoader, togglebtnLoader] = useState(false);
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isDeleteRole, setIsDeleteRole] = useState(false);
  const [isMovinePeople, setIsMovigPeople] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const Menulist = menuList[userRoleId] || Object.values(menuList)[0];

  let location = useSelector(getLocations);

  console.log("location -->", location);

  const cursorstyle = { cursor: "pointer", height: "18px" };

  const csvLinkEl = useRef();

  const CSVHeaders = [
    { label: "Name", key: "roleName" },
    // { label: "Department", key: "department" },
    { label: "Location", key: "locationId" },
    // { label: "Created On", key: "insertedAt" },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "roleName",
      key: "role_name",
    },
    // {
    //   title: "No of Department",
    //   dataIndex: "department",
    //   key: "department",
    //   align: "center",
    //   render: (department) => {
    //     if (department) {
    //       return department?.length;
    //     } else {
    //       return "-";
    //     }
    //   },
    // },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      align: "center",
      render: (locationId) => {
        const locationObj = location.find((loc) =>
          locationId.includes(String(loc.location_id).trim())
        );

        if (locationObj) {
          return locationObj.location_name || "-";
        } else {
          return "-";
        }
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "roleStatus",
    //   key: "roleStatus",
    //   align: "center",
    // },
    // {
    //   title: "",
    //   dataIndex: "roleStatus",
    //   key: "action",
    //   align: "center",
    //   width: "20%",
    //   render: (text, record) => {

    //     return (
    //       <div className="action-content" onClick={(e)=>e.stopPropagation()}>
    //         {/* <Icon name="trash" style={cursorstyle} onClick={(e) => { e.stopPropagation(); deletedRole(record); }} /> */}
    //         <Icon className="mx-2" name="edit" style={cursorstyle} onClick={(e) => { e.stopPropagation(); showViewRole(record); }} />
    //         <Dropdown overlay={
    //           <StyledViewMenu>
    //             <Menu>
    //               <Menu.Item onClick={()=>{setRoleDetails(record);setIsDisable(true)}}>
    //                {record?.roleStatus === "Enabled" ? "Disable" : "Enable"}
    //               </Menu.Item>
    //               <Menu.Item onClick={()=>{setRoleDetails(record);setRoleid(record?.roleId);deletedRole(record)}}>
    //                 Delete
    //               </Menu.Item>
    //             </Menu>
    //           </StyledViewMenu>
    //         } placement="bottomRight">
    //           <MoreOutlined style={{ cursor: 'pointer' }} />
    //         </Dropdown>
    //       </div>
    //     );
    //   }
    // }
  ];

  const fetchMasterData = async (fetch_master_data) => {
    const { code, roleList } = await CALL_API(
      `master-data/${clientId}`,
      "post",
      {
        fetch_master_data,
      }
    );
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        saveRoleList(roleList);
      }
    }
  };

  const addRoleModal = () => {
    toggleAddRole(true);
  };

  useEffect(() => {
    getRoleList();
  }, [setRoleList]);

  const getRoleList = async () => {
    setDisplayLocationLoader(true);
    const { code, response } = await CALL_API(
      `owner-roles/${clientId}`,
      "get",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setRoleList(response.reverse());
      setDisplayLocationLoader(false);
    }
  };

  const prepareCSV = async () => {
    togglebtnLoader(true);
    if (roleList.length > 0) {
      setroleCSVlist(
        reduce(
          roleList,
          function (result, list) {
            const departmentIds = Array.isArray(list?.department)
              ? list?.department
              : [list?.department];
            const departmentNames = departmentIds.map((id) => {
              const matchingDepartment = AllDepartmentList.find(
                (item) => item.department_id === id
              );
              return matchingDepartment
                ? matchingDepartment.department_name
                : "";
            });

            const locationIdIds = Array.isArray(list?.locationId)
              ? list?.locationId
              : [list?.locationId];
            const locationNames = locationIdIds.map((id) => {
              const matchinglocationId = locationList.find(
                (item) => item.location_id === id
              );
              return matchinglocationId ? matchinglocationId.location_name : "";
            });

            result.push({
              "Role name": list.roleName,
              Department: departmentNames?.join(", "),
              Location: locationNames?.join(", "),
              "Created On": moment(list?.insertedAt).format("yyyy-MM-DD"),
            });
            return result;
          },
          []
        )
      );
      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
    togglebtnLoader(false);
  };

  const deletedRole = (details) => {
    if (details?.userId?.length === 0) {
      setDeleteRole(true);
      setIsViewDetails(false);
    } else {
      setIsDeleteRole(true);
      setIsViewDetails(false);
    }
  };

  const showViewRole = (RoleObject, isViewOnly = false) => {
    setRoleid(RoleObject.roleId);
    toggleEditRole(true);
    // setIsViewDetails(true);
    setRoleDetails({
      ...RoleObject,
      isViewOnly,
    });
  };

  const ViewRole = (RoleObject, isViewOnly = false) => {
    setRoleid(RoleObject.roleId);
    toggleEditRole(true);
    // setIsViewDetails(true);
    setRoleDetails({
      ...RoleObject,
      isViewOnly,
    });
  };
  const confirmdelete = async () => {
    setDisplayLocationLoader(true);
    const { code, Message } = await CALL_API(
      `owner-roles/${clientId}/${roleid}`,
      "delete",
      {}
    );
    if (code === STATUS_CODE.SUCCESS) {
      setDeleteRole(false);
      message.success("Role Deleted Successfully");
      toggleEditRole(false);
      getRoleList();
      setDisplayLocationLoader(false);
    }
  };

  const handleDisable = async () => {
    togglebtnLoader(true);
    const { code } = await CALL_API(
      `owner-roles/${clientId}/${RoleDetails.roleId}`,
      "patch",
      {
        rolename: RoleDetails?.roleName,
        department: RoleDetails?.department,
        location: RoleDetails?.locationId,
        menuList: RoleDetails?.menuList,
        rolestatus:
          RoleDetails?.roleStatus === "Enabled" ? "Disabled" : "Enabled",
      }
    );
    if (STATUS_CODE.SUCCESS === code) {
      getRoleList();
      setIsDisable(false);
      fetchMasterData(true);
      togglebtnLoader(false);
    }
  };

  // const confirmdelete = async () => {
  //   setDisplayLocationLoader(true);
  //   const { code, Message } = await CALL_API(
  //     `owner-roles/${clientId}/${roleid}`,
  //     "delete",
  //     {}
  //   );
  //   if (code === STATUS_CODE.SUCCESS) {
  //     setDeleteRole(false);
  //     message.success(Message);
  //     toggleEditRole(false);
  //     getRoleList();
  //     setDisplayLocationLoader(false);
  //   }
  // };

  console.log("rolelist--->", roleList);
  const headerstyle = { fontSize: "20px", fontWeight: "bold" };
  return (
    <>
      {!addRole && !editRole && (
        <StyledRow className="page-title">
          <Col sm={24} md={12} style={headerstyle}>
            List of Permission
          </Col>
          <Col sm={24} md={12} className="align-right">
            <StyledButtonoutlined onClick={prepareCSV}>
              Export CSV file
            </StyledButtonoutlined>
            <CSVLink
              header={CSVHeaders}
              data={roleCSVlist}
              filename={"role-list.csv"}
              asyncOnClick={true}
              ref={csvLinkEl}
            />
            {/* {Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "roles" && subItem?.permission.create
                )
            ) && (
              <StyledButton
                type="primary"
                htmlType="submit"
                onClick={() => addRoleModal(true)}
              >
                +Add Role
              </StyledButton>
            )} */}
          </Col>
        </StyledRow>
      )}

      {!addRole && !editRole && (
        <Spin spinning={displayLocationLoader}>
          <StyledTable
            className="mb-5"
            dataSource={roleList}
            columns={columns}
            rowKey="role_id"
            pagination={false}
            onRow={(record) => ({
              onClick: () => {
                ViewRole(record);
              },
              className: "hover-row",
            })}
          />
        </Spin>
      )}
      {addRole && (
        <AddRole
          isModalVisible={addRole}
          closeModal={() => toggleAddRole(false)}
          getRoleList={getRoleList}
          RoleDetails={RoleDetails}
        />
      )}

      {editRole && (
        <EditRole
          isModalVisible={editRole}
          closeModal={() => toggleEditRole(false)}
          getRoleList={getRoleList}
          RoleDetails={RoleDetails}
          deleteRole={deletedRole}
          viewOnly={
            Menulist?.find(
              (item) =>
                item?.key === "manage" &&
                item?.subMenu.find(
                  (subItem) =>
                    subItem?.key === "roles" && subItem?.permission.edit
                )
            )
              ? false
              : true
          }
        />
      )}
      <DetailsPopup
        isopen={isViewDetails}
        isCancel={() => setIsViewDetails(false)}
        title={RoleDetails?.roleName}
        employeeCount={RoleDetails?.userId?.length}
        department={RoleDetails?.department}
        location={RoleDetails?.locationId}
        details={RoleDetails}
        onEdit={() => {
          toggleEditRole(true);
          setIsViewDetails(false);
        }}
        onDelete={deletedRole}
        isDisable={() => setIsDisable(true)}
      />

      {/* <StyleddeactivateModal
        // title="Are you sure you want to deactivate this Role?"
        width={500}
        open={deleteRole}
        onCancel={() => setDeleteRole(false)}
        footer={[]}
        centered
      >
        <p>Are you sure you want to delete this Role?</p>
        <div className="d-flex justify-content-end align-items-center">
        <ButtonOutlined
              className="mx-2"
              // type="primary"
              htmlType="submit"
              onClick={() => setDeleteRole(false)}
            >
              Cancel
            </ButtonOutlined>
            <ButtonFilled
              className="mx-2"
              loading={btnLoader}
              type="primary"
              htmlType="Delete"
              onClick={confirmdelete}
            >
              {btnLoader ? "Delete" : "Delete"}
            </ButtonFilled>
        </div>
            
      </StyleddeactivateModal> */}

      <ConformationModal
        isModalOpen={deleteRole}
        handleCancel={() => setDeleteRole(false)}
        handleOk={confirmdelete}
        name={"Are you sure you want to delete this Role?"}
        loading={btnLoader}
        buttonName="Delete"
      />

      <ConformationModal
        isModalOpen={isDeleteRole}
        handleCancel={() => setIsDeleteRole(false)}
        handleOk={() => {
          setIsDeleteRole(false);
          setIsMovigPeople(true);
        }}
        name={`There are people assigned under this role. Move these people to any other before deleting "${RoleDetails?.roleName}"`}
        loading={btnLoader}
        buttonName="Move People"
      />
      <MovingPeopleModal
        isModalOpen={isMovinePeople}
        handleCancel={() => setIsMovigPeople(false)}
        handleOk={() => setIsConfirmDelete(true)}
        details={RoleDetails}
        getRoleList={getRoleList}
        loading={btnLoader}
        togglebtnLoader={togglebtnLoader}
        fetchMasterData={fetchMasterData}
      />
      <ConformationModal
        isModalOpen={isConfirmDelete}
        handleCancel={() => setIsConfirmDelete(false)}
        handleOk={() => {
          setIsConfirmDelete(false);
          confirmdelete();
        }}
        name={`Peoples under "${RoleDetails?.roleName}" have been moved successfully. Do you want to delete the role "${RoleDetails?.roleName}" now`}
        loading={btnLoader}
        buttonName="Yes, delete"
        cancelButtonName="No, Cancel"
      />
      <ConformationModal
        isModalOpen={isDisable}
        handleCancel={() => setIsDisable(false)}
        handleOk={handleDisable}
        name={
          RoleDetails?.roleStatus === "Enabled" ? (
            <span>
              Please note that disabling this role will not affect any past
              actions associated with it. This action will make the role
              unavailable for future use, but you can re-enable it at any time
              if needed.<br></br>Are you sure you want to diasble this Role?
            </span>
          ) : (
            `Are you sure you want to enable this Role?`
          )
        }
        loading={btnLoader}
        buttonName={
          RoleDetails?.roleStatus === "Enabled" ? "Disable" : "Enable"
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  statusListFromID: getStatusListFromID(state, [4, 5, 6]),
  selectedDepartmentId: getSelectedDepartmentId(state),
  clientId: getClientid(state),
  AllDepartmentList: getAllDepartmentList(state),
  locationList: getLocations(state),
  menuList: getMenuList(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveRoleList: setRoleList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null)(Roles);
