import React from "react";
import { Table, Select, Input, Button, Modal } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { ReactComponent as Edit } from "../../assets/Edit_Pencil_01.svg";
import { ReactComponent as Trash } from "../../assets/trash.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { ReactComponent as Tick } from "../../assets/tick.svg";

const CustomStyle = styled.div`
  .ant-table-thead > tr > th {
    font-weight: 500;
    font-size: 16px; /* Customize title style */
  }

  .ant-table-tbody > tr > td {
    font-size: 14px;
    font-weight: 400; /* Set font size and weight for data cells */
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .in {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }

  .inci {
    font-weight: 400 !important;
    font-size: 14px !important;
  }

  .action-dropdown .ant-select-selector {
    width: 200px;
    height: 34px;
    border-radius: 24px;
    font-weight: 400;
    font-size: 14px;
    color: #636980;
    margin-left: 12px;
  }

  .search {
    width: 200px;
    height: 34px;
    border-radius: 24px;
    font-weight: 400;
    font-size: 14px;
    color: #636980;
    margin-left: 12px;
  }

  /* Hover effect to change color on hover */
  .dropdown-container a:hover {
    color: #1890ff;
  }

  .dropdown-container a .anticon {
    margin-left: 5px; /* Adjust spacing */
    font-size: 9px; /* Make the arrow smaller */
  }
  .action-icons {
    display: none;
    gap: 16px; /* Hide by default */
  }

  .ant-table-tbody > tr:hover .action-icons {
    display: flex; /* Show only on row hover */
  }
  .custom-row td {
    height: 70px; /* Adjust as needed */
    vertical-align: middle;
  }
  .suspend-text {
    color: #f28916;
  }

  .res-text {
    color: #08c108;
  }

  .dis-text {
    color: #ee2738;
  }
  .ant-modal {
    z-index: 9999 !important; /* Ensure the modal stays on top */
  }
  .ant-modal-body {
    overflow-y: auto; /* Add this if the modal content overflows */
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent mask */
    z-index: 9998; /* Ensure the mask is beneath the modal */
  }
  @media (max-width: 768px) {
    .total {
      display: block !important;
    }
    .search {
      margin-bottom: 10px !important;
      margin-left: 0 !important;
    }
    .action-dropdown {
      margin-bottom: 10px !important;
      margin-left: -12px !important;
    }
  }
`;
const StyledModal = styled(Modal)`
  .delete-modal-text {
    font-weight: 400;
    font-size: 16px;
    color: #636980;
  }
  .yes-no-button {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  .no-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #2d53da;
    color: #2d53da;
  }
  .yes-button {
    width: 130px;
    height: 34px;
    border-radius: 8px;
    background-color: #2d53da;
    color: #ffffff;
  }
  .success-modal {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .sent-successfully {
    font-size: 16px;
    font-weight: 400;
  }
  .suspend-text {
    color: #f28916;
  }
`;
const MyIncident = () => {
  const history = useHistory();

  const dataSource = [
    {
      key: "1",
      Incident_ID: "IN123456",
      Name: "Near Miss",
      Employee_ID: "25th Aug 2024",
      Incident_Type: "11:00 AM",
      Location: "Near Office",
      Reported_on: "25th Aug 2024",
      Status: "Waiting for Review",
    },
    {
      key: "2",
      Incident_ID: "IN123456",
      Name: "Near Miss",
      Employee_ID: "25th Aug 2024",
      Incident_Type: "11:00 AM",
      Location: "Near Office",
      Reported_on: "25th Aug 2024",
      Status: "Under Review",
    },
    {
      key: "3",
      Incident_ID: "IN123456",
      Name: "Near Miss",
      Employee_ID: "25th Aug 2024",
      Incident_Type: "11:00 AM",
      Location: "Near Office",
      Reported_on: "25th Aug 2024",
      Status: "Waiting for Review",
    },
    {
      key: "4",
      Incident_ID: "IN123456",
      Name: "Near Miss",
      Employee_ID: "25th Aug 2024",
      Incident_Type: "11:00 AM",
      Location: "Near Office",
      Reported_on: "25th Aug 2024",
      Status: "Dismissed",
    },
    {
      key: "5",
      Incident_ID: "IN123456",
      Name: "Near Miss",
      Employee_ID: "25th Aug 2024",
      Incident_Type: "11:00 AM",
      Location: "Near Office",
      Reported_on: "25th Aug 2024",
      Status: "Resolved",
    },
    {
      key: "6",
      Incident_ID: "IN123456",
      Name: "Near Miss",
      Employee_ID: "25th Aug 2024",
      Incident_Type: "11:00 AM",
      Location: "Near Office",
      Reported_on: "25th Aug 2024",
      Status: "Under Review",
    },
  ];
  const handleEditDisciplinary = (record) => {
    console.log("Editing:", record);
  };

  // const handleDeleteClick = (record) => {
  //   console.log("Deleting:", record);
  // };
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeletedModalOpen, setIsDeletedModalOpen] = useState(false);

  const handleDeleteClick = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteOpen(false);
  };

  const handleDeleteConfirm = () => {
    // Handle the delete logic here
    setIsDeleteOpen(false); // Close the first modal
    setIsDeletedModalOpen(true);

    // Optionally, you can trigger a success message or further action
    //setTimeout(() => setIsDeletedModalOpen(false), 2000); // close the success modal after 2 seconds
  };

  const columns = [
    {
      title: "Incident ID",
      dataIndex: "Incident_ID",
      key: "Incident_ID",
      width: "12%",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      width: "10%",
    },
    {
      title: "Employee ID",
      dataIndex: "Employee_ID",
      key: "Employee_ID",
      width: "15%",
    },
    {
      title: "Incident Type",
      dataIndex: "Incident_Type",
      key: "Incident_Type",
      width: "10%",
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
      width: "10%",
    },
    {
      title: "Reported on",
      dataIndex: "Reported_on",
      key: "Reported_on",
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      width: "15%",
      render: (text, record) => {
        if (text === "Waiting for Review") {
          return (
            <span
              className="clearance-text"
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push("/ViewIncident", { state: { incident: record } })
              }
            >
              {text}
            </span>
          );
        } else if (text === "Under Review") {
          return (
            <span
              className="suspend-text"
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push("/ViewUnderReview", {
                  state: { incident: record },
                })
              }
            >
              {text}
            </span>
          );
        } else if (text === "Resolved") {
          return (
            <span
              className="res-text"
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push("/ViewResolved", {
                  state: { incident: record },
                })
              }
            >
              {text}
            </span>
          );
        } else if (text === "Dismissed") {
          return (
            <span
              className="dis-text"
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push("/ViewDismissed", {
                  state: { incident: record },
                })
              }
            >
              {text}
            </span>
          );
        }
      },
    },
    {
      key: "actions",
      width: 100,
      height: 50,
      render: (_, record) => (
        <div className="action-icons">
          <Edit
            onClick={(event) => {
              event.stopPropagation(); // Prevent the event from bubbling up
              handleEditDisciplinary(record); // Call the edit function with the current record
              history.push("/EditDeletepage"); // Navigate to the EditDeletepage route
            }}
          />

          <Button
            onClick={handleDeleteClick} // Trigger the delete modal
            icon={<Trash />}
            // Add your trash icon here
            className="delete-button"
            type="text" // This removes the default button style
            style={{
              padding: 0, // Remove any extra padding
              border: "none", // Remove any border (outline)
              background: "transparent", // Make background transparent
              boxShadow: "none",
              paddingBottom: "12px", // Remove any box-shadow
            }}
          ></Button>

          <StyledModal
            width={470}
            height={140}
            footer={false}
            closable={false}
            open={isDeleteOpen}
            onCancel={handleDeleteCancel}
            style={{ marginTop: "100px" }}
          >
            <p className="delete-modal-text">
              Are you sure you want to delete this incident?
            </p>
            <br />
            <div className="yes-no-button">
              <Button onClick={handleDeleteCancel} className="no-button">
                No
              </Button>
              <Button onClick={handleDeleteConfirm} className="yes-button">
                Yes
              </Button>
            </div>
          </StyledModal>

          <StyledModal
            width={190}
            height={70}
            open={isDeletedModalOpen}
            onCancel={() => setIsDeletedModalOpen(false)}
            footer={false}
            closable={false}
            style={{ marginTop: "130px" }}
          >
            <div className="success-modal">
              {/* Assuming Tick is another component or icon you want to display */}
              <Tick />
              <p className="sent-successfully">Deleted</p>
            </div>
          </StyledModal>
        </div>
      ),
    },
  ];

  return (
    <CustomStyle>
      <p style={{ fontWeight: 500, fontSize: 20 }}>My Incident</p>
      <div className="total">
        <p style={{ fontWeight: 400, fontSize: 16 }}>
          Total Incident: {dataSource.length}
        </p>

        {/* Dropdown and Search */}
        <div className="dropdown-container">
          <Input
            className="search"
            placeholder="Search"
            prefix={<SearchOutlined />}
          />
          <Select placeholder="Incident Type" className="action-dropdown">
            <Select.Option value="work">Workplace Injury</Select.Option>
            <Select.Option value="first">
              Hazardous Material Exposure
            </Select.Option>
            <Select.Option value="second">Vehicle Accident</Select.Option>
            <Select.Option value="third">Fire or Explosion</Select.Option>
            <Select.Option value="show">Medical Emergency</Select.Option>
            <Select.Option value="suspend">Near Miss</Select.Option>
          </Select>

          <Select placeholder="Date" className="action-dropdown">
            <Select.Option value="a">Last week</Select.Option>
            <Select.Option value="b">Last month</Select.Option>
            <Select.Option value="c">Last 6 months</Select.Option>
            <Select.Option value="d">Custom Date range</Select.Option>
          </Select>

          <Select placeholder="Status" className="action-dropdown">
            <Select.Option value="x">Waiting for Review</Select.Option>
            <Select.Option value="y">Under Review</Select.Option>
            <Select.Option value="z">Resolved</Select.Option>
            <Select.Option value="p">Dismissed</Select.Option>
          </Select>
        </div>
      </div>

      {/* Table */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={() => "custom-row"}
      />
    </CustomStyle>
  );
};

export default MyIncident;
